import { AutoCompleteFieldProps } from 'components/FormFields/FormAutoComplete';
import { ComponentType, lazy } from 'react';

const LazyFormAutocomplete: ComponentType<AutoCompleteFieldProps<any>> = lazy(
    () =>
        import('./FormAutoComplete').then((module) => ({
            default: module.FormAutoComplete,
        }))
);

const LazyFormInput = lazy(() =>
    import('./FormInput').then((module) => ({
        default: module.FormInput,
    }))
);

const LazyFormTextArea = lazy(() =>
    import('./FormTextArea').then((module) => ({
        default: module.FormTextArea,
    }))
);

const LazyFormDateTimePicker = lazy(() =>
    import('./FormDateTimePicker').then((module) => ({
        default: module.FormDateTimePicker,
    }))
);

const LazyFormSelect = lazy(() =>
    import('./FormSelect').then((module) => ({
        default: module.FormSelect,
    }))
);

export {
    LazyFormAutocomplete,
    LazyFormDateTimePicker,
    LazyFormInput,
    LazyFormTextArea,
    LazyFormSelect,
};
