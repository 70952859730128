import { getUniqueFiles } from 'components/Dropzone';
import { MAX_FILES_LENGTH } from 'constants/file';

export const acceptMaxFiles = (
    acceptedFiles: File[],
    files: File[]
): File[] => {
    const remainingFiles = MAX_FILES_LENGTH - files.length;
    const uniqueArray = getUniqueFiles(acceptedFiles, files);
    return uniqueArray.slice(0, remainingFiles);
};
