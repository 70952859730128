const getDateTimeRange = (start: number, end: number): boolean => {
    if (typeof start !== 'number' && typeof end !== 'number' && !end)
        return false;
    const startDay = new Date(start * 1000);
    const endDay = new Date(end * 1000);
    const today = new Date();
    return today > startDay && today < endDay;
};

export { getDateTimeRange };
