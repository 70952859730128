import { AUTH_CONFIG } from 'constants/auth';
import axios from 'utils/axios';
import Keycloak, {
    KeycloakLoginOptions,
    KeycloakLogoutOptions,
    KeycloakRegisterOptions,
} from 'keycloak-js';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { removeQueryParameters } from 'utils/removeQueryParameters';
import { casesTableStorageKey } from 'hooks/usePersistentTableState';

export interface SignOutOptinos extends KeycloakLogoutOptions {
    reason?: 'idle';
}
export interface SignInOptinos extends KeycloakLoginOptions {}
export interface SignUpOptinos extends KeycloakRegisterOptions {}

const AuthClient = new Keycloak({
    realm: AUTH_CONFIG.idp.realm as string,
    url: AUTH_CONFIG.idp.url as string,
    clientId: AUTH_CONFIG.idp.clientId as string,
});

const getPortalAccountId = () =>
    getCookie(AUTH_CONFIG.appCookies.accountId) ||
    AuthClient.idTokenParsed?.['fortra.portalAccountId'];

const signIn = (options?: SignInOptinos) => {
    sessionStorage.removeItem('logOutReason');
    const redirect =
        options?.redirectUri ||
        removeQueryParameters(window.location.href, [
            'error',
            'state',
            'session_state',
            'code',
        ]);

    return AuthClient.login({
        ...options,
        redirectUri: redirect,
    });
};

const signOut = (options?: SignOutOptinos) => {
    const encodedOptions = {
        ...options,
        redirectUri: options?.redirectUri
            ? removeQueryParameters(options.redirectUri, [])
            : window.location.origin,
    };
    if (options?.reason === 'idle') {
        sessionStorage.setItem('logOutReason', 'idle');
    }
    sessionStorage.removeItem('selectedAccounts');
    sessionStorage.removeItem(getPortalAccountId() || casesTableStorageKey);

    return AuthClient.logout(encodedOptions);
};

const signUp = (options?: SignUpOptinos) => {
    sessionStorage.removeItem('logOutReason');
    return AuthClient.register(options);
};

const manageAccount = AuthClient.accountManagement;

const getUser = () => axios.get('/users/user_info');

const getIdToken = () =>
    getCookie(AUTH_CONFIG.appCookies.idToken) || AuthClient.idToken;

const setIdToken = (_idToken: string, exp: number = 300) =>
    setCookie(AUTH_CONFIG.appCookies.idToken, _idToken, {
        maxAge: exp,
        secure: true,
        sameSite: 'strict',
        httpOnly: false,
    });

const setAccountId = (_accountId: string, exp: number = 300) =>
    setCookie(AUTH_CONFIG.appCookies.accountId, _accountId, {
        maxAge: exp,
        secure: true,
        sameSite: 'strict',
        httpOnly: false,
    });

const removeIdToken = () => deleteCookie(AUTH_CONFIG.appCookies.idToken);

const getRefreshToken = () =>
    getCookie(AUTH_CONFIG.appCookies.refreshToken) || AuthClient.refreshToken;

const setRefreshToken = (_refreshToken: string, exp: number = 900) =>
    setCookie(AUTH_CONFIG.appCookies.refreshToken, _refreshToken, {
        maxAge: exp,
        secure: true,
        sameSite: 'strict',
        httpOnly: false,
    });

const removeRefreshToken = () =>
    deleteCookie(AUTH_CONFIG.appCookies.refreshToken);

const setAuthCookies = () => {
    const idExp =
        Number(AuthClient?.idTokenParsed?.exp) -
        Number(AuthClient?.idTokenParsed?.iat);
    const refreshExp =
        Number(AuthClient?.refreshTokenParsed?.exp) -
        Number(AuthClient?.refreshTokenParsed?.iat);
    if (AuthClient?.idToken && AuthClient?.refreshToken) {
        setIdToken(AuthClient?.idToken, idExp);
        setRefreshToken(AuthClient?.refreshToken, refreshExp);

        if (AuthClient.idTokenParsed) {
            setAccountId(
                AuthClient.idTokenParsed['fortra.portalAccountId'],
                idExp
            );
        }
    }
};

const isLoggedIn = () => !!AuthClient.idToken;

const updateToken = (minValidity: number) =>
    AuthClient.updateToken(minValidity);

const identifyUserForFullStory = () => {
    const { FS } = window as any;

    if (AuthClient?.idTokenParsed && FS?.identify) {
        FS.identify(AuthClient.idTokenParsed['fortra.userId'], {
            displayName: AuthClient.idTokenParsed.given_name,
            username: AuthClient.idTokenParsed.name,
            email: AuthClient.idTokenParsed.email,
            tenantId: AuthClient.idTokenParsed['fortra.tenantId'],
        });
    }
};

const isTokenExpired = (minValidity: number = 0) =>
    AuthClient.isTokenExpired(minValidity);

const tokenParsed = () => AuthClient.tokenParsed;

const refreshTokenParsed = () => AuthClient.refreshTokenParsed;

const isRefreshTokenExpired = () =>
    new Date() >= new Date((AuthClient.refreshTokenParsed?.exp || 0) * 1000);

const initAuth = (cb: any, storeTokensInCookies: boolean = false) => {
    AuthClient.init({
        onLoad: 'check-sso',
        responseMode: 'query',
        checkLoginIframe: false,
        redirectUri: removeQueryParameters(window.location.href, [
            'error',
            'state',
            'session_state',
            'code',
        ]),
    })
        .then((authenticated) => {
            if (authenticated && storeTokensInCookies) {
                setAuthCookies();
            }
            cb();
        })
        .catch(console.error);
};

const AuthService = {
    initAuth,
    signIn,
    signOut,
    signUp,
    manageAccount,
    getUser,
    isLoggedIn,
    updateToken,
    getIdToken,
    getPortalAccountId,
    setIdToken,
    removeIdToken,
    getRefreshToken,
    setRefreshToken,
    removeRefreshToken,
    identifyUserForFullStory,
    isTokenExpired,
    tokenParsed,
    refreshTokenParsed,
    isRefreshTokenExpired,
};

export default AuthService;
