import Box from '@northstar/core/Box';
import List from '@northstar/core/List';
import ListItem from '@northstar/core/ListItem';
import { GetApp as DownloadIcon } from '@northstar/icons';
import SanitizeHTML from 'components/SanitizeHTML';
import { ProductDownloadLink } from 'pages/Product/components/ProductDownloadLink';
import { ProductDownloadLinks } from 'types/products';

type Props = {
    productId: string;
    downloadLinks: ProductDownloadLinks[];
    versionId: string;
};

const DownloadsList = ({ downloadLinks, productId, versionId }: Props) => {
    return downloadLinks.length > 0 ? (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
            }}
            aria-label="Downloadable Links Container"
        >
            {downloadLinks.map((item) => (
                <ListItem
                    key={item.url}
                    alignItems="center"
                    sx={{
                        paddingLeft: 0,
                        borderTop: 1,
                        borderColor: 'divider',
                    }}
                >
                    <ProductDownloadLink
                        productURL={item.url}
                        productId={productId}
                        productName={item.name}
                        versionId={versionId}
                        sx={{
                            width: '40%',
                        }}
                    >
                        <DownloadIcon
                            color="primary"
                            aria-label="download"
                            sx={{ mr: 1 }}
                        />
                        {item.name}
                    </ProductDownloadLink>
                    <SanitizeHTML
                        sx={{
                            fontWeight: 'normal',
                            flexBasis: '45%',
                            pr: 2,
                            '& a': {
                                color: 'primary.main',
                            },
                        }}
                        aria-label="Download Link Description"
                        html={item?.description || ''}
                    />
                    <Box flexBasis="40%">
                        {item.md5Url && (
                            <ProductDownloadLink
                                productURL={item.md5Url}
                                productId={productId}
                                productName={item.name}
                                versionId={versionId}
                                sx={{
                                    pl: 2,
                                    borderLeft: '1px solid',
                                    borderColor: 'text.secondary',
                                }}
                            >
                                MD5
                            </ProductDownloadLink>
                        )}
                    </Box>
                </ListItem>
            ))}
        </List>
    ) : null;
};

export { DownloadsList };
