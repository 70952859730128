const clearAutoCompleteValue = (nodeElement: HTMLElement | null) => {
    if (nodeElement) {
        const clearBtn = nodeElement.getElementsByClassName(
            'MuiAutocomplete-clearIndicator'
        )[0] as HTMLButtonElement;

        if (clearBtn) {
            clearBtn.click();
        }
    }
};

export { clearAutoCompleteValue };
