const downloadBase64 = async (file: string, name: string) => {
    try {
        await fetch(file);

        const downloadLink = document.createElement('a');
        downloadLink.href = file;
        downloadLink.download = name;
        downloadLink.click();
        downloadLink.remove();
    } catch (e) {
        throw new Error(`Failed to download ${name}`);
    }
};

export { downloadBase64 };
