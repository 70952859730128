import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { Attachment } from '@northstar/icons';

const CaseAttachmentsColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('hasAttachments', {
        header: 'Attachments',
        filterFn: (row, _, filterValue) =>
            filterValue.length > 0
                ? row.original.hasAttachments === true
                : true,
        enableColumnFilter: false,
        cell: (cell) =>
            cell.getValue() ? (
                <Attachment
                    sx={{
                        width: 18,
                        height: 18,
                        marginLeft: 3.8,
                        fill: ({ palette }) => palette.grey[600],
                    }}
                />
            ) : null,
    });

export { CaseAttachmentsColumn };
