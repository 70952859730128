import { FieldValues } from 'react-hook-form';
import { AnswerOption } from 'types/createCase';
import { DynamicFormField } from 'types/formFields';

type Props = {
    startOption: AnswerOption;
    dynamicFormFields: DynamicFormField[];
    formValues: FieldValues;
};

const getChainedFields = ({
    startOption,
    dynamicFormFields,
    formValues,
}: Props) => {
    const returnValue: DynamicFormField[] = [];
    const queue: string[] = [startOption.questionId];
    const checkedItem = new Set<string>();

    while (queue.length > 0) {
        const currentQuestionId = queue.shift()!;

        if (!checkedItem.has(currentQuestionId)) {
            checkedItem.add(currentQuestionId);

            const currentElement = dynamicFormFields.find(
                (el) => el.props.name === currentQuestionId
            );

            if (
                currentElement &&
                currentElement.props.name !== startOption.questionId
            ) {
                returnValue.push(currentElement);
            }

            dynamicFormFields.forEach((el) => {
                if (
                    el.parentQuestionId === currentQuestionId &&
                    (formValues[el.props.name] ||
                        formValues[el.parentQuestionId] === el.props.name ||
                        formValues[el.props.name] === '')
                ) {
                    queue.push(el.props.name);
                }
            });
        }
    }

    return returnValue;
};

export { getChainedFields };
