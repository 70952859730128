import Avatar from '@northstar/core/Avatar';
import Box from '@northstar/core/Box';
import Divider from '@northstar/core/Divider';
import Typography from '@northstar/core/Typography';
import AttachmentList from 'pages/Support/components/View/AttachmentList';
import { formatedDate } from 'utils/formatedDate';
import { AttachmentFile } from 'types/cases';
import SanitizeHTML from 'components/SanitizeHTML';
import { getAvatarName } from 'utils/getAvatarName';
import { ActivityItemLoading } from '../Loading/ActivityItemLoading';

interface Props {
    user?: string;
    date: string;
    details?: string;
    isComment: boolean;
    changedTo?: string;
    changedFrom?: string;
    attachments?: Array<AttachmentFile>;
}
const ActivityItem = ({ ...props }: Props) => {
    const date = formatedDate(props.date);
    const avatarName = props?.user && getAvatarName(props.user);
    if (!props.date) {
        return <ActivityItemLoading />;
    }

    return (
        <div>
            {props.isComment ? (
                <>
                    <Box sx={{ display: 'flex', my: 2 }}>
                        <Avatar
                            sx={{
                                width: 36,
                                height: 36,
                                bgcolor: 'secondary.main',
                            }}
                            alt={props.user}
                        >
                            {avatarName}
                        </Avatar>
                        <Box sx={{ ml: 2, flex: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 500, mr: 1 }}
                                >
                                    {props.user}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {date.toLocalDateString()}
                                </Typography>
                            </Box>
                            {props.details && (
                                <SanitizeHTML
                                    sx={{
                                        my: 1,
                                        wordBreak: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                    html={props.details}
                                />
                            )}

                            <AttachmentList
                                data={props?.attachments}
                                createdAt={props.date}
                            />
                        </Box>
                    </Box>
                    <Divider />
                </>
            ) : (
                <div>
                    {props.date && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    my: 2,
                                }}
                            >
                                <Typography variant="body1" sx={{ mr: 1 }}>
                                    Your request status changed{' '}
                                    {props.changedFrom && (
                                        <>
                                            to{' '}
                                            <Box
                                                component="span"
                                                sx={{ fontWeight: 500 }}
                                            >
                                                {props.changedFrom}
                                            </Box>{' '}
                                        </>
                                    )}
                                    to{' '}
                                    <Box
                                        component="span"
                                        sx={{ fontWeight: 500 }}
                                    >
                                        {props.changedTo}
                                    </Box>
                                    .
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {props.date}
                                </Typography>
                            </Box>
                            <Divider />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ActivityItem;
