import Box from '@northstar/core/Box';
import Button from '@northstar/core/Button';
import Grid from '@northstar/core/Grid';
import Typography from '@northstar/core/Typography';
import { Link } from 'components/Link';
import Divider from '@northstar/core/Divider';
import Container from '@northstar/core/Container';

import { useAuth } from 'contexts/Auth';

const ContactUsMainDescription = () => {
    const { user, signIn } = useAuth();
    const openCaseUrl = `${window.location.origin}/support/requests/open`;
    return (
        <Container>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h3" component="h1" gutterBottom>
                    Contact Support
                </Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xs={12} md={!user || user?.is_portal_user ? 8 : 12}>
                    <Typography component="p">
                        Looking for help? We’re committed to solving your
                        problems so you can get back to what you do best. If
                        you’ve run into an issue, our friendly support team can
                        help you resolve it in a timely manner via phone, email
                        or portal request. When you need assistance implementing
                        a software feature or just want to find your product
                        downloads or manuals, we can point you in the right
                        direction.
                    </Typography>
                </Grid>
                {(!user || user?.is_portal_user) && (
                    <Grid item xs={12} md={4} alignItems="center">
                        <Button
                            sx={{
                                width: 'fit-content',
                                mx: 'auto',
                                display: 'block',
                            }}
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/support/requests/open"
                            onClick={(e) => {
                                if (!user) {
                                    e.preventDefault();
                                    signIn({
                                        redirectUri: openCaseUrl,
                                    });
                                }
                            }}
                        >
                            Open Support request
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider sx={{ mt: 3 }} />
        </Container>
    );
};

export { ContactUsMainDescription };
