import { useRef, useCallback } from 'react';
import { FieldErrors } from 'react-hook-form';
import { elementIsVisibleInViewport } from 'utils/elementIsVisibleInViewport';

const useFormScrollToError = () => {
    const formFieldRef = useRef<HTMLFormElement>(null);

    const scrollToFirstError = useCallback((errors: FieldErrors) => {
        const errorKeys = Object.keys(errors);

        if (formFieldRef.current) {
            const inputElements =
                formFieldRef.current.querySelectorAll('input');

            Array.from(inputElements).every((element) => {
                if (errorKeys.includes(element.name)) {
                    if (!elementIsVisibleInViewport(element)) {
                        element.scrollIntoView({ block: 'center' });
                    }

                    element.focus?.();

                    return false;
                }

                return true;
            });
        }
    }, []);

    return { formFieldRef, scrollToFirstError };
};

export { useFormScrollToError };
