import { useEffect, useRef, useState } from 'react';
import axios from 'utils/axios';
import { debounce } from 'utils/debounce';
import { useApp } from 'contexts/App';
import { mutate, useSWRConfig } from 'swr';
import { getUniqueFiles, removeFile } from 'components/Dropzone';
import { Case, CaseData } from 'types/cases';
import { useBeforeUnload, useBlocker } from 'hooks';
import { canUploadFiles } from './utils/canUploadFiles';
import { acceptMaxFiles } from './utils/acceptMaxFiles';
import { mutateStateFile } from './utils/mutateStateFile';
import { getUploadProgress } from './utils/getUploadProgress';
import { getError } from './utils/getError';
import { s3FilesToFileList } from './utils/s3FilesToFileList';

export type SaveStatusType =
    | 'changing'
    | 'saving'
    | 'saved'
    | 'uploading'
    | 'deleting'
    | 'deletingFile'
    | 'isSubmitting'
    | undefined;

export interface DraftCommentType {
    id: string;
    description: string;
    attachments: File[];
    createdBy: string;
    createdOn: Date;
}
export interface DraftCaseType
    extends Omit<CaseData & DraftCommentType, 'attachments'> {
    attachments: File[];
}

export const useDraftSave = (
    initialValues?: DraftCaseType | DraftCommentType,
    caseId?: string
) => {
    const { addNotification } = useApp();
    const [draftSaveStatus, setDraftSaveStatus] = useState<SaveStatusType>();
    const [draftData, setDraftData] = useState(initialValues);
    const { cache } = useSWRConfig();
    const [files, setFiles] = useState<File[]>([]);
    const firstLoadData = useRef<DraftCaseType | DraftCommentType>();
    const [newDraft, setNewDraft] = useState({
        isNew: true,
        attempts: 0,
    });
    const uploadedCount = useRef(0);
    const filesToUpload = useRef<File[]>([]);

    const deleteHandler = async (endpoint: string, cacheEndpoint?: string) => {
        try {
            setDraftSaveStatus('deleting');
            const data = await axios.delete(endpoint);

            if (data.status === 204) {
                firstLoadData.current = undefined;
                setDraftData(undefined);
                setFiles([]);
                cache.delete(cacheEndpoint || endpoint);
            }
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
        } finally {
            setDraftSaveStatus(undefined);
        }
    };

    const uploadFile = async (
        uploadFiles: File[],
        endpoint: string,
        mutateEndpoint?: string
    ) => {
        if (!canUploadFiles(files, uploadFiles)) {
            return;
        }

        filesToUpload.current = [...uploadFiles, ...filesToUpload.current];
        const uploadPromises = uploadFiles.map(async (file: any) => {
            setDraftSaveStatus('uploading');
            const controller = new AbortController();
            Object.assign(file, {
                abortController: controller,
            });
            try {
                if (file.status === 'error') {
                    return;
                }
                const formData = new FormData();
                formData.append('attachment', file);
                const response = await axios.post(endpoint, formData, {
                    headers: {
                        skipCancelation: true,
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = getUploadProgress(progressEvent);
                        setFiles((prevFiles) =>
                            mutateStateFile(prevFiles, file, {
                                progress,
                                uploaded:
                                    progress === 100
                                        ? 'serverPending'
                                        : 'uploading',
                            })
                        );
                    },
                    signal: controller.signal,
                });

                setFiles((prevFiles) =>
                    mutateStateFile(prevFiles, file, {
                        id: response.data.id,
                        abortController: null,
                        uploaded: 'success',
                    })
                );
            } catch (e: any) {
                const { message } = getError(e);
                setFiles((prevFiles) =>
                    mutateStateFile(prevFiles, file, {
                        status: 'error',
                        errorMessage: message,
                        uploaded: 'failed',
                    })
                );
            } finally {
                uploadedCount.current += 1;
                if (uploadedCount.current === filesToUpload.current.length) {
                    mutate(mutateEndpoint || endpoint);
                    setDraftSaveStatus(undefined);
                    filesToUpload.current = [];
                    uploadedCount.current = 0;
                }
            }
        });
        await Promise.all(uploadPromises);
    };

    const uploadHandler = async (
        acceptedFiles: File[],
        endpoint: string,
        mutateEndpoint?: string
    ) => {
        const uniqueArray = getUniqueFiles(
            acceptMaxFiles(acceptedFiles, files),
            files
        );
        setFiles((prevFiles) => [...prevFiles, ...uniqueArray]);
        await uploadFile(uniqueArray, endpoint, mutateEndpoint || endpoint);
    };

    const waitForServerPending = (file: any, interval = 500) => {
        return new Promise<void>((resolve) => {
            const checkPending = () => {
                if (file.uploaded !== 'serverPending') {
                    resolve(); // Resolve when `uploaded` is not `serverPending`
                } else {
                    setTimeout(checkPending, interval); // Keep checking at regular intervals
                }
            };
            checkPending();
        });
    };

    const handleRemoveFile = async (file: File, mutateEndpoint?: string) => {
        setDraftSaveStatus('deletingFile');
        const { id, abortController, uploaded } = file as any;

        if (uploaded === 'serverPending') {
            await waitForServerPending(file);
        }

        if ((!id || uploaded === 'failed') && uploaded !== 'serverPending') {
            if (abortController) {
                abortController.abort();
            }
            setFiles((prevFiles) => removeFile(prevFiles, file));
            setDraftSaveStatus(
                filesToUpload.current.length ? 'uploading' : undefined
            );
            return;
        }

        try {
            const { status } = await axios.delete(
                `/cases/draft/attachments/${(file as any)?.id}`,
                {
                    headers: {
                        skipCancelation: true,
                    },
                }
            );

            if (status === 204) {
                setFiles((prevFiles) => removeFile(prevFiles, file));
                await mutate(mutateEndpoint);
            }
        } catch (e: any) {
            // Handle errors
            const { message } = getError(e);
            setDraftSaveStatus(undefined);
            Object.assign(file, {
                status: 'error',
                errorMessage: message,
            });
        }
        setDraftSaveStatus(
            filesToUpload.current.length ? 'uploading' : undefined
        );
    };

    const retryUpload = async (
        file: any,
        endpoint: string,
        mutateEndpoint?: string
    ) => {
        try {
            Object.assign(file, {
                status: 'success',
                uploaded: 'retry',
                errorMessage: null,
            });
            await uploadFile([file], endpoint, mutateEndpoint);
        } catch (e: any) {
            const { message } = getError(e);
            Object.assign(file, {
                status: 'error',
                errorMessage: message,
                uploaded: 'failed',
            });
        }
    };

    // Draft comment
    const createDraftComment = async (
        endpoint: string,
        body: any,
        mutateEndpoint?: string
    ) => {
        let data;
        try {
            setDraftSaveStatus('saving');
            const response = await axios.post(endpoint, body);
            if (response.status === 200) {
                const { data: responseData } = response;
                setDraftData(responseData);
                setDraftSaveStatus('saved');
                mutate(mutateEndpoint || endpoint, { ...responseData });
                data = responseData;
            }
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
        } finally {
            setTimeout(() => {
                if (filesToUpload.current.length) {
                    setDraftSaveStatus('uploading');
                } else if (draftSaveStatus !== 'changing') {
                    setDraftSaveStatus(undefined);
                }
            }, 2000);
        }
        return data;
    };

    const createComment = useRef(debounce(createDraftComment, 2000)).current;

    const saveDraftComment = (
        endpoint: string,
        body: any,
        mutateEndpoint?: string
    ): Promise<DraftCommentType> => {
        setDraftSaveStatus('changing');
        return createComment(endpoint, body, mutateEndpoint);
    };

    const deleteDraftComment = () => {
        const commentEndpoint = `/cases/draft/${caseId}/comment`;
        return deleteHandler(
            `/cases/draft/comment/${draftData?.id}`,
            commentEndpoint
        );
    };

    const createEmptyDradtComment = async () => {
        setDraftSaveStatus('changing');
        return createDraftComment(`/cases/draft/${caseId}/comment`, {
            description: '',
        });
    };

    const uploadCommentAttachment = async (acceptedFiles: File[]) => {
        let createEmptyDraft;
        if (!draftData) {
            createEmptyDraft = await createEmptyDradtComment();
        }
        const commentId = createEmptyDraft?.id || draftData?.id;
        const url = `/cases/draft/comments/${commentId}/attachment`;
        const commentEndpoint = `/cases/draft/${caseId}/comment`;
        return uploadHandler(acceptedFiles, url, commentEndpoint);
    };

    const reuploadCommentAttachment = async (file: File) => {
        let createEmptyDraft;
        if (!draftData) {
            createEmptyDraft = await createEmptyDradtComment();
        }
        const commentId = createEmptyDraft?.id || draftData?.id;
        return retryUpload(
            file,
            `/cases/draft/comments/${commentId}/attachment`,
            `/cases/draft/${caseId}/comment`
        );
    };

    const removeCommentAttachment = (file: File) => {
        setDraftSaveStatus('changing');
        return handleRemoveFile(file, `/cases/draft/${caseId}/comment`);
    };

    // Draft case
    const createDraftCase = async (
        endpoint: string,
        body: any,
        method: string = 'POST',
        mutateEndpoint?: string
    ) => {
        let responseData: any;
        try {
            setDraftSaveStatus('saving');
            const { data } = await axios({
                method,
                url: endpoint,
                data: body,
                headers: {
                    portalAccountIds: body.selectedAccount,
                },
            });
            responseData = data;
            if (responseData?.id) {
                if (!draftData?.id || draftData?.id !== responseData.id) {
                    window.history.replaceState(
                        null,
                        '',
                        `#${responseData.id}`
                    );
                }
                setDraftData(data);
                mutate(
                    mutateEndpoint || endpoint,
                    (
                        { cases }: { cases: Array<CaseData> } = {
                            cases: [],
                        }
                    ) => {
                        const existingCase = cases.some(
                            (c) => c.id === responseData.id
                        );

                        const updatedCases = cases.map((c) => {
                            return c.id === responseData.id ? responseData : c;
                        });
                        !existingCase && updatedCases.unshift(responseData);
                        return {
                            cases: updatedCases,
                        };
                    }
                );
            }
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
            setDraftSaveStatus(undefined);
        } finally {
            setTimeout(() => {
                setDraftSaveStatus('saved');
                setDraftSaveStatus(undefined);
            }, 2000);
        }
        return responseData;
    };

    const createCase = useRef(debounce(createDraftCase, 2000)).current;

    const isNewDraft = () => newDraft.isNew && newDraft.attempts === 0;

    const saveDraftCase = (body: any) => {
        let respons;
        let url = '/cases/draft';
        if (draftData?.id) {
            url = `${url}/${draftData?.id}`;
        }

        if (newDraft.attempts < 1) {
            setNewDraft((prev) => {
                return {
                    ...prev,
                    attempts: prev.attempts + 1,
                };
            });
            respons = createDraftCase(url, body);
        }

        setDraftSaveStatus('changing');

        if (!isNewDraft() && draftData?.id) {
            respons = createCase<DraftCaseType>(
                url,
                body,
                'PUT',
                '/cases/draft'
            );
        }
        return respons;
    };

    const deleteDraftCase = async () => {
        const endpoint = `/cases/draft/${draftData?.id}`;
        let responseData: any;
        try {
            setDraftSaveStatus('deleting');
            const data = await axios.delete(endpoint);
            if (data.status === 204) {
                setDraftData(undefined);
                setFiles([]);
                mutate(
                    '/cases/draft',
                    (
                        { cases }: { cases: Array<CaseData> } = {
                            cases: [],
                        }
                    ) => {
                        const updatedCases = cases?.filter(
                            (c) => c.id !== draftData?.id
                        );

                        return {
                            cases: updatedCases,
                        };
                    }
                );
                mutate(endpoint, undefined, { revalidate: false });

                window.history.replaceState(null, '', ' ');
                setNewDraft({ isNew: true, attempts: 0 });
            }
            responseData = data;
        } catch (e: any) {
            const { message, status } = getError(e);
            addNotification({
                message,
                status,
            });
        } finally {
            setDraftSaveStatus(undefined);
        }
        return responseData;
    };

    const uploadCaseAttachment = async (acceptedFiles: File[], body: Case) => {
        let createEmptyDraft: any;
        if (!draftData?.id) {
            createEmptyDraft = await saveDraftCase(body);
        }
        const id = createEmptyDraft?.id || draftData?.id;
        const url = `/cases/draft/${id}/attachments`;
        uploadHandler(acceptedFiles, url, `/cases/draft/${id}`);
        return id;
    };

    const reuploadCaseAttachment = async (file: File, body: Case) => {
        let createEmptyDraft;
        if (!draftData?.id) {
            createEmptyDraft = await createDraftCase('/cases/draft', body);
        }
        const id = createEmptyDraft?.id || draftData?.id;
        retryUpload(
            file,
            `/cases/draft/${id}/attachments`,
            `/cases/draft/${draftData?.id}`
        );
        return id;
    };

    const removeCaseAttachment = (file: File) => {
        return handleRemoveFile(file, `/cases/draft/${draftData?.id}`);
    };

    useEffect(() => {
        if (!initialValues || firstLoadData.current) return;
        if (!firstLoadData.current) {
            const attachments = s3FilesToFileList(
                initialValues?.attachments || []
            ) as unknown as File[];
            firstLoadData.current = { ...initialValues, attachments };
            setDraftData({ ...initialValues, attachments });
            setFiles(attachments);
            setNewDraft({ isNew: false, attempts: 1 });
        }
    }, [initialValues]);

    useBeforeUnload(draftSaveStatus);

    useBlocker(draftSaveStatus, () => {
        files.forEach((file) => {
            const { progress, abortController } = file as any;
            if (progress !== 100 && abortController) {
                abortController.abort();
            }
        });
        setDraftSaveStatus(undefined);
    });

    return {
        initialDraftData: firstLoadData.current,
        draftSaveStatus,
        setDraftSaveStatus,
        draftData,
        setDraftData,
        files,
        setNewDraft,
        setFiles,
        saveDraftComment,
        uploadCommentAttachment,
        reuploadCommentAttachment,
        removeCommentAttachment,
        deleteDraftComment,
        acceptMaxFiles,
        saveDraftCase,
        deleteDraftCase,
        uploadCaseAttachment,
        reuploadCaseAttachment,
        removeCaseAttachment,
    };
};
