import React from 'react';
import { SxProps, styled } from '@northstar/core/styles';
import theme from '@northstar/theme';

const SvgStyled = styled('svg', {
    shouldForwardProp: (prop: string) => prop !== 'variant',
})({
    userSelect: 'none',
    display: 'inline-block',
    flexShrink: 0,
});

export type FortraLogoProps = React.SVGProps<SVGSVGElement> & {
    classes?: Record<string, string>;
    className?: string;
    hexColor?: string;
    titleAccess?: string;
    viewBox?: string;
    variant?: 'light' | 'dark';
    sx?: SxProps;
    height?: number | string;
};

const AppLogo = (props: FortraLogoProps) => {
    const {
        classes,
        className,
        hexColor,
        titleAccess,
        viewBox = '0 0 125 20',
        variant = 'light',
        height = 20,
        ...other
    } = props;

    return (
        <SvgStyled
            aria-labelledby="company-logo"
            className={className}
            focusable="false"
            viewBox={viewBox}
            color={hexColor}
            aria-hidden={titleAccess ? undefined : true}
            role={titleAccess ? 'img' : undefined}
            fill={
                variant === 'light'
                    ? theme.palette.marketing.skyBlue
                    : theme.palette.marketing.fortaForest
            }
            height={height}
            {...other}
        >
            <path
                fill={hexColor || ''}
                d="M108.889 7.41939L113.333 16.38H104.444L108.889 7.41939ZM99.1755 19.6775H108.889H118.602L108.889 0.0717163L99.1755 19.6775Z"
            />
            <path
                fill={hexColor || ''}
                d="M0 0.358398V19.6774H3.58423V12.043H14.3369V8.60213H3.58423V3.79926H14.3369V0.358398H0Z"
            />
            <path
                fill={hexColor || ''}
                d="M50.681 9.56987H43.656V3.76342H50.681C52.1864 3.76342 53.4051 5.05374 53.4051 6.66664C53.3692 8.2437 52.1506 9.56987 50.681 9.56987ZM56.9534 6.66664C56.9534 3.18994 54.1219 0.358398 50.6452 0.358398H40.0359V19.6774H43.6201V12.8315H49.7491L53.7276 19.6774H57.6703L53.3334 12.2222C55.5198 11.2186 56.9534 9.03223 56.9534 6.66664Z"
            />
            <path
                fill={hexColor || ''}
                d="M90.466 9.56987H83.4409V3.76342H90.466C91.9714 3.76342 93.19 5.05374 93.19 6.66664C93.19 8.2437 91.9714 9.56987 90.466 9.56987ZM96.7743 6.66664C96.7743 3.18994 93.9427 0.358398 90.466 0.358398H79.8567V19.6774H83.4409V12.8315H89.5699L93.5126 19.6774H97.4553L93.1183 12.2222C95.3047 11.2186 96.7743 9.03223 96.7743 6.66664Z"
            />
            <path
                fill={hexColor || ''}
                d="M58.9963 0.358398V3.79926H66.2365V19.6774H69.8207V3.79926H77.025V0.358398H58.9963Z"
            />
            <path
                fill={hexColor || ''}
                d="M27.2043 16.4875C23.5484 16.4875 20.7886 13.6918 20.7886 10C20.7886 6.30824 23.5484 3.51254 27.2043 3.51254C30.8603 3.51254 33.6201 6.30824 33.6201 10C33.6201 13.6918 30.8603 16.4875 27.2043 16.4875ZM27.2043 0C21.6129 0 17.2043 4.37276 17.2043 10C17.2043 15.6272 21.5771 20 27.2043 20C32.8316 20 37.2043 15.6272 37.2043 10C37.2043 4.37276 32.7957 0 27.2043 0Z"
            />
            <path
                fill={hexColor || ''}
                d="M122.33 17.3118H121.828V19.6774H122.115V17.5986L123.011 19.6774H123.226L124.122 17.5627V19.6774H124.444V17.3118H123.978L123.154 19.2473L122.33 17.3118ZM119.032 17.5986H120V19.6774H120.323V17.5986H121.29V17.3118H118.996V17.5986H119.032Z"
            />

            {titleAccess ? (
                <title id="company-logo">{titleAccess}</title>
            ) : null}
        </SvgStyled>
    );
};

export default AppLogo;
