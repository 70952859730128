import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';

const OrganizationColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('ownerInfo.account', {
        header: 'Organization',
        id: 'ownerInfo',
        enableColumnFilter: false,
        filterFn: (row: any, key: string, filterValue: string[]) =>
            filterValue.length === 0 ||
            filterValue.includes(row.original.ownerInfo.accountId),
    });

export { OrganizationColumn };
