import { TextField, InputAdornment } from '@northstar/core';
import { Search as SearchIcon } from '@northstar/icons';
import { TextFieldProps } from '@northstar/core/types/TextField';

const NavSearch = (props: TextFieldProps) => {
    return (
        <TextField
            id="sidebar-search-input"
            size="small"
            placeholder="Search"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            {...props}
            name="search"
        />
    );
};

export default NavSearch;
