/* eslint-disable func-names */
import {
    FALLBACK_BLOCKEDLIST,
    MAX_FILE_SIZE,
    MAX_FILES_LENGTH,
    MAX_FILE_NAME,
    MAX_FILES_TOTAL,
} from 'constants/file';
import { formatBytes } from 'utils/formatBytes';
import { getFileExtension } from 'utils/getFileExtension';
import * as Yup from 'yup';
import { ReferenceOptions } from 'yup/lib/Reference';
import validators from './en-US.json';

interface AttachmentsParamsType {
    max: number;
    size: number;
    totalSize: number;
    maxFileName: number;
    typeErrMsg: string;
    maxErrMsg: string;
    sizeErrMsg: string;
    totalSizeErrMsg: string;
    maxFileNameErrMsg: string;
    duplicatesFileErrMsg: string;
}
declare module 'yup' {
    interface StringSchema {
        phone(errMsg?: string): this;
        accountName(errMsg?: string): this;
        password(
            lowerCaseErrMsg?: string,
            upperCaseErrMsg?: string,
            digitErrMsg?: string,
            specialCharErrMsg?: string
        ): this;
        repeatPassword(ref: ReferenceOptions<unknown>, errMsg?: string): this;
    }
    interface BaseSchema {
        attachments(
            max?: number,
            size?: number,
            totalSize?: number,
            maxFileName?: number,
            typeErrMsg?: string,
            maxErrMsg?: string,
            sizeErrMsg?: string,
            maxFileNameErrMsg?: string,
            duplicatesFileErrMsg?: string
        ): this;
    }
}

Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'phone',
    function (errMsg: string = validators.string.phone) {
        const phoneRegExp = /^(?:00|\+)?\d{9,15}$/;
        return this.trim().matches(phoneRegExp, errMsg);
    }
);

Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'accountName',
    function (errMsg: string = validators.mixed.default) {
        const accountNameRegExp = /^[\p{L}\s.'-]+$/u;
        return this.trim().min(2).max(50).matches(accountNameRegExp, errMsg);
    }
);

Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'password',
    function (
        lowerCaseErrMsg: string = validators.string.password.lowerCase,
        upperCaseErrMsg: string = validators.string.password.upperCase,
        digitErrMsg: string = validators.string.password.digit,
        specialCharErrMsg: string = validators.string.password.specialChar
    ) {
        return this.min(8)
            .matches(/[a-z]/, lowerCaseErrMsg)
            .matches(/[A-Z]/, upperCaseErrMsg)
            .matches(/\d/, digitErrMsg)
            .matches(/[\W_]/g, specialCharErrMsg)
            .required();
    }
);

Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    'repeatPassword',
    function (
        ref: string,
        errMsg: string = validators.string.password.repeatPassword
    ) {
        return this.oneOf([ref, null], errMsg).required();
    }
);
const attachmentsDefaultParams = {
    max: MAX_FILES_LENGTH,
    size: MAX_FILE_SIZE,
    totalSize: MAX_FILES_TOTAL,
    maxFileName: MAX_FILE_NAME,
    typeErrMsg: '',
    maxErrMsg: '',
    sizeErrMsg: '',
    totalSizeErrMsg: '',
    maxFileNameErrMsg: '',
    duplicatesFileErrMsg: '',
};

Yup.addMethod(
    Yup.mixed,
    'attachments',
    function (v: AttachmentsParamsType = attachmentsDefaultParams) {
        return this.test(
            'totalSize',
            (values: File[], { createError, path }) => {
                let attachmentsTotal = 0;
                if (values?.length > 0) {
                    values.forEach((value) => {
                        attachmentsTotal += value.size;
                    });

                    if (attachmentsTotal > MAX_FILES_TOTAL) {
                        const errorMsg = validators.mixed.attachments.totalSize;

                        return createError({
                            path,
                            params: {
                                totalSize: formatBytes(v.totalSize).toString(),
                            },
                            message: v.totalSizeErrMsg || errorMsg,
                        });
                    }
                }

                return true;
            }
        )
            .test('max', (values: File[], { createError, path }) => {
                if (values?.length > v.max) {
                    return createError({
                        path,
                        params: { max: v.max },
                        message:
                            v.maxErrMsg || validators.mixed.attachments.max,
                    });
                }

                return true;
            })
            .test('size', (values: File[], { createError, path }) => {
                const errors: string[] = [];
                const errorMsg = validators.mixed.attachments.size;
                const isEmptyFileMsg = validators.mixed.attachments.isEmptyFile;

                if (values?.length > 0) {
                    values.forEach((value: any) => {
                        const name = value.name || value.fileName;

                        if (value.size > v.size) {
                            const fileError = createError({
                                path,
                                params: {
                                    size: formatBytes(v.size).toString(),
                                },
                                message: errorMsg,
                            });
                            Object.assign(value, {
                                errorMessage: fileError.message,
                                status: 'error',
                            });
                            if (!errors.includes(name)) {
                                errors.push(name);
                            }
                        }

                        if (value.size === 0) {
                            const fileError = createError({
                                path,
                                params: {
                                    size: formatBytes(v.size).toString(),
                                },
                                message: isEmptyFileMsg,
                            });
                            Object.assign(value, {
                                errorMessage: fileError.message,
                                status: 'error',
                            });
                            if (!errors.includes(value.name)) {
                                errors.push(value.name);
                            }
                        }
                    });

                    if (errors.length) {
                        return createError({
                            path,
                            message:
                                v.sizeErrMsg ||
                                validators.mixed.attachments.default,
                        });
                    }
                }

                return true;
            })
            .test('type', async (values: File[], { createError, path }) => {
                const FILE_BLOCKEDLIST =
                    sessionStorage.getItem('extensions') ||
                    FALLBACK_BLOCKEDLIST;
                const errors: string[] = [];
                const errorMsg = validators.mixed.attachments.notAllowed;

                if (values?.length > 0) {
                    values.forEach((value: any) => {
                        const name = value.name || value.fileName;
                        const ext = getFileExtension(name).toLowerCase();

                        if (
                            ext !== 'unknown' &&
                            FILE_BLOCKEDLIST &&
                            FILE_BLOCKEDLIST.includes(ext)
                        ) {
                            const fileError = createError({
                                path,
                                params: {
                                    size: formatBytes(v.size).toString(),
                                },
                                message: errorMsg,
                            });
                            Object.assign(value, {
                                errorMessage: fileError.message,
                                status: 'error',
                            });
                            if (!errors.includes(value.name)) {
                                errors.push(value.name);
                            }
                        }
                    });
                }

                if (errors.length) {
                    return createError({
                        path,
                        message:
                            v.typeErrMsg ||
                            validators.mixed.attachments.default,
                    });
                }

                return true;
            })
            .test('filename', (values: File[], { createError, path }) => {
                const errors: string[] = [];
                const errorMsg = validators.mixed.attachments.maxFileName;
                if (values?.length > 0) {
                    values.forEach((value: any) => {
                        const name = value.name || value.fileName;
                        if (
                            name.length > v.maxFileName &&
                            !errors.includes(name)
                        ) {
                            const fileError = createError({
                                path,
                                message: errorMsg,
                            });
                            Object.assign(value, {
                                errorMessage: fileError.message,
                                status: 'error',
                            });
                            errors.push(name);
                        }
                    });

                    if (errors.length) {
                        return createError({
                            path,
                            message:
                                v.maxFileNameErrMsg ||
                                validators.mixed.attachments.default,
                        });
                    }
                }

                return true;
            })
            .test('duplicates', (values: File[], { createError, path }) => {
                const errors: string[] = [];
                const errorMsg = `${validators.mixed.attachments.duplicates}\n`;

                if (values?.length > 0) {
                    values.forEach((value: any, index) => {
                        const name = value.name || value.fileName;
                        const duplicates = values.filter((file: any, i) => {
                            const fileName = file.name || file.fileName;
                            return i !== index && fileName === name;
                        });

                        if (duplicates.length > 1 && !errors.includes(name)) {
                            const fileError = createError({
                                path,
                                message: errorMsg,
                            });
                            Object.assign(value, {
                                errorMessage: fileError.message,
                                status: 'error',
                            });
                            errors.push(value.name);
                        }
                    });

                    if (errors.length) {
                        return createError({
                            path,
                            message: v.duplicatesFileErrMsg || errorMsg,
                        });
                    }
                }

                return true;
            })
            .test('failed', (values: File[], { createError, path }) => {
                const errorMsg = validators.mixed.attachments.failed;
                if (values?.length > 0) {
                    const hasError = values.some(
                        (value: any) => value.uploaded === 'failed'
                    );

                    if (hasError) {
                        return createError({
                            path,
                            message: v.sizeErrMsg || errorMsg,
                        });
                    }
                }

                return true;
            });
    }
);

Yup.setLocale(validators);

export * from 'yup';
