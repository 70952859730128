import NoData from 'components/DataState';
import { List } from '@northstar/icons';

const emptyDataComponent = (
    <NoData
        minHeight={530}
        justifyContent="center"
        title="The support request list is empty"
        icon={List}
    />
);

export { emptyDataComponent };
