import Typography from '@northstar/core/Typography';
import Grid from '@northstar/core/Grid';
import Link from '@northstar/core/Link';
import Container from '@northstar/core/Container';
import useSWR from 'swr';
import type { ContactList } from 'types/contactUs';
import Skeleton from '@northstar/core/Skeleton';
import { Box, Divider } from '@northstar/core';

const LoadingContacts = () => (
    <Grid spacing={3}>
        {Array.from(Array(5).keys()).map((index) => {
            return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Skeleton
                        aria-label="skeleton"
                        sx={{
                            width: '100%',
                            my: 1,
                        }}
                    />
                    <Skeleton
                        aria-label="skeleton"
                        sx={{
                            width: '100%',
                            my: 1,
                        }}
                    />
                    <Skeleton
                        aria-label="skeleton"
                        sx={{
                            width: '80%',
                            my: 1,
                        }}
                    />
                </Grid>
            );
        })}
    </Grid>
);

const ContactUsDetails = () => {
    const { data: detailsData } = useSWR<ContactList>('/hs_contact_details');

    return (
        <Container sx={{ paddingY: 5 }}>
            {!detailsData ? (
                <LoadingContacts />
            ) : (
                <>
                    {detailsData?.emergencySupport && (
                        <>
                            <Typography
                                marginBottom={2}
                                variant="h4"
                                gutterBottom
                            >
                                Brand Contact Details
                            </Typography>
                            <Grid>
                                {detailsData?.emergencySupport?.map(
                                    (item, rIndex) => (
                                        <Grid
                                            item
                                            xs={12}
                                            key={rIndex}
                                            sx={{
                                                paddingTop: '0 !important',
                                                paddingBottom: 3,
                                            }}
                                        >
                                            {item.brand && (
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    {item.brand}
                                                </Typography>
                                            )}
                                            {item.support && (
                                                <Box marginBottom={1}>
                                                    <Typography
                                                        variant="body1"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Emergency Support:{' '}
                                                        <Typography
                                                            variant="subtitle1"
                                                            component="span"
                                                        >
                                                            {item.support}
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            )}
                                            {item.hours && (
                                                <Box marginBottom={1}>
                                                    <Typography
                                                        variant="body1"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Business Hours:{' '}
                                                        <Typography
                                                            variant="subtitle1"
                                                            component="span"
                                                            marginRight={1}
                                                        >
                                                            {item.hours}
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box marginBottom={1}>
                                                <Typography
                                                    variant="body1"
                                                    color="textPrimary"
                                                    sx={{
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    Phones:{' '}
                                                    {item.phones.map(
                                                        (phone, index) => (
                                                            <Typography
                                                                key={index}
                                                                variant="subtitle1"
                                                                component="span"
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    marginRight: 1,
                                                                    display:
                                                                        'inline-block',
                                                                }}
                                                            >
                                                                {phone?.region &&
                                                                    `${phone.region}: `}
                                                                <Link
                                                                    href={`tel:${phone.phone}`}
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="textSecondary"
                                                                        component="span"
                                                                    >
                                                                        {
                                                                            phone.phone
                                                                        }
                                                                    </Typography>
                                                                </Link>
                                                                {phone?.options && (
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="span"
                                                                        marginLeft={
                                                                            1
                                                                        }
                                                                    >
                                                                        {
                                                                            phone?.options
                                                                        }
                                                                    </Typography>
                                                                )}
                                                                {phone?.ext && (
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="span"
                                                                        marginLeft={
                                                                            1
                                                                        }
                                                                    >
                                                                        ext.{' '}
                                                                        {
                                                                            phone.ext
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </Typography>
                                                        )
                                                    )}
                                                </Typography>
                                            </Box>
                                            {item?.emails?.length && (
                                                <Box marginBottom={1}>
                                                    <Typography
                                                        variant="body1"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Support Email:{' '}
                                                        {item.emails.map(
                                                            (email, eIndex) => (
                                                                <Link
                                                                    key={eIndex}
                                                                    href={`mailto:${email}`}
                                                                    marginRight={
                                                                        1
                                                                    }
                                                                    display="inline-block"
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        color="textSecondary"
                                                                        component="span"
                                                                    >
                                                                        {email}
                                                                        {item?.emails &&
                                                                            item
                                                                                .emails
                                                                                .length >
                                                                                1 &&
                                                                            item
                                                                                .emails
                                                                                .length !==
                                                                                eIndex +
                                                                                    1 &&
                                                                            ','}
                                                                    </Typography>
                                                                </Link>
                                                            )
                                                        )}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {item?.portalUrl && (
                                                <Box marginBottom={1}>
                                                    <Typography
                                                        variant="body1"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Portal:{' '}
                                                        <Link
                                                            href={
                                                                item.portalUrl
                                                            }
                                                            target="_blank"
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                color="textSecondary"
                                                                component="span"
                                                            >
                                                                {item.portalUrl}
                                                            </Typography>
                                                        </Link>
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Divider
                                                sx={{
                                                    width: '100%',
                                                    marginTop: 3,
                                                }}
                                            />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </>
                    )}
                    <Typography marginBottom={2} variant="h4" gutterBottom>
                        Corporate numbers
                    </Typography>
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            {detailsData.email && (
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    gutterBottom
                                    sx={{
                                        fontWeight: 500,
                                    }}
                                >
                                    Email :{' '}
                                    <Link href={`mailto:${detailsData.email}`}>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            component="span"
                                        >
                                            {detailsData.email}
                                        </Typography>
                                    </Link>
                                </Typography>
                            )}
                        </Grid>
                        {detailsData.phoneNumbers.map((item) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={item.geoLocation}
                            >
                                {item.country && (
                                    <Typography variant="h6">
                                        {item.country}
                                    </Typography>
                                )}
                                {item.geoLocation && (
                                    <Typography
                                        variant="subtitle1"
                                        color="primary"
                                        gutterBottom
                                        sx={{
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        {item.geoLocation}
                                    </Typography>
                                )}
                                {item.tollFreeNumber && (
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 500,
                                        }}
                                    >
                                        Toll Free :{' '}
                                        <Link
                                            href={`tel:${item.tollFreeNumber}`}
                                        >
                                            <Typography
                                                variant="body1"
                                                color="textSecondary"
                                                component="span"
                                            >
                                                {item.tollFreeNumber}
                                            </Typography>
                                        </Link>
                                    </Typography>
                                )}
                                {item.phoneNumber && (
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 500,
                                        }}
                                    >
                                        Phone :{' '}
                                        <Link href={`tel:${item.phoneNumber}`}>
                                            <Typography
                                                variant="body1"
                                                color="textSecondary"
                                                component="span"
                                            >
                                                {item.phoneNumber}
                                            </Typography>
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </Container>
    );
};

export { ContactUsDetails };
