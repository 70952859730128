let primaryRequestResolve: (() => void) | null = null;

type PrimaryRequest = {
    isCompleted: boolean;
    waitToComplete: Promise<void>;
};

const primaryRequest: PrimaryRequest = {
    isCompleted: false,
    waitToComplete: new Promise((resolve) => {
        primaryRequestResolve = resolve;
    }),
};

const markPrimaryRequestCompleted = () => {
    primaryRequest.isCompleted = true;
    primaryRequestResolve?.();
};

export { primaryRequest, markPrimaryRequestCompleted };
