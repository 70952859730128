import MenuItem from '@northstar/core/MenuItem';
import Divider from '@northstar/core/Divider';
import Typography from '@northstar/core/Typography';
import { useAuth } from 'contexts/Auth';
import { Link } from 'components/Link';
import { useApp } from 'contexts/App';
import { ThemeSwitcher } from '../ThemeSwitcher';

const UserAccountNav = () => {
    const { signOut, user, manageAccount } = useAuth();
    const { appConfig } = useApp();

    return (
        <>
            <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{ py: 0, px: 1 }}
            >
                ACCOUNT
            </Typography>
            {user?.is_portal_user && !appConfig?.IS_READ_ONLY_MAINTENANCE && (
                <MenuItem component={Link} to="/account">
                    General
                </MenuItem>
            )}
            <MenuItem component={Link} to="/account/products">
                My Products
            </MenuItem>
            <MenuItem
                onClick={manageAccount}
                sx={{
                    justifyContent: 'space-between',
                }}
            >
                Manage Account
            </MenuItem>
            <Divider sx={{ my: 1 }} />
            <MenuItem
                sx={{
                    padding: 0,
                    '& > label:first-of-type': {
                        paddingX: 2,
                        paddingY: 0.75,
                    },
                }}
            >
                <ThemeSwitcher />
            </MenuItem>
            <Divider sx={{ my: 1 }} />
            <MenuItem onClick={() => signOut()}>Log Out</MenuItem>
        </>
    );
};

export default UserAccountNav;
