import { Main } from 'components/layouts';
import PageError from 'components/PageError';

const NotFound = () => {
    return (
        <Main>
            <PageError status="404" />
        </Main>
    );
};

export default NotFound;
