import Card from '@northstar/core/Card';
import Container, { ContainerProps } from '@northstar/core/Container';

interface Props extends ContainerProps {}

const PageBody = ({ maxWidth, children }: Props) => {
    return (
        <Container maxWidth={maxWidth} sx={{ mt: -4, mb: 10 }}>
            <Card sx={{ boxShadow: 3, padding: 4, position: 'relative' }}>
                {children}
            </Card>
        </Container>
    );
};

export default PageBody;
