import { useEffect } from 'react';
import { elementIsVisibleInViewport } from 'utils/elementIsVisibleInViewport';

type Props = {
    errors: { [key: string]: string | string[] };
    isSubmitting: boolean;
    container: HTMLFormElement | null;
};

const useScrollToInvalidField = ({
    isSubmitting,
    errors,
    container,
}: Props) => {
    useEffect(() => {
        if (!isSubmitting) return;

        const errorKeys = Object.keys(errors);

        if (container) {
            const inputElements = container.querySelectorAll('input');

            Array.from(inputElements).every((element) => {
                if (errorKeys.includes(element.name)) {
                    if (!elementIsVisibleInViewport(element)) {
                        element.scrollIntoView({ block: 'center' });
                    }

                    element.focus?.();

                    return false;
                }

                return true;
            });
        }
    }, [isSubmitting, errors, container]);
};

export { useScrollToInvalidField };
