import Divider from '@northstar/core/Divider';
import Box from '@northstar/core/Box';
import Skeleton from '@northstar/core/Skeleton';

const LoadingData = () => {
    return (
        <Box>
            <Box paddingY={3}>
                <Skeleton aria-label="skeleton" width="80%" height={21} />
                <Skeleton aria-label="skeleton" width="100%" height={20} />
                <Skeleton aria-label="skeleton" width="35%" height={24} />
            </Box>
            <Divider />
        </Box>
    );
};

export default LoadingData;
