const getCachedSearchResults = (locationSearch: string) => {
    const defaultSearchResult = {
        hits: [],
        nextLink: null,
    };

    const sessionData = sessionStorage.getItem('searchResults');

    if (sessionData) {
        try {
            const parsed = JSON.parse(sessionData);

            if (parsed.searchKey !== locationSearch) return defaultSearchResult;

            return parsed.data;
        } catch {
            return defaultSearchResult;
        }
    }

    return defaultSearchResult;
};

export { getCachedSearchResults };
