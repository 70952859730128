import { Checkbox, FormControlLabel, Link, Typography } from '@northstar/core';

export const SeverityConfirmeContnet = ({
    confirm,
    onConfirme,
}: {
    confirm: boolean;
    onConfirme: () => void;
}) => (
    <>
        <Typography marginBottom={4}>
            In order to complete the escalation of this request to <b>High</b>{' '}
            or <b>Critical</b>, you need to contact support by phone.
        </Typography>
        <Link href="/contact-us" target="_blank">
            https://support.fortra.com/contact-us
        </Link>
        <FormControlLabel
            id="confirme"
            control={<Checkbox color="primary" />}
            label="I will contact support by phone"
            defaultChecked={confirm}
            onChange={onConfirme}
            sx={{ marginTop: 3 }}
        />
    </>
);
