import { QuestionCriteria } from 'types/createCase';
import { ProductPermission } from 'types/permissions';

const getProductMatchCriteria = (
    value: ProductPermission | null,
    questionCriteria: QuestionCriteria[]
) => {
    if (!value) return null;

    return questionCriteria.find(
        (qCItem) =>
            (value.productId && value.productId === qCItem.productFamilyId) ||
            (value.brandId && value.brandId === qCItem.productFamilyId)
    );
};

export { getProductMatchCriteria };
