import { useFormik, FormikHelpers } from 'formik';
import Box from '@northstar/core/Box';
import Divider from '@northstar/core/Divider';
import Grid from '@northstar/core/Grid';
import TextField from '@northstar/core/TextField';
import Typography from '@northstar/core/Typography';
import Link from '@northstar/core/Link';

import { Button } from 'components/Button';
import FormControl from '@northstar/core/FormControl';
import useSWR, { mutate } from 'swr';
import type { IGetUserContactInfo } from 'types/user';
import { useApp } from 'contexts/App';
import CountriesDropDown from 'components/CountriesDropdown';
import { JobTitlesDropDown } from 'components/JobTitlesDropDown';
import { countriesList } from 'constants/countriesList';
import axios from 'utils/axios';
import { object, string } from 'libs/validation';
import LoadingBox from 'components/LoadingBox';
import { jobList } from 'constants/jobList';
import { UserType, useAuth } from 'contexts/Auth';
import { useScrollToInvalidField } from 'hooks/useScrollToInvalidField';
import { getFieldErrorInfo } from 'utils/getFieldErrorInfo';
import { useRef } from 'react';

type IFormFieldValues = {
    address: string;
    city: string;
    companyName: string;
    country: string;
    email: string;
    jobTitle: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    phoneNumber: string;
    postalCode: string;
};

const validationSchema = object({
    firstName: string().accountName().required(),
    lastName: string().accountName().required(),
    companyName: string().trim().min(2).max(100).required(),
    email: string().email().required(),
    phoneNumber: string().required().phone(),
    mobileNumber: string().phone(),
    country: string().min(2).max(80).required(),
    jobTitle: string().min(2).max(80),
    city: string().trim().min(2).max(80).required(),
    address: string().trim().required().min(2).max(200),
    postalCode: string().trim().required().min(4).max(40),
});

const UserGeneralPage = () => {
    const { addNotification } = useApp();
    const { user, setUser } = useAuth();
    const formItemRef = useRef<HTMLFormElement>(null);

    const { data: contactInfoData } = useSWR<IGetUserContactInfo>(
        '/users/contact_info'
    );

    const initialValues = {
        address: contactInfoData?.address?.addressLine || '',
        city: contactInfoData?.address?.city || '',
        companyName: contactInfoData?.companyName || '',
        country: contactInfoData?.address?.country
            ? String(countriesList[contactInfoData.address.country])
            : '',
        email: contactInfoData?.email || '',
        jobTitle: contactInfoData?.jobTitle
            ? String(jobList[contactInfoData.jobTitle])
            : '',
        firstName: contactInfoData?.firstName || '',
        lastName: contactInfoData?.lastName || '',
        mobileNumber: contactInfoData?.phoneNumber?.mobile || '',
        phoneNumber: contactInfoData?.phoneNumber?.business || '',
        postalCode: contactInfoData?.address?.zipCode || '',
    };

    const onSubmit = async (
        values: IFormFieldValues,
        actions: FormikHelpers<IFormFieldValues>
    ) => {
        const payload = validationSchema.cast(values) as IFormFieldValues;

        const {
            address,
            city,
            firstName,
            lastName,
            mobileNumber,
            phoneNumber,
            postalCode,
            country,
            jobTitle,
        } = payload;

        const data = {
            firstName,
            lastName,
            jobTitle,
            phoneNumber: {
                business: phoneNumber,
                mobile: mobileNumber,
            },
            address: {
                addressLine: address,
                city,
                country,
                zipCode: postalCode,
            },
        };

        try {
            actions.setSubmitting(true);
            const { data: response } = await axios.put(
                '/users/contact_info',
                data
            );

            addNotification({
                message: 'Contact information updated successfully',
                status: 200,
            });

            mutate('/users/contact_info', response, false);

            const newUserData = {
                ...user,
                name: `${firstName} ${lastName}`,
            } as UserType;

            setUser(newUserData);
            actions.resetForm();
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response.status,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });

    const { isSubmitting, errors } = formik;

    useScrollToInvalidField({
        isSubmitting,
        errors,
        container: formItemRef.current,
    });

    const handleDropDownValueChange = (value: string, name: string) => {
        formik.setFieldValue(name, value);
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom fontWeight={500}>
                Change your private information
            </Typography>
            <Typography variant="body2" color="textSecondary">
                Your first and last name will display alongside any comment or
                forum post you make on the site. Fortra does not sell or
                distribute your information. Please read our{' '}
                <Link
                    color="primary"
                    href="https://fortra.com/privacy-policy"
                    underline="none"
                >
                    privacy policy
                </Link>{' '}
                to be informed how we manage your private data.
            </Typography>
            <Box paddingY={4}>
                <Divider />
            </Box>
            <form onSubmit={formik.handleSubmit} ref={formItemRef}>
                {contactInfoData ? (
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                Enter your first name
                            </Typography>
                            <TextField
                                label="First name *"
                                variant="outlined"
                                name="firstName"
                                fullWidth
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={
                                    getFieldErrorInfo(formik, 'firstName')
                                        .isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'firstName')
                                        .helperText
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                Enter your last name
                            </Typography>
                            <TextField
                                label="Last name *"
                                variant="outlined"
                                name="lastName"
                                fullWidth
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={
                                    getFieldErrorInfo(formik, 'lastName')
                                        .isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'lastName')
                                        .helperText
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                Email
                            </Typography>
                            <TextField
                                disabled
                                label="Email"
                                variant="outlined"
                                name="email"
                                fullWidth
                                value={formik.values.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                Enter your phone number
                            </Typography>
                            <TextField
                                label="Business Phone *"
                                variant="outlined"
                                name="phoneNumber"
                                fullWidth
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                error={
                                    getFieldErrorInfo(formik, 'phoneNumber')
                                        .isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'phoneNumber')
                                        .helperText
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                &nbsp;
                            </Typography>
                            <TextField
                                label="Mobile Phone"
                                variant="outlined"
                                name="mobileNumber"
                                fullWidth
                                value={formik.values.mobileNumber}
                                onChange={formik.handleChange}
                                error={
                                    getFieldErrorInfo(formik, 'mobileNumber')
                                        .isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'mobileNumber')
                                        .helperText
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                Company name
                            </Typography>
                            <TextField
                                disabled
                                label="Company name"
                                variant="outlined"
                                name="companyName"
                                fullWidth
                                value={formik.values.companyName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                Enter your Job Title
                            </Typography>
                            <FormControl fullWidth>
                                <JobTitlesDropDown
                                    selectedValue={formik.values.jobTitle}
                                    onChange={handleDropDownValueChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                sx={{ marginBottom: 2 }}
                                fontWeight={500}
                            >
                                Enter your address
                            </Typography>
                            <TextField
                                label="Address *"
                                variant="outlined"
                                name="address"
                                fullWidth
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={
                                    getFieldErrorInfo(formik, 'address').isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'address')
                                        .helperText
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CountriesDropDown
                                selectedValue={formik.values.country}
                                onChange={handleDropDownValueChange}
                                error={
                                    getFieldErrorInfo(formik, 'country').isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'country')
                                        .helperText
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="City *"
                                variant="outlined"
                                name="city"
                                fullWidth
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={
                                    getFieldErrorInfo(formik, 'city').isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'city').helperText
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="ZIP/Postal Code *"
                                variant="outlined"
                                name="postalCode"
                                fullWidth
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                                error={
                                    getFieldErrorInfo(formik, 'postalCode')
                                        .isError
                                }
                                helperText={
                                    getFieldErrorInfo(formik, 'postalCode')
                                        .helperText
                                }
                            />
                        </Grid>
                        <Grid item container xs={12}>
                            <Button
                                variant="contained"
                                disabled={formik.isSubmitting || !formik.dirty}
                                isLoading={formik.isSubmitting}
                                type="submit"
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <LoadingBox />
                )}
            </form>
        </Box>
    );
};

export default UserGeneralPage;
