import { FC, ReactNode } from 'react';
import { Breadcrumb } from 'components/Breadcrumb';
import { BreadcrumbItem } from 'components/Breadcrumb/Breadcrumb';
import PageError from 'components/PageError';
import { BoxProps } from '@northstar/core/types/styles';

interface IProps extends BoxProps {
    children: ReactNode;
    bgcolor?: string;
    breadcrumbs?: BreadcrumbItem[];
    breadcrumbsMode?: 'dark' | 'light';
    breadcrumbFullWidth?: boolean;
    errorStatus?: number;
}

const Main: FC<IProps> = ({
    children,
    breadcrumbs,
    breadcrumbsMode = 'light',
    breadcrumbFullWidth,
    errorStatus = undefined,
}) => {
    const pageHasError =
        errorStatus &&
        (errorStatus >= 500 ||
            errorStatus === 400 ||
            errorStatus === 404 ||
            errorStatus === 403);

    return pageHasError ? (
        <PageError status={errorStatus} />
    ) : (
        <>
            {breadcrumbs && (
                <Breadcrumb
                    data={breadcrumbs}
                    mode={breadcrumbsMode}
                    breadcrumbFullWidth={breadcrumbFullWidth}
                />
            )}
            {children}
        </>
    );
};

export default Main;
