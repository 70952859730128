const formatedDate = (
    date: string
): {
    month: number;
    monthString: string;
    day: number;
    year: number;
    time: string;
    toString: () => string;
    toAddedAt: () => string;
    toDate: () => string;
    toLocalDateString: () => string;
    toLocalDateFormat: () => string;
    toDateFormatCT: () => string;
} => {
    const d = new Date(date);
    const [
        month,
        monthString,
        day,
        year,
        time,
        fullDateFormat,
        fullDateFormatCT,
    ] = [
        d.getMonth() + 1,
        d.toLocaleString('default', { month: 'short' }),
        d.getDate(),
        d.getFullYear(),
        d.toLocaleTimeString('default', { timeStyle: 'short' }),
        d.toLocaleDateString('default', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }),
        d.toLocaleDateString('default', {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'America/Chicago',
        }),
    ];

    return {
        month,
        monthString,
        day,
        year,
        time,
        toString() {
            return `${monthString} ${day}, ${year} ${time}`;
        },
        toDate() {
            return `${month}/${day}/${year}`;
        },
        toLocalDateString() {
            return `${year}-${month}-${day} ${time}`;
        },
        toAddedAt() {
            return `Added ${monthString} ${day}, ${year} at ${time}`;
        },
        toLocalDateFormat() {
            return `${fullDateFormat}`;
        },
        toDateFormatCT() {
            return `${fullDateFormatCT} CT`;
        },
    };
};

export { formatedDate };
