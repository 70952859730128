import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import Button from '@northstar/core/Button';
import TextField from '@northstar/core/TextField';
import Container from '@northstar/core/Container';
import InputAdornment from '@northstar/core/InputAdornment';
import { useTheme } from '@northstar/core/styles';
import { Image } from 'components/Image';
import { Search } from '@northstar/icons';
import { Link } from 'components/Link';
import { useNavigate } from 'react-router-dom';
import { UserType, useAuth } from 'contexts/Auth';

interface Props {
    title: string;
    subTitle?: string;
    image: string;
    hasForm?: boolean;
    user?: UserType | null;
}

const HeroBanner = ({
    title,
    subTitle,
    image,
    hasForm = false,
    user = null,
}: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { signIn } = useAuth();
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const searchValue = e.target.elements.search.value.trim() || '';
        if (searchValue) {
            navigate({
                pathname: '/search',
                search: `q=${encodeURIComponent(searchValue)}`,
            });
        }
    };

    return (
        <Box
            sx={{
                bgcolor: 'marketing.duskForest',
                color: 'common.white',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Container
                sx={{
                    paddingX: [0],
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Box
                    component={Container}
                    width={1}
                    order={{ xs: 2, md: 1 }}
                    sx={{ py: 4 }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    zIndex={10}
                >
                    <Typography variant="h1" marginBottom={2}>
                        {title}
                    </Typography>
                    <Typography variant="body1" marginBottom={3}>
                        {subTitle}
                    </Typography>
                    {hasForm && (
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            sx={{
                                '& .MuiInputBase-input.MuiOutlinedInput-input':
                                    {
                                        bgcolor: 'background.paper',
                                    },
                            }}
                            display="flex"
                            flexDirection={{
                                xs: 'column',
                                sm: 'row',
                            }}
                            alignItems={{
                                xs: 'stretched',
                                sm: 'flex-start',
                            }}
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                sx={{
                                    bgcolor: 'white',
                                    borderRadius: 1,
                                    '& .MuiInputBase-input': {
                                        height: 54,
                                        boxSizing: 'border-box',
                                        bgcolor: 'transparent !important',
                                        color: 'success.contrastText',
                                    },
                                }}
                                variant="outlined"
                                color="primary"
                                size="medium"
                                placeholder="Search Fortra Support"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search
                                                sx={{
                                                    color: 'marketing.fortaForest',
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                defaultValue=""
                                name="search"
                            />
                            <Button
                                variant="outlined"
                                size="large"
                                sx={{
                                    height: 54,
                                    mt: { xs: 2, sm: 0 },
                                    ml: { sm: 2 },
                                    color: 'white',
                                    borderColor: 'white',
                                    '&:hover': {
                                        borderColor: 'white',
                                    },
                                }}
                                type="submit"
                            >
                                Search
                            </Button>
                        </Box>
                    )}
                    {user?.is_portal_user && (
                        <Link
                            display="block"
                            marginTop={{ xs: 1, sm: 2, md: 3 }}
                            to="/support/requests"
                            variant="body1"
                            sx={{ color: 'common.white' }}
                        >
                            Contact support to create a request or view open
                            requests
                        </Link>
                    )}
                    {!user && (
                        <Typography
                            marginTop={{ xs: 1, sm: 2, md: 3 }}
                            component="span"
                            variant="body1"
                            onClick={() => signIn()}
                            sx={{
                                color: 'common.white',
                                cursor: 'pointer',
                                width: 'fit-content',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            Log in to create a request, view open requests, and
                            manage your account
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        flex: { xs: '0 0 100%', md: '0 0 40%' },
                        maxWidth: { xs: '100%', md: '40%' },
                        order: { xs: 1, md: 2 },
                        position: {
                            xs: 'relative',
                            md: 'static',
                        },
                    }}
                >
                    <svg
                        height="100%"
                        viewBox="0 0 653 462"
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            zIndex: 1,
                        }}
                    >
                        <path
                            d="M0 0L228 0L0 463V0Z"
                            fill={theme.palette.marketing.duskForest}
                        />
                        <path
                            d="M119 0H204L161.5 85L119 0Z"
                            fill={theme.palette.marketing.mintGreen}
                        />
                    </svg>
                    <svg
                        height="100%"
                        viewBox="0 0 653 462"
                        style={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                        }}
                    >
                        <path
                            d="M669 207V462H543L669 207Z"
                            fill={theme.palette.marketing.duskForest}
                        />
                        <path
                            d="M652 462L567 462L609.5 377L652 462Z"
                            fill={theme.palette.marketing.skyBlue}
                        />
                    </svg>
                    <Box
                        sx={{
                            width: { xs: 1, md: '50vw' },
                            height: { xs: 300, md: 462 },
                            minHeight: '100%',
                        }}
                    >
                        <Image
                            src={image}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                effect: 'blur',
                                filter:
                                    theme.palette.mode === 'dark'
                                        ? 'brightness(0.7)'
                                        : 'none',
                            }}
                            alt="Home banner"
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default HeroBanner;
