import { Typography, Grid, TextField, Autocomplete } from '@northstar/core';
import { Brand, Product } from 'types/products';
import { getFieldErrorInfo } from 'utils/getFieldErrorInfo';

type TProps = {
    showMoreFields: boolean;
    productBrandList: Brand[] | undefined;
    selectedBrandsProducts: Product[];
    handleBrandChange: (values: Brand[]) => void;
    handleProductChange: (values: Product[]) => void;
    formik: any;
};

const ShowMoreFields = ({
    formik,
    handleBrandChange,
    handleProductChange,
    productBrandList,
    selectedBrandsProducts,
    showMoreFields,
}: TProps) =>
    showMoreFields ? (
        <>
            <Grid item xs={12}>
                <Typography
                    variant="body2"
                    fontWeight={500}
                    sx={{
                        marginBottom: 2,
                        fontWeight: '500',
                    }}
                >
                    Product Brand
                </Typography>
                <Autocomplete
                    multiple
                    disablePortal
                    id="productBrands-combo-box"
                    options={productBrandList || []}
                    loading={!productBrandList}
                    getOptionLabel={(option) => option.name || 'Null'}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(_, values) => handleBrandChange(values)}
                    renderOption={(props, option) => {
                        if (option.name === null) {
                            return null;
                        }
                        return (
                            <li {...props} key={props.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Product Brands *"
                            InputLabelProps={{ shrink: true }}
                            name="product_brands"
                            placeholder="Select all that apply"
                            error={
                                getFieldErrorInfo(formik, 'product_brands')
                                    .isError
                            }
                            helperText={
                                getFieldErrorInfo(formik, 'product_brands')
                                    .helperText
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="body2"
                    fontWeight={500}
                    sx={{
                        marginBottom: 2,
                        fontWeight: '500',
                    }}
                >
                    Products owned
                </Typography>
                <Autocomplete
                    multiple
                    disablePortal
                    id="productOwned-combo-box"
                    options={selectedBrandsProducts || []}
                    loading={!selectedBrandsProducts}
                    getOptionLabel={(option) => option.name || 'Null'}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(_, values) => handleProductChange(values)}
                    renderOption={(props, option) => {
                        if (option.name === null) {
                            return null;
                        }
                        return (
                            <li {...props} key={props.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Products owned *"
                            InputLabelProps={{ shrink: true }}
                            name="product_owned"
                            placeholder="Select all that apply"
                            error={
                                getFieldErrorInfo(formik, 'product_owned')
                                    .isError
                            }
                            helperText={
                                getFieldErrorInfo(formik, 'product_owned')
                                    .helperText
                            }
                        />
                    )}
                />
            </Grid>
        </>
    ) : null;

export { ShowMoreFields };
