import { buttons } from './buttons';

const editorConfig = {
    addNewLine: false,
    addNewLineOnDBLClick: false,
    buttons,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    spellcheck: true,
    toolbar: true,
    toolbarAdaptive: false,
    toolbarSticky: false,
    toolbarStickyOffset: 0,
    maxHeight: 400,
    cleanHTML: {
        cleanOnPaste: true,
        fillEmptyParagraph: false,
        removeEmptyElements: true,
        denyTags: {
            iframe: true,
            embed: true,
            video: true,
            audio: true,
            script: true,
        },
    },
} as const;

export { editorConfig };
