import { AccordionSummary } from '@northstar/core';
import Typography from '@northstar/core/Typography';
import { ExpandMore as ExpandMoreIcon } from '@northstar/icons';

type Props = {
    accountName: string;
    isSingleAccount: boolean | undefined;
};

const AccountTitle = ({ accountName, isSingleAccount }: Props) =>
    isSingleAccount ? (
        <Typography variant="h6" fontWeight={500} sx={{ margin: '20px' }}>
            {accountName}
        </Typography>
    ) : (
        <AccordionSummary
            sx={{ cursor: isSingleAccount ? 'default !important' : 'pointer' }}
            expandIcon={!isSingleAccount && <ExpandMoreIcon />}
        >
            <Typography variant="h6" fontWeight={500}>
                {accountName}
            </Typography>
        </AccordionSummary>
    );

export { AccountTitle };
