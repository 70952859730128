const getComparedValues = <T>(
    valueA: string | T[keyof T],
    valueB: string | T[keyof T]
) => {
    if (valueA < valueB) {
        return -1;
    }

    if (valueA > valueB) {
        return 1;
    }

    return 0;
};

export const objectOrderBy = <T>(
    arrayOfObj: T[],
    orderBy: keyof T,
    expression: 'ASC' | 'DESC' = 'ASC'
): T[] => {
    if (!arrayOfObj || arrayOfObj.length <= 1) {
        return arrayOfObj;
    }

    return arrayOfObj.sort((a: T, b: T) => {
        const valueA =
            typeof a[orderBy] === 'string'
                ? (a[orderBy] as string).toLowerCase()
                : a[orderBy];
        const valueB =
            typeof b[orderBy] === 'string'
                ? (b[orderBy] as string).toLowerCase()
                : b[orderBy];

        const comparison = getComparedValues(valueA, valueB);

        return expression === 'DESC' ? -comparison : comparison;
    });
};
