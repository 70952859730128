import { useAuth } from 'contexts/Auth';
import { ComponentType } from 'react';
import LoadingBox from 'components/LoadingBox';

export const withAuth = (Component: ComponentType) => () => {
    const { user, signIn } = useAuth();

    if (!user) {
        signIn();
        return (
            <LoadingBox
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                }}
            />
        );
    }

    return <Component />;
};
