import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { formatedDate } from 'utils/formatedDate';
import { TooltipCell } from '@northstar/plus';
import { casesDefaultFilterFn } from '../filterFn/casesDefaultFilterFn';

const CaseCreateDateColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('createDate', {
        header: 'Submitted At',
        filterFn: casesDefaultFilterFn,
        cell: (cell) =>
            cell.getValue() === 'Draft' ? (
                ''
            ) : (
                <TooltipCell
                    text={formatedDate(cell.getValue() as string).toDate()}
                />
            ),
    });

export { CaseCreateDateColumn };
