import Paper from '@northstar/core/Paper';
import { VisibilityOff } from '@northstar/icons';
import Button from '@northstar/core/Button';
import { Link } from 'components/Link';
import DataState from 'components/DataState';
import { Container } from 'components/Container';

const AccessRestricted = () => {
    return (
        <Container aria-label="Access Restricted Container">
            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    mb: 2,
                    p: 2,
                    maxWidth: 840,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <DataState
                    title="You do not have permission to view this page"
                    description="You do not have permission to access the
                    resources that you have requested. If you think
                    that you have reached this page in error, please
                    reach out to our support team to resolve this
                    issue."
                    icon={VisibilityOff}
                    actions={[
                        <Button
                            size="medium"
                            variant="text"
                            sx={{
                                marginLeft: 5,
                            }}
                            component={Link}
                            to="/"
                        >
                            BACK HOME
                        </Button>,
                        <Button
                            size="medium"
                            variant="contained"
                            sx={{
                                marginLeft: 5,
                            }}
                            component={Link}
                            to="/support/requests"
                        >
                            CONTACT SUPPORT
                        </Button>,
                    ]}
                />
            </Paper>
        </Container>
    );
};

export default AccessRestricted;
