import { useApp } from 'contexts/App';
import Button from '@northstar/core/Button';
import Container from '@northstar/core/Container';
import Box from '@northstar/core/Box';
import { Link } from 'components/Link';
import IconButton from '@northstar/core/IconButton';
import { Close } from '@northstar/icons';
import { useState } from 'react';

import SanitizeHTML from 'components/SanitizeHTML';

const InfoBanner = () => {
    const { appConfig, removeAnnouncementBanner } = useApp();
    const [show, setShow] = useState(appConfig?.announcementStarted);

    const closeBanner = () => {
        removeAnnouncementBanner();
        setShow(false);
    };

    const color = {
        text: 'rgba(0, 0, 0, 0.87)',
        button: 'marketing.fortaForest',
    };

    return show ? (
        <Box
            bgcolor="marketing.skyBlue"
            paddingY={1}
            minHeight={52}
            display="flex"
            alignItems="center"
            aria-label="info banner"
        >
            <Container>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    paddingRight={8}
                >
                    <SanitizeHTML
                        sx={{
                            color: color.text,
                            '& a': {
                                color: 'marketing.webLinks',
                                textDecoration: 'none',
                            },
                        }}
                        fontWeight={500}
                        html={appConfig?.banner?.text}
                    />
                    {appConfig?.banner?.href && (
                        <Button
                            component={Link}
                            to={appConfig?.banner?.href}
                            flex="none"
                            sx={{
                                marginLeft: 2,
                                color: color.button,
                            }}
                        >
                            Learn more
                        </Button>
                    )}
                    {appConfig?.banner?.canClose && (
                        <IconButton
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 0,
                                color: color.text,
                            }}
                            onClick={closeBanner}
                        >
                            <Close />
                        </IconButton>
                    )}
                </Box>
            </Container>
        </Box>
    ) : null;
};

export default InfoBanner;
