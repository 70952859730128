import Skeleton from '@northstar/core/Skeleton';
import Box from '@northstar/core/Box';

const ProductsLoading = () => (
    <Box paddingY={0.4}>
        <Skeleton aria-label="skeleton" width="100%" height={24} />
        <Skeleton
            aria-label="skeleton"
            width="100%"
            animation="wave"
            height={24}
        />
    </Box>
);

export { ProductsLoading };
