import Paper from '@northstar/core/Paper';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import { Description, Folder, Image, PlayCircleFilled } from '@northstar/icons';
import { imageFormats, documentFormats, videoFormats } from 'constants/file';

type TProps = {
    fileExtension: string;
    altText: string;
};

const AttachmentType = ({ fileExtension, altText }: TProps) => (
    <Box>
        <Paper
            variant="outlined"
            elevation={0}
            sx={{
                m: 1,
                borderRadius: 1,
                lineHeight: 0,
                width: '100px',
                height: '80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                }}
            >
                {videoFormats.includes(fileExtension) && (
                    <PlayCircleFilled sx={{ fontSize: 55 }} color="action" />
                )}
                {documentFormats.includes(fileExtension) && (
                    <Folder sx={{ fontSize: 55 }} color="action" />
                )}
                {imageFormats.includes(fileExtension) && (
                    <Image
                        sx={{ fontSize: 55 }}
                        color="action"
                        titleAccess={altText}
                    />
                )}
                {!documentFormats.includes(fileExtension) &&
                    !videoFormats.includes(fileExtension) &&
                    !imageFormats.includes(fileExtension) && (
                        <Description sx={{ fontSize: 55 }} color="action" />
                    )}

                <Typography
                    fontSize="12px"
                    fontWeight={500}
                    textTransform="uppercase"
                    labelColor="secondary"
                    bgcolor="#C0EEE2"
                    sx={{
                        padding: '3px 7px',
                        position: 'absolute',
                        borderRadius: 1,
                        lineHeight: 1,
                        bottom: -2,
                        right: -8,
                    }}
                >
                    {fileExtension}
                </Typography>
            </Box>
        </Paper>
    </Box>
);

export { AttachmentType };
