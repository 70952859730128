import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { casesDefaultFilterFn } from '../filterFn/casesDefaultFilterFn';

const CreatedByColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('createdBy', {
        header: 'Creator',
        filterFn: casesDefaultFilterFn,
    });

export { CreatedByColumn };
