import { MouseEvent } from 'react';
import Box from '@northstar/core/Box';
import { Link } from 'components/Link';
import List from '@northstar/core/List';
import ListItem from '@northstar/core/ListItem';
import { ListItemSecondaryAction, ListItemText } from '@northstar/core';
import IconButton from '@northstar/core/IconButton';
import { MoreVert as MoreVertIcon } from '@northstar/icons';
import type { Brand, Product } from 'types/products';
import { NoProducts } from 'components/DataState/NoProducts';

type Props = {
    products: Brand[];
    handleClick: (event: MouseEvent<HTMLElement>, product: Product) => void;
};

const ProductsSection = ({ products, handleClick }: Props) => {
    if (!products.length) return <NoProducts />;

    return (
        <Box>
            {products.map(
                (brand, index) =>
                    brand.productList.length > 0 && (
                        <Box marginBottom={2} key={brand.id}>
                            <ListItemText>{brand.name}</ListItemText>
                            <List
                                sx={{
                                    p: 0,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    borderRadius: 1,
                                }}
                            >
                                {brand.productList.map((product) =>
                                    product.name && product.slug ? (
                                        <ListItem
                                            key={product.id}
                                            divider={
                                                index !== products.length - 1
                                            }
                                        >
                                            <Link to={`/${product.slug}`}>
                                                {product.name}
                                            </Link>

                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    disabled={
                                                        product.isSecured &&
                                                        Object.values(
                                                            product.permissions ||
                                                                {}
                                                        ).filter(Boolean)
                                                            .length === 0
                                                    }
                                                    edge="end"
                                                    aria-label="product-menu"
                                                    size="medium"
                                                    onClick={(
                                                        e: MouseEvent<HTMLElement>
                                                    ) =>
                                                        handleClick(e, product)
                                                    }
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ) : null
                                )}
                            </List>
                        </Box>
                    )
            )}
        </Box>
    );
};

export { ProductsSection };
