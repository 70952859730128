import Typography from '@northstar/core/Typography';
import Grid from '@northstar/core/Grid';
import { Success as SuccessIcon } from '@northstar/icons';

interface Props {
    title: string;
    description: string;
}

const AlertFormSubmited = ({ title, description }: Props) => {
    return (
        <>
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                sx={{ paddingTop: '25px' }}
            >
                <SuccessIcon
                    sx={{
                        fill: '#00b485',
                        mx: 1,
                        fontSize: 36,
                    }}
                />
                <Typography variant="h4" align="center">
                    {title}
                </Typography>
            </Grid>

            <Typography
                color="textSecondary"
                align="center"
                sx={{ paddingBottom: '25px' }}
            >
                {description}
            </Typography>
        </>
    );
};

export default AlertFormSubmited;
