import Box from '@northstar/core/Box';
import Card from '@northstar/core/Card';
import Typography from '@northstar/core/Typography';
import Button from '@northstar/core/Button';
import { Link } from 'components/Link';
import { useAuth } from 'contexts/Auth';
import Container from '@northstar/core/Container';
import { useTheme } from '@northstar/core/styles';

const ContactFooter = () => {
    const { user, signIn } = useAuth();
    const theme = useTheme();
    if (user && !user?.is_portal_user) {
        return null;
    }
    return (
        <Box
            paddingY={4}
            marginTop="auto"
            sx={{
                background: 'transparent',
                backgroundImage: `linear-gradient(180deg, transparent 43%, ${theme.palette.marketing.duskForest} 0%)`,
            }}
        >
            <Container>
                <Box
                    component={Card}
                    boxShadow={4}
                    paddingTop={5}
                    paddingBottom={3.5}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography
                            variant="h4"
                            align="center"
                            data-aos="fade-up"
                            sx={{ fontWeight: 500 }}
                            marginBottom={1.5}
                        >
                            Still not finding the help you need?
                        </Typography>
                        <Box
                            width={1}
                            display="flex"
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            alignItems={{
                                xs: 'stretched',
                                sm: 'flex-start',
                            }}
                            justifyContent="center"
                        >
                            {user?.is_portal_user && (
                                <Button
                                    component={Link}
                                    variant="contained"
                                    color="primary"
                                    sx={{ m: 1.5 }}
                                    to="/support/requests"
                                >
                                    CONTACT SUPPORT
                                </Button>
                            )}
                            {!user && (
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ m: 1.5 }}
                                        onClick={() => signIn()}
                                    >
                                        LOG IN
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        to="/contact-us"
                                        component={Link}
                                        sx={{ m: 1.5 }}
                                    >
                                        CONTACT US
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export { ContactFooter };
