import { QuestionCriteria, QuestionDefinition } from 'types/createCase';
import { DynamicFormField } from 'types/formFields';

const createFieldState = (
    criteria: QuestionCriteria,
    definition: QuestionDefinition
) => {
    const placeholder = `${definition.placeholder || definition.name} ${
        criteria.isRequired ? '*' : ''
    }`;

    return {
        defaultValue: definition.defaultValue,
        displayOrder: criteria.displayOrder,
        elementType: definition.questionType,
        parentQuestionId: criteria.parentQuestionId,
        validationRules: {
            limit: definition.limit,
            isRequired: criteria.isRequired,
        },
        props: {
            labelDescription: definition.description,
            inputLabel: definition.name,
            placeholder,
            name: criteria.questionId,
            ...(definition.questionType === 'dropDown' && {
                labelKey: 'name',
                valueKey: 'id',
            }),
        },
    } as DynamicFormField;
};

export { createFieldState };
