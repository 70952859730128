const getAvatarName = (str: string): string | undefined => {
    const name = str || '';
    const [first, last = ''] = name.split(' ');
    const avatarName = first
        ? `${first[0]}${last && last[0]}`.toUpperCase()
        : undefined;

    return avatarName;
};

export { getAvatarName };
