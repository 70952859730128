import FormControl from '@northstar/core/FormControl';
import InputLabel from '@northstar/core/InputLabel';
import Select, { SelectChangeEvent } from '@northstar/core/Select';

import MenuItem from '@northstar/core/MenuItem';
import { countriesList } from 'constants/countriesList';

import { dropDownMenuProps } from 'constants/dropDownMenuProps';
import FormHelperText from '@northstar/core/FormHelperText';

type ICountriesDropDown = {
    onChange: (event: string, name: string) => void;
    selectedValue?: string;
    error?: boolean;
    helperText?: string | boolean;
};

const CountriesDropDown = ({
    onChange,
    selectedValue,
    error,
    helperText,
}: ICountriesDropDown) => {
    return (
        <FormControl fullWidth error={error}>
            <InputLabel>Country/Region *</InputLabel>
            <Select
                value={selectedValue}
                displayEmpty
                fullWidth
                label="Country/Region *"
                onChange={(e: SelectChangeEvent<string>) =>
                    onChange(e.target.value, 'country')
                }
                MenuProps={dropDownMenuProps}
            >
                {Object.keys(countriesList).map((item) => (
                    <MenuItem
                        value={countriesList[item]}
                        key={countriesList[item]}
                    >
                        {item}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default CountriesDropDown;
