import { PermissionsProducts } from 'types/cases';
import { ProductPermission } from 'types/permissions';
import { objectOrderBy } from 'utils/objectOrderBy';
import { removeArrayDuplicateObjectsByKey } from 'utils/removeArrayDuplicateObjectsByKey';

const processPermissionsProducts = (
    productsList: PermissionsProducts,
    accounts: string[]
) => {
    const dataKeys = Object.keys(productsList);

    if (!dataKeys.length) return undefined;

    const accountProducts: ProductPermission[] = [];

    dataKeys.forEach((currentKey) => {
        if (accounts.includes(currentKey)) {
            accountProducts.push(...productsList[currentKey]);
        }
    });

    const filterByName = removeArrayDuplicateObjectsByKey(
        accountProducts,
        'productName'
    );

    return objectOrderBy(filterByName, 'productName');
};

export { processPermissionsProducts };
