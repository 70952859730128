import { BoxProps } from '@northstar/core/Box';
import Button from '@northstar/core/Button';
import SearchOff from 'components/Icons/SearchOff';
import { Link } from 'components/Link';
import NoData from 'components/DataState';
import { useAuth } from 'contexts/Auth';

const NoProducts = (props: BoxProps) => {
    const { user } = useAuth();
    const description = user
        ? 'Once products are added to your account, they will appear here. You can request access to your products below.'
        : '';
    const recuestActions = user
        ? [
              <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  component={Link}
                  to="/website-access-request"
              >
                  Request Access
              </Button>,
          ]
        : [];

    return (
        <NoData
            title="No products found"
            description={description}
            icon={SearchOff}
            actions={recuestActions}
            TitleProps={{ maxWidth: 430 }}
            DescriptionProps={{ maxWidth: 430 }}
            {...props}
        />
    );
};

export { NoProducts };
