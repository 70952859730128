import sanitizeHtml from 'sanitize-html';
import { configSanitizeHTML } from 'components/SanitizeHTML/configSanitizeHTML';
import { removeDeniedTags } from './removeDeniedTags';

const handleBeforePastInsert = (html: string) => {
    const sanitizedHtmlContent = sanitizeHtml(html, configSanitizeHTML);

    const cleanedHtmlContent = removeDeniedTags(sanitizedHtmlContent);

    return cleanedHtmlContent || '';
};

export { handleBeforePastInsert };
