import { Link } from 'components/Link';
import { useAuth } from 'contexts/Auth';
import Box from '@northstar/core/Box';

const SidebarNavLinks = () => {
    const { user } = useAuth();
    return (
        <Box
            sx={{
                a: {
                    display: 'block',
                },
            }}
        >
            {user?.is_portal_user && (
                <>
                    <Link
                        underline="none"
                        marginBottom={1}
                        sx={{ color: 'text.primary' }}
                        to="/support/requests"
                    >
                        Manage Requests
                    </Link>
                    <Link
                        underline="none"
                        sx={{ color: 'text.primary' }}
                        to="/support/requests/open"
                    >
                        Open Support Request
                    </Link>
                </>
            )}
            {!user && (
                <Link
                    underline="none"
                    sx={{ color: 'text.primary' }}
                    to="/contact-us"
                >
                    Contact Us
                </Link>
            )}
        </Box>
    );
};

export default SidebarNavLinks;
