import {
    ContactUsMainDescription,
    ContactUsDetails,
} from 'pages/ContactUs/components';
import { Main } from 'components/layouts';
import Box from '@northstar/core/Box';

const breadcrumbs = [
    {
        href: '/',
        title: 'Home',
        isActive: false,
    },
    {
        title: 'Contact us',
        isActive: true,
    },
];

const ContactUs = () => (
    <Main breadcrumbs={breadcrumbs}>
        <Box marginTop={6}>
            <ContactUsMainDescription />
            <ContactUsDetails />
        </Box>
    </Main>
);

export default ContactUs;
