import Typography from '@northstar/core/Typography';
import AppLogo from 'components/Icons/AppLogo';
import { Link } from 'components/Link';

const Logo = () => {
    return (
        <Link
            display="inline-flex"
            alignItems="center"
            to="/"
            underline="none"
            title="Fortra"
        >
            <AppLogo
                sx={{
                    paddingRight: 1.25,
                    borderRight: '1px',
                    borderRightStyle: 'solid',
                    borderRightColor: 'common.white',
                }}
            />
            <Typography
                variant="h6"
                sx={{
                    color: 'common.white',
                    paddingLeft: 2,
                }}
            >
                Support
            </Typography>
        </Link>
    );
};

export default Logo;
