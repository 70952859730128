import { ArticlesData } from 'types/kbArticles';

const groupKBArticlesByKey = (array: Object[], key: string): ArticlesData => {
    const data: ArticlesData = {};
    array.forEach(({ [key]: value, description, ...rest }: any) => {
        if (!data[value]) {
            data[value] = {
                category: value,
                description: description || null,
                articles: [],
            };
        }
        if (data[value]) {
            data[value].articles.push({ ...rest });
        }
    });
    return data;
};

export { groupKBArticlesByKey };
