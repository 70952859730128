import { Table } from '@tanstack/table-core';
import { caseStatusList } from 'pages/Support/components/CasesList/caseStatusList';
import { AccountDetails } from 'types/accounts';
import { CaseData } from 'types/cases';
import { ProductPermission } from 'types/permissions';

type Props = {
    productsList: ProductPermission[] | undefined;
    userAccountsDetails: AccountDetails[];
    globalFilter: string;
    tableInstance: Table<CaseData>;
};

const getFilteredByNames = ({
    tableInstance,
    productsList,
    userAccountsDetails,
    globalFilter,
}: Props) => {
    const { columnFilters } = tableInstance.getState();

    const filteredColNames = {} as any;

    const filterNames: string[] = [];

    columnFilters.forEach((filter) => {
        const columnName = tableInstance.getColumn(filter.id)?.columnDef
            .header as string;

        if (columnName) {
            filteredColNames[columnName] = filter.value;
        }
    });

    const { Organization, Product, Status, ...dynamicFilters } =
        filteredColNames;

    const isFilterApplied = (filter: string[] | null, allItemsLength: number) =>
        filter?.length !== 0 && filter?.length !== allItemsLength;

    const isOrgFilterApplied = isFilterApplied(
        Organization,
        userAccountsDetails.length
    );

    const isProductFilterApplied = isFilterApplied(
        Product,
        productsList?.length || 0
    );

    const isStatusFilterApplied = isFilterApplied(
        Status,
        caseStatusList.length
    );

    if (isOrgFilterApplied && Organization) {
        const appliedAccounts = userAccountsDetails
            .filter(({ accountId }) => Organization.includes(accountId))
            .map(({ accountName }) => accountName);

        filterNames.push(`"Organization: '${appliedAccounts.join(', ')}'"`);
    }

    if (isProductFilterApplied && Product) {
        filterNames.push(`"Product: '${Product.join(', ')}'"`);
    }

    if (isStatusFilterApplied && Status) {
        const appliedStatuses = Status.filter(
            (item: string) => item !== 'Draft'
        );

        filterNames.push(`"Status: '${appliedStatuses.join(', ')}'"`);
    }

    Object.keys(dynamicFilters).forEach((item) => {
        if (dynamicFilters[item].length > 0) {
            filterNames.push(`"${item}: '${dynamicFilters[item]}'"`);
        }
    });

    if (globalFilter) {
        filterNames.push(`Keywords: '${globalFilter}'`);
    }

    if (filterNames.length > 0) {
        filterNames.unshift('"Filtered By: "');

        return filterNames.join(',');
    }

    return '';
};

export { getFilteredByNames };
