import { Box, CircularProgress } from '@northstar/core';
import { Success as SuccessIcon } from '@northstar/icons';
import { SaveStatusType } from 'hooks/useDraftSave';

export const DraftStatus = ({ status }: { status: SaveStatusType }) => {
    const start = status === 'saving' && 'Saving draft';
    const end = status === 'saved' && 'Draft saved';

    return (
        <Box
            display="inline-flex"
            alignItems="center"
            aria-label="draftSaveStatus"
        >
            {start && (
                <>
                    <CircularProgress
                        size={20}
                        sx={{
                            color: 'scale.seagreen.500',
                            mr: 1,
                        }}
                    />
                    {start}
                </>
            )}
            {end && (
                <>
                    <SuccessIcon sx={{ mr: 1 }} />
                    {end}
                </>
            )}
        </Box>
    );
};
