export const pageErrorData: {
    [key: number]: {
        status: number;
        message: string;
        description: string;
        image: string;
    };
} = {
    400: {
        status: 400,
        message: 'Bad Request',
        description:
            'This could be due to a broken link, expired page, or something <br> on our side. Try refreshing the page, or click below to go home.',
        image: '/assets/images/page-not-found.png',
    },
    403: {
        status: 403,
        message: 'Forbidden',
        description:
            'You do not have permission to access the resources that you have requested. If you think that you have reached this page in error, please reach out to our support team to resolve this issue.',
        image: '/assets/images/page-not-found.png',
    },
    404: {
        status: 404,
        message: 'Page not found',
        description:
            'This could be due to a broken link, expired page, or something <br> on our side. Try refreshing the page, or click below to go home.',
        image: '/assets/images/page-not-found.png',
    },
    429: {
        status: 429,
        message: 'Server is busy',
        description:
            'The server is currently busy and may be temporarily unavailable. Please try again later, or click below to go home.',
        image: '/assets/images/page-not-found.png',
    },
    500: {
        status: 500,
        message: 'Internal server error',
        description: 'Proper security permissions are needed to view this page',
        image: '/assets/images/page-not-found.png',
    },
};
