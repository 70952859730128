import { Tooltip, Typography, Zoom } from '@northstar/core';
import { useAccounts } from 'contexts/Accounts';

type Props = {
    shouldShowTooltip: boolean;
};

const SelectedAccounts = ({ shouldShowTooltip }: Props) => {
    const { userAccountsDetails, selectedAccounts } = useAccounts();

    const getSelectedNames = () => {
        const res: string[] = [];

        if (selectedAccounts.length === userAccountsDetails?.length) {
            return 'All';
        }

        selectedAccounts.forEach((item) => {
            const foundedItem = userAccountsDetails?.find(
                (innerItem) => innerItem.accountId === item
            );

            if (foundedItem) {
                res.push(foundedItem.accountName);
            }
        });

        return res.join(', ');
    };

    return (
        <Tooltip
            arrow
            title={`Organization: ${getSelectedNames()}`}
            TransitionComponent={Zoom}
            placement="right"
            open={shouldShowTooltip}
            disableFocusListener
            sx={{
                marginRight: '-30px',
                paddingRight: '30px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            <Typography>{getSelectedNames()}</Typography>
        </Tooltip>
    );
};

export { SelectedAccounts };
