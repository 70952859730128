export interface S3FileType {
    fileName: string;
    fileSize: string;
    id: string;
    mimeType: string;
}

export const s3FilesToFileList = (files: File[]) =>
    files.map((value) => {
        const file = value as unknown as S3FileType;
        return {
            id: file.id,
            name: file.fileName,
            size: Number(file.fileSize),
            type: file.mimeType,
            progress: 100,
            status: 'success',
        };
    });
