import AuthService from 'services/auth/AuthService';

const calculateSessionTimeouts = () => {
    const IDLE_TIMEOUT_MS =
        (Number(AuthService.refreshTokenParsed()?.exp) -
            Number(AuthService.refreshTokenParsed()?.iat) || 900) * 1000;

    const PROMPT_TIMEOUT_MS =
        IDLE_TIMEOUT_MS <= 60000 ? IDLE_TIMEOUT_MS / 2 : 60000;

    const DEFAULT_REMAINING_SEC = PROMPT_TIMEOUT_MS / 1000;

    return { IDLE_TIMEOUT_MS, PROMPT_TIMEOUT_MS, DEFAULT_REMAINING_SEC };
};

export { calculateSessionTimeouts };
