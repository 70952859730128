import Box from '@northstar/core/Box';
import Card from '@northstar/core/Card';
import List from '@northstar/core/List';
import ListItem from '@northstar/core/ListItem';
import Grid from '@northstar/core/Grid';
import Typography from '@northstar/core/Typography';
import Container from '@northstar/core/Container';
import { Link } from 'components/Link';
import { useLocation, Outlet } from 'react-router-dom';
import { Main } from 'components/layouts';
import { useAuth } from 'contexts/Auth';
import { withAuth } from 'components/Auth';
import { useApp } from 'contexts/App';

const UserAccount = () => {
    const { pathname: locationPathName } = useLocation();
    const { user, manageAccount } = useAuth();
    const { appConfig } = useApp();
    const setLinkColor = (path: string) =>
        locationPathName === path ? 'textPrimary' : 'textSecondary';
    const setLinkBorderColor = (path: string) =>
        locationPathName === path ? 'primary.main' : 'transparent';

    return (
        <Main>
            <Box>
                <Box
                    bgcolor="marketing.duskForest"
                    paddingTop={4}
                    paddingBottom={10.5}
                    height={224}
                    display="flex"
                    alignItems="flex-end"
                >
                    <Container>
                        <Typography
                            variant="h4"
                            component="h1"
                            gutterBottom
                            sx={{ color: 'common.white' }}
                        >
                            Account settings
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'common.white' }}>
                            Change account information and settings
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{ marginTop: -8, marginBottom: 7.5 }}>
                    <Grid spacing={4}>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ boxShadow: 3 }}>
                                <List
                                    disablePadding
                                    sx={{
                                        display: {
                                            xs: 'inline-flex',
                                            md: 'flex',
                                        },
                                        flexDirection: {
                                            xs: 'row',
                                            md: 'column',
                                        },
                                        overflow: 'auto',
                                        flexWrap: 'nowrap',
                                        width: '100%',
                                        paddingY: { xs: 3, md: 4 },
                                        paddingX: { xs: 4, md: 0 },
                                    }}
                                >
                                    {!appConfig?.IS_READ_ONLY_MAINTENANCE && (
                                        <ListItem
                                            component={Link}
                                            to="/account"
                                            disableGutters
                                            sx={{
                                                marginRight: { xs: 2, md: 0 },
                                                flex: 0,
                                                paddingX: { xs: 0, md: 3 },
                                                borderLeft: {
                                                    xs: 'none',
                                                    md: '2px solid transparent',
                                                },
                                                borderLeftColor: {
                                                    md: setLinkBorderColor(
                                                        '/account'
                                                    ),
                                                },
                                            }}
                                        >
                                            <Typography
                                                component="span"
                                                variant="subtitle1"
                                                noWrap
                                                color={setLinkColor('/account')}
                                            >
                                                General
                                            </Typography>
                                        </ListItem>
                                    )}

                                    {user?.is_portal_user && (
                                        <ListItem
                                            component={Link}
                                            to="/account/products"
                                            disableGutters
                                            sx={{
                                                marginRight: { xs: 2, md: 0 },
                                                flex: 0,
                                                paddingX: { xs: 0, md: 3 },
                                                borderLeft: {
                                                    xs: 'none',
                                                    md: '2px solid transparent',
                                                },
                                                borderLeftColor: {
                                                    md: setLinkBorderColor(
                                                        '/account/products'
                                                    ),
                                                },
                                            }}
                                        >
                                            <Typography
                                                component="span"
                                                variant="subtitle1"
                                                noWrap
                                                color={setLinkColor(
                                                    '/account/products'
                                                )}
                                            >
                                                My Products
                                            </Typography>
                                        </ListItem>
                                    )}

                                    <ListItem
                                        component="a"
                                        onClick={manageAccount}
                                        disableGutters
                                        sx={{
                                            justifyContent: 'space-between',
                                            marginRight: { xs: 2, md: 0 },
                                            flex: 0,
                                            paddingX: { xs: 0, md: 3 },
                                            borderLeft: {
                                                xs: 'none',
                                                md: '2px solid transparent',
                                            },
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            variant="subtitle1"
                                            noWrap
                                            color="textSecondary"
                                        >
                                            Manage Account
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Card sx={{ boxShadow: 3, padding: 4 }}>
                                <Outlet />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Main>
    );
};

export default withAuth(UserAccount);
