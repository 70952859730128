import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Drawer, { DrawerProps } from '@northstar/core/Drawer';
import { SidebarNav } from './components';

interface IProps extends DrawerProps {
    onClose: () => void;
}

const Sidebar = ({ open, variant, onClose, ...rest }: IProps) => {
    const { pathname } = useLocation();

    useEffect(() => {
        onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <Drawer
            anchor="left"
            onClose={() => onClose()}
            open={open}
            variant={variant}
            sx={{
                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: 280,
                },
            }}
            PaperProps={{
                sx: {
                    paddingY: 1,
                },
            }}
            {...rest}
        >
            <SidebarNav onClose={onClose} />
        </Drawer>
    );
};

export default Sidebar;
