import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { multiSelectFilterFn } from '../filterFn/multiSelectFilterFn';

const ProductColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('product', {
        header: 'Product',
        filterFn: multiSelectFilterFn,
        enableColumnFilter: false,
    });

export { ProductColumn };
