import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import AuthService from 'services/auth/AuthService';
import { currentExecutingRequests } from 'utils/axios/currentExecutingRequests';

const instance = axios.create();
instance.defaults.baseURL = process.env.REACT_APP_API_URL;

instance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig): Promise<any> => {
        const originalRequest = config;

        if (AuthService.getIdToken()) {
            try {
                await AuthService.updateToken(5);
            } catch {
                AuthService.signIn();
            }
        }

        const { url = '' } = originalRequest;

        if (originalRequest.headers.skipCancelation !== 'true') {
            if (currentExecutingRequests[url]) {
                const source = currentExecutingRequests[url];
                delete currentExecutingRequests[url];
                source.abort();
            }
            const abortController = new AbortController();
            originalRequest.signal = abortController.signal;
            currentExecutingRequests[url] = abortController;
        } else {
            originalRequest.headers.skipCancelation = undefined;
        }

        const portalAccountIds =
            originalRequest.headers.portalAccountIds ||
            sessionStorage.getItem('selectedAccounts') ||
            AuthService.getPortalAccountId();

        return {
            ...originalRequest,
            headers: {
                'Content-Type': 'application/json',
                portalAccountIds,
                Authorization: AuthService.getIdToken()
                    ? `Bearer ${AuthService.getIdToken()}`
                    : undefined,
                ...originalRequest.headers,
            },
        };
    },
    (err) => {
        if (err.response) {
            return Promise.reject(err.response.data);
        }

        if (err.request) {
            return Promise.reject(err.request);
        }

        return Promise.reject(err.message);
    }
);

const handleUnauthenticated = () => {
    AuthService.removeIdToken();
    AuthService.removeRefreshToken();
    AuthService.signIn();
};

const retryRequest = (originalRequest: AxiosRequestConfig) => {
    return instance.request(originalRequest);
};

instance.interceptors.response.use(
    (response) => {
        const { url = '' } = response.config;
        delete currentExecutingRequests[url];

        return response;
    },
    async (error) => {
        if (error.code === 'ERR_CANCELED') {
            return Promise.reject(error);
        }
        const { url = '' } = error.config;

        delete currentExecutingRequests[url];

        const { config } = error;

        config.attempt = config.attempt || 0;
        config.retry = config.retry || 1;

        const originalRequest = config;

        if (error?.response?.status === 401 && !AuthService.getRefreshToken()) {
            handleUnauthenticated();
        }

        const updateAuthToken = async () => {
            try {
                const updateToken = await AuthService.updateToken(-1);

                if (updateToken) {
                    originalRequest.headers = {
                        Authorization: `Bearer ${AuthService.getIdToken()}`,
                    };

                    config.attempt += 1;
                    return await retryRequest(originalRequest);
                }
            } catch (err) {
                console.error(err);
            }
            return null;
        };

        if (
            error?.response?.status === 401 &&
            AuthService.getRefreshToken() &&
            config.attempt < config.retry
        ) {
            try {
                await updateAuthToken();
            } catch {
                handleUnauthenticated();
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
