import { ReactNode, useState } from 'react';

import Typography, { TypographyProps } from '@northstar/core/Typography';

import { useApp } from 'contexts/App';
import { downloadProduct } from 'utils/downloadProduct';
import { generateHexString } from 'utils/generateHexString';

type Props = {
    children: ReactNode;
    productURL: string;
    productId: string;
    productName: string;
    versionId: string;
} & TypographyProps;

const ProductDownloadLink = (props: Props) => {
    const { children, productURL, productId, productName, versionId } = props;
    const [inProgress, setInProgress] = useState(false);
    const { addNotification, closeSnackbar } = useApp();

    const prepareDownload = async () => {
        setInProgress(true);

        const key = generateHexString();

        addNotification(
            {
                message: 'Downloading...',
                status: 0,
            },
            {
                persist: true,
                key,
            }
        );

        try {
            await downloadProduct({
                productURL,
                productId,
                productName,
                versionId,
            });
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response?.status || 400,
            });
        } finally {
            setInProgress(false);
            closeSnackbar(key);
        }
    };

    return (
        <Typography
            sx={{
                pl: '0.7rem',
                display: 'flex',
                alignItems: 'center',
                border: 'none',
                textAlign: 'initial',
                background: 'none',
                cursor: inProgress ? 'default' : 'pointer',
                '&:hover': {
                    textDecoration: inProgress ? 'none' : 'underline',
                },
                ...props.sx,
            }}
            color="primary"
            aria-label="Download Product"
            component="button"
            onClick={(e) =>
                inProgress ? e.preventDefault() : prepareDownload()
            }
        >
            {children}
        </Typography>
    );
};

export { ProductDownloadLink };
