import { TooltipCell } from '@northstar/plus';
import { Cell, ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { casesDefaultFilterFn } from '../filterFn/casesDefaultFilterFn';

const CaseTitleColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('caseTitle', {
        header: 'Request Title',
        enableHiding: false,
        filterFn: casesDefaultFilterFn,
        cell: ({ cell }: { cell: Cell<CaseData, unknown> }) => {
            const value = cell.getValue() as string;

            return <TooltipCell text={value} />;
        },
    });

export { CaseTitleColumn };
