import { forwardRef } from 'react';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';
import NorthLink, { LinkProps as NorthLinkProps } from '@northstar/core/Link';

export type LinkProps = NorthLinkProps & RouterLinkProps;

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <NorthLink ref={ref} {...props} component={RouterLink} />
));

export default Link;
