import { UserType, productPermissionsDefaultValues } from 'contexts/Auth';
import { useState } from 'react';
import AuthService from 'services/auth/AuthService';
import axios from 'utils/axios';
import { Permissions } from 'types/products';
import { useEffectOnce } from 'hooks/useEffectOnce';

export function useProvideAuth() {
    const [user, setUser] = useState<UserType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {
        signOut,
        signIn,
        signUp,
        manageAccount,
        updateToken,
        identifyUserForFullStory,
        isTokenExpired,
        tokenParsed,
        refreshTokenParsed,
        isRefreshTokenExpired,
    } = AuthService;
    const auth = (() => {
        const getProductPermissions = (
            permissions: Permissions | undefined,
            isSecured: boolean | undefined = false
        ) => {
            if (!permissions) {
                return productPermissionsDefaultValues;
            }

            const hasManuals = permissions.isManuals;
            const hasDownloads = permissions.isDownloads;

            const hasKBArticles = permissions?.isKBArticles;

            const canKBArticles =
                (user && isSecured && hasKBArticles) || !isSecured;
            const canForums = false;

            const canManuals = (user && hasManuals) || false;
            const canDownloads = (user && hasDownloads) || false;

            return {
                canKBArticles,
                canForums,
                canManuals,
                canDownloads,
            };
        };

        return {
            user,
            isLoading,
            signOut,
            signIn,
            signUp,
            manageAccount,
            updateToken,
            getProductPermissions,
            setUser,
            isTokenExpired,
            tokenParsed,
            refreshTokenParsed,
            isRefreshTokenExpired,
        };
    })();

    const initAuth = async () => {
        try {
            if (AuthService.isLoggedIn()) {
                const userInfo = await AuthService.getUser();

                if (userInfo) {
                    axios.defaults.headers.primaryAccountId =
                        userInfo.data?.accountId ||
                        AuthService.getPortalAccountId();
                    axios.defaults.headers.portalContactId =
                        userInfo.data?.user_id;
                    setUser(userInfo.data);
                    identifyUserForFullStory();
                }
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    useEffectOnce(() => {
        initAuth();
    });

    return auth;
}
