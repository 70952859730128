import { useState } from 'react';
import { useTheme } from '@northstar/core/styles';
import { AppBar, useMediaQuery, useScrollTrigger } from '@northstar/core';
import { useScrollToTop } from 'hooks';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';

interface Props {
    bgcolor?: string;
}

const Header = ({ bgcolor }: Props) => {
    useScrollToTop();
    const theme = useTheme();

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [openSidebar, setOpenSidebar] = useState<boolean>(false);

    const handleSidebarOpen = (): void => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = (): void => {
        setOpenSidebar(false);
    };

    const open = isMd ? false : openSidebar;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 38,
    });

    return (
        <>
            <Sidebar onClose={handleSidebarClose} open={open} />
            <AppBar
                position="sticky"
                sx={{
                    top: 0,
                    bgcolor,
                    py: 1.5,
                }}
                elevation={trigger ? 3 : 0}
            >
                <Topbar
                    onSidebarOpen={handleSidebarOpen}
                    expanded={openSidebar}
                />
            </AppBar>
        </>
    );
};

export default Header;
