import { Box, Typography } from '@northstar/core';
import { SeverityLevel } from 'types/cases';
import { BoxProps } from '@northstar/core/types/Box';
import { SeverityIcon } from './SeverityIcon';

interface Props extends BoxProps {
    level: SeverityLevel;
    subTitle?: string;
    description?: string;
}
export const SeverityLabel = ({
    level,
    subTitle,
    description,
    ...rest
}: Props) => (
    <Box
        border={1}
        borderRadius={1}
        borderColor="divider"
        padding="8px 16px"
        display="flex"
        alignItems="center"
        {...rest}
    >
        <SeverityIcon name={level} />
        <Box padding="8px 16px">
            <Typography variant="h5" component="p">
                {level}{' '}
                {subTitle && (
                    <Typography component="span" variant="caption">
                        ({subTitle})
                    </Typography>
                )}
            </Typography>
            {description && (
                <Typography sx={{ textWrap: 'wrap' }}>{description}</Typography>
            )}
        </Box>
    </Box>
);
