import { useCallback } from 'react';
import { useBeforeUnload as baseUseBeforeUnload } from 'react-router-dom';

export const useBeforeUnload = <T>(isUnsafeClose: T) => {
    baseUseBeforeUnload(
        useCallback(
            (event) => {
                if (isUnsafeClose) {
                    event.preventDefault();
                    // eslint-disable-next-line no-param-reassign
                    event.returnValue = '';
                    return '';
                }
                return undefined;
            },
            [isUnsafeClose]
        )
    );
};
