/* eslint-disable react/no-unescaped-entities */
import { useRef, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import useSWR from 'swr';

import {
    Box,
    Typography,
    Divider,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@northstar/core';
import { Main } from 'components/layouts';

import axios from 'utils/axios';
import { useApp } from 'contexts/App';

import { object, string, array } from 'libs/validation';

import { Button } from 'components/Button';
import { Brand, Product } from 'types/products';
import { getProductsFromBrands } from 'utils/getProductsFromBrands';
import { AlertFormSubmited } from 'components/Alert';
import { SelectChangeEvent } from '@northstar/core/types/Select';
import { useScrollToInvalidField } from 'hooks/useScrollToInvalidField';
import { getFieldErrorInfo } from 'utils/getFieldErrorInfo';
import { withAuth } from 'components/Auth';
import { ShowMoreFields } from './ShowMoreFields';
import { PageHeader, PageBody } from '../Support/components/PageContent';

export interface RequestWebAccess {
    company_name: string;
    business_phone: string;
    customer_type: string;
    product_brands: string[];
    product_owned: string[];
}

const customerTypes: { id: number | string; name: string }[] = [
    {
        id: 717710000,
        name: 'Current customer',
    },
    {
        id: 717710001,
        name: 'Trial customer',
    },
    {
        id: 717710002,
        name: 'Not a customer',
    },
];

const validationSchema = object({
    company_name: string().trim().max(100).required(),
    business_phone: string().phone().required(),
    customer_type: string().max(100).required(),
    product_brands: array()
        .of(string())
        .when('customer_type', {
            is: (value: number) =>
                +value === customerTypes[0].id ||
                +value === customerTypes[1].id,
            then: (schema) => {
                return schema.min(1);
            },
        }),
    product_owned: array()
        .of(string())
        .when('customer_type', {
            is: (value: number) =>
                +value === customerTypes[0].id ||
                +value === customerTypes[1].id,
            then: (schema) => {
                return schema.min(1);
            },
        }),
});

const WebsiteAccessRequest = () => {
    const { addNotification } = useApp();
    const [selectedBrandsProducts, setSelectedBrandsProducts] = useState<
        Product[]
    >([]);
    const formItemRef = useRef<HTMLFormElement>(null);
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [formSuccessSubmitted, setFormSuccessSubmitted] =
        useState<boolean>(false);

    const { data: productBrandList } = useSWR<Brand[]>('/productbrands');

    const initialValues: RequestWebAccess = {
        company_name: '',
        business_phone: '',
        customer_type: '',
        product_brands: [],
        product_owned: [],
    };

    const onSubmit = async (
        values: RequestWebAccess,
        actions: FormikHelpers<RequestWebAccess>
    ) => {
        const payload = validationSchema.cast(values) as RequestWebAccess;

        const selectedType = customerTypes.find(
            (type) => type.id === Number(payload.customer_type)
        );
        const brandsList = productBrandList
            ?.filter((brand) => payload.product_brands.includes(brand.id))
            .map((item) => ({ id: item.id, name: item.name }));

        const productList =
            productBrandList &&
            selectedBrandsProducts
                .filter((product) => payload.product_owned.includes(product.id))
                .map((item) => ({ id: item.id, name: item.name }));

        try {
            const data = await axios.post('/users/access_request', {
                ...payload,
                customer_type: selectedType,
                product_brands: brandsList,
                product_owned: productList,
            });

            if (data.status === 201) {
                setFormSuccessSubmitted(true);
            }
        } catch (e: any) {
            actions.setSubmitting(false);

            addNotification({
                message: e.message,
                status: e.response.status,
            });
        }
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit,
    });

    const { isSubmitting, errors } = formik;

    useScrollToInvalidField({
        isSubmitting,
        errors,
        container: formItemRef.current,
    });

    const selectedCustomerType = formik.values.customer_type;

    const showMoreFields =
        selectedCustomerType === customerTypes[0].id ||
        selectedCustomerType === customerTypes[1].id;

    const handleCustomerTypeChange = (e: SelectChangeEvent<string>) => {
        formik.setFieldValue('customer_type', e.target.value);
        if (
            formik.values.product_brands.length &&
            e.target.value === customerTypes[2].id
        ) {
            formik.setFieldValue('product_brands', []);
            formik.setFieldValue('product_owned', []);
        }
    };

    const handleBrandChange = (values: Brand[]) => {
        setSelectedBrandsProducts(getProductsFromBrands(values));
        const brnadIds = values.map((option) => option.id);
        const filterSelectedProducts = selectedProducts.filter((product) =>
            brnadIds.includes(product.productBrandId)
        );
        setSelectedProducts(filterSelectedProducts);
        const productIds = filterSelectedProducts.map((product) => product.id);
        formik.setFieldValue('product_owned', productIds);
        formik.setFieldValue('product_brands', brnadIds);
    };

    const handleProductChange = (values: Product[]) => {
        setSelectedProducts(values);
        const productIds = values.map((option) => option.id);
        formik.setFieldValue('product_owned', productIds);
    };

    return (
        <Main>
            <PageHeader maxWidth="md">
                <Typography
                    variant="h3"
                    component="h1"
                    sx={{ color: 'common.white' }}
                >
                    Request access
                </Typography>
            </PageHeader>
            <PageBody maxWidth="md">
                {!formSuccessSubmitted ? (
                    <form onSubmit={formik.handleSubmit} ref={formItemRef}>
                        <Typography variant="body1" gutterBottom>
                            Please complete the form below to gain access to
                            your product information, resources, and downloads.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            After submitting, your request will be reviewed and
                            a Fortra team member will reach out to you regarding
                            your product access. Access to product requests are
                            only monitored during business hours.
                        </Typography>
                        <Box paddingY={4}>
                            <Divider />
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    sx={{ marginBottom: 1, fontWeight: '500' }}
                                >
                                    Company name
                                </Typography>
                                <TextField
                                    placeholder="Company *"
                                    variant="outlined"
                                    color="primary"
                                    name="company_name"
                                    aria-label="companyName"
                                    fullWidth
                                    value={formik.values.company_name}
                                    onChange={formik.handleChange}
                                    error={
                                        getFieldErrorInfo(
                                            formik,
                                            'company_name'
                                        ).isError
                                    }
                                    helperText={
                                        getFieldErrorInfo(
                                            formik,
                                            'company_name'
                                        ).helperText
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    sx={{ marginBottom: 1, fontWeight: '500' }}
                                >
                                    Business Phone
                                </Typography>
                                <TextField
                                    placeholder="Provided telephone number *"
                                    variant="outlined"
                                    color="primary"
                                    name="business_phone"
                                    aria-label="businessPhone"
                                    fullWidth
                                    value={formik.values.business_phone}
                                    onChange={formik.handleChange}
                                    error={
                                        getFieldErrorInfo(
                                            formik,
                                            'business_phone'
                                        ).isError
                                    }
                                    helperText={
                                        getFieldErrorInfo(
                                            formik,
                                            'business_phone'
                                        ).helperText
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    sx={{ marginBottom: 2, fontWeight: '500' }}
                                >
                                    What brings you to Fortra?
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="customerType-filter">
                                        Customer type *
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="customerType-filter-select"
                                        id="customerType-filter-select"
                                        label="Customer type *"
                                        aria-label="customerType"
                                        name="customer_type"
                                        value={formik.values.customer_type}
                                        onChange={(e) =>
                                            handleCustomerTypeChange(e)
                                        }
                                        error={
                                            getFieldErrorInfo(
                                                formik,
                                                'customer_type'
                                            ).isError
                                        }
                                    >
                                        {customerTypes.map((item) => (
                                            <MenuItem
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.customer_type &&
                                        formik.errors.customer_type && (
                                            <FormHelperText
                                                error
                                                sx={{
                                                    marginLeft: '14px',
                                                    marginRight: '14px',
                                                }}
                                            >
                                                {formik.errors.customer_type}
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>

                            <ShowMoreFields
                                formik={formik}
                                handleBrandChange={handleBrandChange}
                                handleProductChange={handleProductChange}
                                productBrandList={productBrandList}
                                selectedBrandsProducts={selectedBrandsProducts}
                                showMoreFields={showMoreFields}
                            />
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                xs={12}
                            >
                                <Button
                                    variant="contained"
                                    disabled={formik.isSubmitting}
                                    isLoading={formik.isSubmitting}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                        width: '100%',
                                        textAlign: 'center',
                                        mt: 1.5,
                                    }}
                                >
                                    We'll get back to you in 1-2 business days.
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                ) : (
                    <AlertFormSubmited
                        title="Thanks!"
                        description="Your request has been received and a team member will
                get in touch with you soon!"
                    />
                )}
            </PageBody>
        </Main>
    );
};

export default withAuth(WebsiteAccessRequest);
