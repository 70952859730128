import { Box, Skeleton, Typography } from '@northstar/core';
import { ActivityItemLoading } from './ActivityItemLoading';

export const CaseContentLoading = () => (
    <Box>
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 0.5,
            }}
        >
            <Typography variant="h4">
                <Skeleton width={300} aria-label="skeleton" />
            </Typography>
            <Skeleton
                width={100}
                sx={{
                    ml: 1,
                }}
            />
        </Box>
        <Skeleton width={200} sx={{ mb: 4 }} />
        <Skeleton width={90} />
        <Typography variant="body1">
            <Skeleton width="90%" />
        </Typography>
        <Typography variant="body1">
            <Skeleton width="100%" />
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
            <Skeleton width="95%" />
        </Typography>
        <Typography variant="body1">
            <Skeleton width="50%" />
        </Typography>
        <Skeleton width={70} sx={{ mb: 4 }} />
        <ActivityItemLoading />
    </Box>
);

export const CaseDetailsLoading = () => (
    <>
        {Array.from(Array(5).keys()).map((index) => (
            <Box marginBottom={3} key={index}>
                <Typography variant="body1">
                    <Skeleton width="90%" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton width="100%" />
                </Typography>
            </Box>
        ))}
    </>
);
