import { useContext, createContext, ReactNode } from 'react';
import {
    useSnackbar,
    VariantType,
    OptionsObject,
    SnackbarKey,
    SnackbarMessage,
} from 'notistack';
import { useLoaderData } from 'react-router-dom';

export interface Notification {
    message: SnackbarMessage;
    status: number | VariantType;
}

export type AppContextType = {
    addNotification: (data: Notification, options?: OptionsObject) => void;
    closeSnackbar: (key?: SnackbarKey) => void;
    appConfig: { [key: string]: any };
    removeAnnouncementBanner: () => void;
};

const appContextDefaultValues: AppContextType = {
    addNotification: () => undefined,
    closeSnackbar: () => undefined,
    appConfig: {},
    removeAnnouncementBanner: () => undefined,
};

const context = createContext<AppContextType>(appContextDefaultValues);

const getErrorType = (status: number) => {
    let variant: VariantType = 'default';
    switch (true) {
        case status > 100 && status < 199:
            variant = 'info';
            break;
        case status >= 200 && status < 300:
            variant = 'success';
            break;
        case status >= 300 && status < 400:
            variant = 'warning';
            break;
        case status >= 400 && status < 500:
            variant = 'error';
            break;
        case status >= 500 && status < 600:
            variant = 'error';
            break;
        default:
            break;
    }
    return variant;
};

export function useProvideApp() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const appConfigLoader: any = useLoaderData();

    const addNotification = (
        data: Notification,
        options?: OptionsObject
    ): SnackbarKey =>
        enqueueSnackbar(data?.message, {
            ...options,
            variant: Number.isInteger(data.status)
                ? getErrorType(Number(data.status))
                : (data.status as VariantType),
        });

    const removeAnnouncementBanner = () => {
        appConfigLoader.banner.start = 0;
        appConfigLoader.banner.end = 0;
        sessionStorage.setItem(
            'appConfig',
            JSON.stringify(appConfigLoader?.appConfig)
        );
    };

    return {
        addNotification,
        closeSnackbar,
        appConfig: appConfigLoader,
        removeAnnouncementBanner,
    };
}

export function AppProvider(props: { children?: ReactNode }) {
    const ctx = useProvideApp();
    return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

export const useApp = () => {
    const contextValue = useContext(context);
    if (!context) {
        throw new Error('useApp must be called from within an AppProvider');
    }
    return contextValue;
};
