import { FormikTouched, FormikErrors, FormikValues } from 'formik';

type FormikConfig<T = {}> = {
    touched: FormikTouched<T>;
    errors: FormikErrors<T>;
};

const getFieldErrorInfo = <T extends FormikValues>(
    formik: FormikConfig<T>,
    fieldName: keyof T
) => {
    const isError =
        formik.touched[fieldName] && Boolean(formik.errors[fieldName]);

    const helperText = isError
        ? (formik.errors[fieldName] as string)
        : undefined;

    return { isError, helperText };
};

export { getFieldErrorInfo };
