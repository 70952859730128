import Box from '@northstar/core/Box';
import { useTheme } from '@northstar/core/styles';
import AppLogo from 'components/Icons/AppLogo';
import { Link } from 'components/Link';
import { useNavigate } from 'react-router-dom';
import { NavSearch, NavAccount } from './components';
import { SidebarNavLinks } from './components/NavLinks';

interface IProps {
    onClose: () => void;
}

const SidebarNav = ({ onClose }: IProps) => {
    const theme = useTheme();
    const { mode } = theme.palette;

    const navigate = useNavigate();

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const searchValue = e.target.search.value.trim() || '';
        if (searchValue) {
            navigate({
                pathname: '/search',
                search: `q=${searchValue}`,
            });
            onClose();
        }
    };

    return (
        <Box paddingX={2}>
            <Link display="inline-flex" to="/" paddingY={1}>
                <AppLogo
                    titleAccess="Fortra"
                    variant={mode === 'light' ? 'dark' : 'light'}
                />
            </Link>
            <Box paddingY={2}>
                <Box component="form" onSubmit={handleSubmit}>
                    <NavSearch sx={{ mb: 2 }} />
                </Box>
                <Box sx={{ mb: 2 }}>
                    <SidebarNavLinks />
                </Box>
                <Box marginTop={1}>
                    <NavAccount />
                </Box>
            </Box>
        </Box>
    );
};

export default SidebarNav;
