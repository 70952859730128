import {
    InfoFilled,
    PriorityHigh,
    ReportProblem,
    Report,
} from '@northstar/icons';
import { SeverityLevel } from 'types/cases';

export const SeverityIcon = ({ name }: { name: SeverityLevel }) => {
    if (name === 'Low') {
        return (
            <InfoFilled
                color="action"
                sx={{
                    fontSize: 40,
                    marginRight: 1,
                }}
            />
        );
    }

    if (name === 'Normal') {
        return (
            <ReportProblem
                color="success"
                sx={{
                    fontSize: 40,
                    marginRight: 1,
                }}
            />
        );
    }

    if (name === 'High') {
        return (
            <Report
                color="warning"
                sx={{
                    fontSize: 40,
                    marginRight: 1,
                }}
            />
        );
    }
    if (name === 'Critical') {
        return (
            <PriorityHigh
                color="error"
                sx={{
                    fontSize: 40,
                    marginRight: 1,
                }}
            />
        );
    }

    return null;
};
