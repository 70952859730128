import { Container, Link } from '@northstar/core';
import AppLogo from 'components/Icons/AppLogo';

interface Props {
    isFullMaintenance?: boolean;
    endDate: string;
}
const MaintenancePage = ({ isFullMaintenance = false, endDate }: Props) => {
    return (
        <Container
            sx={{ width: ['auto', '60%'], paddingX: 2, paddingY: [2, 6] }}
        >
            {isFullMaintenance && (
                <AppLogo
                    variant="dark"
                    height="45"
                    sx={{ margin: 'auto', display: 'block' }}
                />
            )}

            <h1>Maintenance</h1>
            <p>
                Fortra Support Portal is currently undergoing scheduled
                maintenance and is expected to return online by {endDate}. If
                you have a Severity 1, Production Down Emergency and need
                support, please contact us as listed below:
            </p>
            <p>
                <strong>Fortra US &amp; Canada (Toll Free):</strong>
                &nbsp;+1 (800) 328 1000
            </p>
            <p>
                <strong>Fortra Outside of US &amp; Canada:</strong>
                &nbsp;+1 (952) 933 0609
            </p>
            <p>
                <strong>Agari:</strong> +1 (855) 682 1708
            </p>
            <p>
                <strong>Alert Logic UK:</strong> +44 (0) 203 011 5533
            </p>
            <p>
                <strong>Alert Logic US:</strong> +1 (877) 484 8383
            </p>
            <p>
                <strong>Boldon James Support:</strong> +44 (0) 127 050 7810
            </p>
            <p>
                <strong>Clearswift:</strong>&nbsp;+1 (856) 359 2170
            </p>
            <p>
                <strong>Core Security:</strong>&nbsp;+1 (678) 304 4485
            </p>
            <p>
                <strong>
                    Core Privileged Access Management (BoKS) Sweden:
                </strong>
                &nbsp;+46 18 16 0000
            </p>
            <p>
                <strong>Digital Defense:</strong> +1 (888) 273 1412 x2
            </p>
            <p>
                <strong>Digital Guardian:</strong> +1 (800) 558 5305 or +1 (781)
                902 5792
            </p>
            <p>
                <strong>Digital Guardian Managed Services:</strong>{' '}
                <Link href="mailto:dgmanagedservices@fortra.com">
                    dgmanagedservices@fortra.com
                </Link>
            </p>
            <p>
                <strong>Document Management:</strong>&nbsp;+1 (952) 486 6805
            </p>
            <p>
                <strong>FileCatalyst US:</strong> +1 (877) 327 9387
            </p>
            <p>
                <strong>FileCatalyst Outside of US:</strong> +1 (613) 667 2439
            </p>
            <p>
                <strong>GlobalScape:</strong> +1 (210) 366 3993
            </p>
            <p>
                <strong>GoAnywhere US &amp; Canada:</strong>
                &nbsp;+1 (800) 949 4696
            </p>
            <p>
                <strong>GoAnywhere Outside of US &amp; Canada:</strong>
                &nbsp;+1 (402) 944 4242
            </p>
            <p>
                <strong>JAMS:</strong> +1 (800) 261 5267 x2
            </p>
            <p>
                <strong>Terranova:</strong> +1 (866) 889 5806
            </p>
            <p>
                <strong>Titus:</strong> +1 343 655 2752
            </p>
            <p>
                <strong>Tripwire Austria (Toll Free No Cell Phones):</strong>{' '}
                0800 802064
            </p>
            <p>
                <strong>Tripwire Austria (Local):</strong> +43 720 880277
            </p>
            <p>
                <strong>Tripwire India (Toll Free):</strong> 000 800 0502 260
            </p>
            <p>
                <strong>Tripwire NA (Toll Free):</strong> +1 (866) 897 8776
            </p>
            <p>
                <strong>Tripwire Saudi Arabia (Toll Free):</strong> 800 850 0517
            </p>
            <p>
                <strong>Tripwire UAE (Toll Free):</strong> 800 0320828
            </p>
            <p>
                <strong>
                    Tripwire UK, France, Germany, Benelux and Nordics (Toll
                    Free):
                </strong>{' '}
                00 800 77517751
            </p>
            <p>
                <strong>Vera:</strong> +1 (650) 620 7122
            </p>
            <Link href="mailto:support@fortra.com">support@fortra.com</Link>
        </Container>
    );
};

export default MaintenancePage;
