import Box from '@northstar/core/Box';
import DropzoneFile from './DropzoneFile';

interface Props {
    files: File[];
    onRemove: (file: File) => void;
    hasProgressBar?: boolean;
    disabled?: boolean;
    retryUpload?: (file: File) => void;
}

const DropzoneAcceptedFiles = ({
    files,
    onRemove,
    hasProgressBar,
    disabled,
    retryUpload,
}: Props) => {
    return (
        <Box sx={{ width: '100%' }}>
            {files.map((file: any) => (
                <DropzoneFile
                    key={file.name || file.fileName}
                    file={file}
                    onRemove={onRemove}
                    hasProgressBar={hasProgressBar}
                    disabled={disabled}
                    retryUpload={retryUpload}
                />
            ))}
        </Box>
    );
};

export default DropzoneAcceptedFiles;
