import Container from '@northstar/core/Container';
import { Main } from 'components/layouts';
import Box from '@northstar/core/Box';
import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { SearchResultType } from 'types/searchResult';
import SearchFields from './components/SearchFields';
import SearchHeader from './components/Header';
import SearchResult from './components/SearchResult';
import { getCachedSearchResults } from './getCachedSearchResults';

const breadcrumbs = [
    {
        href: '/',
        title: 'Home',
        isActive: false,
    },
    {
        title: 'Search',
        isActive: true,
    },
];

const Search = () => {
    const { search: locationSearch } = useLocation();
    const [searchParams] = useSearchParams();
    const term = searchParams.get('q');

    if (term) {
        breadcrumbs[2] = { title: `Results for “${term}”`, isActive: true };
    } else if (breadcrumbs.length > 2) {
        breadcrumbs.pop();
    }

    const [result, setResult] = useState<SearchResultType>(() =>
        getCachedSearchResults(locationSearch)
    );

    const [loading, setLoading] = useState<boolean>(
        !!term && result.hits.length === 0
    );
    const [next, setNext] = useState<string | null>(null);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

    const cacheData = (data: SearchResultType) => {
        const savedData = {
            searchKey: window.location.search,
            data,
        };

        sessionStorage.setItem('searchResults', JSON.stringify(savedData));
    };

    const getData = (data: SearchResultType) => {
        setResult(data);

        if (data.hits.length > 0) {
            cacheData(data);
        } else if (data.hits.length === 0) {
            sessionStorage.removeItem('searchResults');
        }
    };

    const getNextLink = (nextLink: string | null) => {
        setIsLoadingMore(true);
        setNext(nextLink);
    };

    const getMoreResult = (
        data: SearchResultType | null,
        hasError?: AxiosError
    ) => {
        if (hasError) {
            setNext(null);
        }

        if (!hasError && data) {
            const newData = {
                hits: [...result.hits, ...data.hits],
                nextLink: data.nextLink,
            };

            setNext(null);
            setResult(newData);
            cacheData(newData);
        }

        setIsLoadingMore(false);
    };

    return (
        <Main breadcrumbs={breadcrumbs}>
            <Box marginTop={6} marginBottom={10}>
                <Container>
                    <SearchHeader />
                    <SearchFields
                        searchedData={result}
                        setLoading={setLoading}
                        isLoading={loading}
                        handleSearchResult={getData}
                        nextLnk={next}
                        handleMoreResult={getMoreResult}
                    />
                    <SearchResult
                        term={term}
                        data={result}
                        isLoading={loading}
                        handleNextLink={getNextLink}
                        isLoadingMore={isLoadingMore}
                    />
                </Container>
            </Box>
        </Main>
    );
};

export default Search;
