import { IconButton } from '@northstar/core';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import {
    ThumbUp,
    ThumbDown,
    ThumbUpOutlined,
    ThumbDownOutlined,
} from '@northstar/icons';
import { useAuth } from 'contexts/Auth';
import { useState } from 'react';
import Button from '@northstar/core/Button';
import Alert from '@northstar/core/Alert';
import { Link } from 'components/Link';

export type UserFeedback = 1 | -1 | 0 | null;
interface Props {
    userFeedback: UserFeedback;
    likes: number;
    dislikes: number;
    onRate: (action: UserFeedback) => Promise<any>;
}

const ThumbRating = ({ userFeedback, likes, dislikes, onRate }: Props) => {
    const [like, setLike] = useState<UserFeedback>(userFeedback);
    const [likeTotal, setLikeTotal] = useState<number>(likes);
    const [dislikeTotal, setDislikeTotal] = useState<number>(dislikes);
    const [disabled, setDisabled] = useState<boolean>(false);
    const { user, signIn } = useAuth();
    const [open, setOpen] = useState(false);

    const likeHandle = async (action: UserFeedback) => {
        // redirect to login page
        if (!user) {
            signIn();
            return;
        }
        if (!user.is_portal_user) {
            setOpen(true);
            return;
        }
        // remove like
        if (action === 1 && like === 1) {
            try {
                setDisabled(true);
                await onRate(0);
                setLike(0);
                setLikeTotal(likeTotal - 1);
                setDisabled(false);
            } catch {
                setDisabled(false);
            }
        }
        // like article
        if (action === 1 && like !== 1) {
            try {
                setDisabled(true);
                await onRate(1);
                setLike(1);
                setLikeTotal(likeTotal + 1);
                if (like === -1) {
                    setDislikeTotal(dislikeTotal > 1 ? dislikeTotal - 1 : 0);
                }
                setDisabled(false);
            } catch {
                setDisabled(false);
            }
        }
        // remove dislike
        if (action === -1 && like === -1) {
            try {
                setDisabled(true);
                await onRate(0);
                setLike(0);
                setDislikeTotal(dislikeTotal - 1);
                setDisabled(false);
            } catch {
                setDisabled(false);
            }
        }
        // dislike article
        if (action === -1 && like !== -1) {
            try {
                setDisabled(true);
                await onRate(-1);
                setLike(-1);
                setDislikeTotal(dislikeTotal + 1);
                if (like === 1) {
                    setLikeTotal(likeTotal > 1 ? likeTotal - 1 : 0);
                }
                setDisabled(false);
            } catch {
                setDisabled(false);
            }
        }
    };

    return (
        <>
            <Box
                aria-label="Rating container"
                display="flex"
                alignItems="center"
            >
                <Typography
                    variant="h6"
                    marginRight={3}
                    sx={{ color: 'action.active' }}
                >
                    Was this page helpful?
                </Typography>
                <Box display="flex" alignItems="center" marginRight={1}>
                    <IconButton
                        aria-label="like"
                        onClick={() => likeHandle(1)}
                        disabled={disabled}
                    >
                        {like === 1 ? <ThumbUp /> : <ThumbUpOutlined />}
                    </IconButton>
                    <Typography aria-label="Article likes" marginLeft={1}>
                        {likeTotal}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <IconButton
                        aria-label="dislike"
                        onClick={() => likeHandle(-1)}
                        disabled={disabled}
                    >
                        {like === -1 ? <ThumbDown /> : <ThumbDownOutlined />}
                    </IconButton>
                    <Typography aria-label="Article dislikes" marginLeft={1}>
                        {dislikeTotal}
                    </Typography>
                </Box>
            </Box>
            {open && (
                <Alert
                    sx={{
                        border: '1px solid',
                        borderColor: 'warning.main',
                        marginTop: 2,
                        '& > .MuiAlert-message': {
                            width: '100%',
                            display: ['', 'flex'],
                            alignItems: 'center',
                        },
                        alignItems: ['', 'center'],
                        '& > .MuiAlert-action, & > .MuiAlert-icon': {
                            paddingTop: 0,
                            paddingBottom: 0,
                        },
                    }}
                    severity="warning"
                    onClose={() => setOpen(false)}
                >
                    Your account has not been assigned to an organization yet.
                    Please complete the Request Access form to finish your
                    onboarding.
                    <Button
                        marginLeft="auto"
                        size="small"
                        color="inherit"
                        sx={{ marginLeft: 'auto' }}
                        to="/website-access-request"
                        component={Link}
                        onClick={() => setOpen(false)}
                        flex="none"
                    >
                        Request Access
                    </Button>
                </Alert>
            )}
        </>
    );
};

export default ThumbRating;
