import { DynamicFormField } from 'types/formFields';

const removeDescendantFields = (
    fields: DynamicFormField[],
    fieldName: string
) => {
    const queue = [fieldName];
    const removedFieldsSet = new Set<string>();

    while (queue.length > 0) {
        const currentId = queue.shift();
        const descendantFields = fields.filter(
            (field) => field.parentQuestionId === currentId
        );

        descendantFields.forEach((field) => {
            queue.push(field.props.name);
            removedFieldsSet.add(field.props.name);
        });
    }

    const remainingFormFields = fields.filter(
        (field) => !removedFieldsSet.has(field.props.name)
    );

    const removedFieldIds = Array.from(removedFieldsSet);

    return { remainingFormFields, removedFieldIds };
};

export { removeDescendantFields };
