import { HSProducts } from 'components/HSProducts';
import { ContactFooter } from 'components/CallToAction/ContactFooter';
import { Main } from 'components/layouts';
import { useAuth } from 'contexts/Auth';
import { HeroBanner } from 'components/Banner';

const Home = () => {
    const { user } = useAuth();
    return (
        <Main>
            <HeroBanner
                title="How can we help you?"
                subTitle="We’re committed to solving your problems so you can get
                        back to what you do best."
                image="/assets/images/HeroBanner1.jpg"
                hasForm
                user={user}
            />
            <HSProducts />
            <ContactFooter />
        </Main>
    );
};

export default Home;
