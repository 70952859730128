export const getError = (error: any) => {
    const message =
        error?.response?.data?.errorMessage ||
        error.message ||
        error.title ||
        'Error';
    const status = error?.response?.status || error.status || 0;
    const config = error?.response?.config;
    return {
        message,
        status,
        config,
    };
};
