const dropDownMenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 100,
        },
    },
};

export { dropDownMenuProps };
