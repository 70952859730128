import { LightTheme, DarkTheme } from '@northstar/core';
import theme from '@northstar/theme';

const lightThemeColors = {
    ...LightTheme.palette,
    marketing: theme.palette.marketing,
    alternate: {
        main: '#fafafa',
        dark: '#f5f5f5',
    },
};

const darkThemeColors = {
    ...DarkTheme.palette,
    marketing: theme.palette.marketing,
    alternate: {
        main: '#303030',
        dark: '#212121',
    },
};

export { lightThemeColors, darkThemeColors };
