import Grid from '@northstar/core/Grid';
import { Container } from 'components/Container';
import Typography from '@northstar/core/Typography';

type Props = {
    description: string | null;
    name: string;
};

const ProductOverview = ({ description, name }: Props) => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h1" gutterBottom>
                        {name} Downloads
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'normal' }}>
                        Here are some overarching details about downloads for
                        this product. Any information or links provided here
                        should be applicable to all versions.
                    </Typography>
                    {description && (
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            sx={{ fontWeight: 'normal', marginTop: '10px' }}
                        >
                            {description}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProductOverview;
