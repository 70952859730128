import { createBrowserRouter, redirect } from 'react-router-dom';
import RootLayout from 'components/layouts/RootLayout';
import UserGeneralPage from 'pages/UserAccount/Views/UserGeneralPage';
import MyProductsPage from 'pages/UserAccount/Views/MyProductsPage';
import { fetchAppConfig } from 'services/maintenance';
import {
    CaseCreatePage,
    CasesPage,
    HomePage,
    NotFound,
    ContactUs,
    ViewCase,
    UserAccount,
    ProductPage,
    Search,
    ProductDownloadsPage,
    KBArticlesPage,
    ProductArticle,
    ProductDocumentationPage,
    WebsiteAccessRequest,
} from './pages';

const routes = [
    {
        path: '/',
        element: <RootLayout />,
        loader: fetchAppConfig,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: '/:productSlug',
                children: [
                    { index: true, element: <ProductPage /> },
                    {
                        path: 'downloads',
                        element: <ProductDownloadsPage />,
                    },
                    {
                        path: 'kb-articles',
                        element: <KBArticlesPage />,
                    },
                    {
                        path: 'kb-articles/:articleSlug',
                        element: <ProductArticle />,
                    },
                    {
                        path: 'documentation',
                        element: <ProductDocumentationPage />,
                    },
                ],
            },
            {
                path: '/account',
                element: <UserAccount />,
                children: [
                    {
                        index: true,
                        element: <UserGeneralPage />,
                    },
                    {
                        path: 'products',
                        element: <MyProductsPage />,
                    },
                ],
            },
            {
                path: '/support/cases/*', // Remove this route a couple months later
                loader: ({ params }) => {
                    return redirect(
                        `/support/requests${params['*'] && `/${params['*']}`}`
                    );
                },
            },
            {
                path: '/support/requests',
                children: [
                    {
                        index: true,
                        element: <CasesPage />,
                    },
                    {
                        path: 'open',
                        element: <CaseCreatePage />,
                    },
                    {
                        path: ':id',
                        element: <ViewCase />,
                    },
                ],
            },
            {
                path: '/contact-us',
                element: <ContactUs />,
            },
            {
                path: '/search',
                element: <Search />,
            },
            {
                path: '/website-access-request',
                element: <WebsiteAccessRequest />,
            },
            {
                path: '/kb-articles/slug/:articleSlug',
                element: <ProductArticle />,
            },
            {
                path: '/kb-articles/:articleId',
                element: <ProductArticle />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
];

const Routes = createBrowserRouter(routes);

export default Routes;
