import NorthContainer, { ContainerProps } from '@northstar/core/Container';
import Box, { BoxProps } from '@northstar/core/Box';

const Container = ({ children, sx, ...rest }: BoxProps & ContainerProps) => (
    <Box
        component={NorthContainer}
        sx={{
            paddingTop: { xs: 2, sm: 4, md: 6 },
            paddingBottom: { xs: 2, sm: 4, md: 6 },
            ...sx,
        }}
        {...rest}
    >
        {children}
    </Box>
);

export default Container;
