export const AUTH_CONFIG = {
    idp: {
        realm: process.env.REACT_APP_IDP_REALM,
        url: process.env.REACT_APP_IDP_URL,
        clientId: process.env.REACT_APP_IDP_CLIENT_ID,
    },
    appCookies: {
        idToken: 'idToken',
        accountId: 'accountId',
        refreshToken: 'refreshToken',
    },
};
