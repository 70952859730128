const isHtmlEmpty = (html: string) => {
    const spacesRemoved = html.replace(/&nbsp;/g, ' ').trim();

    const cleanedString = spacesRemoved.replace(/>\s+</g, '><');
    const regex = />[^<]+</;

    const imgBase64Regex =
        /<img\s+[^>]*src=["']data:image\/[^;]+;base64[^>]*>/i;

    return !(regex.test(cleanedString) || imgBase64Regex.test(cleanedString));
};

export { isHtmlEmpty };
