import { LinearProgress } from '@northstar/core';

type Props = {
    loading: boolean;
};

const LinearLoading = ({ loading }: Props) =>
    loading ? (
        <LinearProgress
            sx={{
                borderRadius: '4px',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
            }}
        />
    ) : null;

export { LinearLoading };
