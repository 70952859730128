import Link from '@northstar/core/Link';
import { Manuals } from 'types/products';

type Props = {
    item: Manuals;
};

const ProductDownloadableLinks = ({ item }: Props) => (
    <>
        {item.chmLink && (
            <Link
                sx={{
                    paddingX: 1,
                }}
                href={item.chmLink}
                target="_blank"
            >
                CHM
            </Link>
        )}

        {item.exeLink && (
            <Link
                sx={{
                    paddingX: 1,
                }}
                href={item.exeLink}
                target="_blank"
                download
            >
                EXE
            </Link>
        )}

        {item.htmlLink && (
            <Link
                sx={{
                    paddingX: 1,
                }}
                href={item.htmlLink}
                target="_blank"
            >
                HTML
            </Link>
        )}
        {item.pdfLink && (
            <Link
                sx={{
                    paddingX: 1,
                }}
                href={item.pdfLink}
                target="_blank"
            >
                PDF
            </Link>
        )}

        {item.zipLink && (
            <Link
                sx={{
                    paddingX: 1,
                }}
                href={item.zipLink}
                target="_blank"
                download
            >
                ZIP
            </Link>
        )}
    </>
);

export { ProductDownloadableLinks };
