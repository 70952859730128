import axios from 'utils/axios';
import { formatedDate } from 'utils/formatedDate';
import { getDateTimeRange } from 'utils/getDateTimeRange';
import { parseJsonString } from 'utils/parseJsonString';

export const fetchAppConfig = async () => {
    let appConfig: any = parseJsonString(
        sessionStorage.getItem('appConfig') || ''
    );
    if (!appConfig) {
        try {
            const { data } = await axios.get('/banner_info');
            sessionStorage.setItem('appConfig', JSON.stringify(data));
            appConfig = data;
        } catch (error) {
            console.error(error);
        }
    }
    const MAINTENANCE = appConfig?.maintenanceModeInfo;
    const ANNOUNCEMENT = appConfig?.announcementBannerInfo;
    const M_STARTED =
        MAINTENANCE && getDateTimeRange(MAINTENANCE?.start, MAINTENANCE?.end);
    const READ_ONLY = MAINTENANCE?.isReadOnly;
    const IS_FULL_MAINTENANCE = M_STARTED && !READ_ONLY;
    const IS_READ_ONLY_MAINTENANCE = M_STARTED && READ_ONLY;
    const BANNER_MAINTENANCE = MAINTENANCE?.banner;
    const banner = M_STARTED ? BANNER_MAINTENANCE : ANNOUNCEMENT;
    const announcementStarted = getDateTimeRange(banner?.start, banner?.end);
    const maintenanceEndDay = formatedDate(
        ((MAINTENANCE?.end || 0) * 1000) as any
    ).toDateFormatCT();

    return {
        appConfig,
        IS_FULL_MAINTENANCE,
        IS_READ_ONLY_MAINTENANCE,
        banner,
        announcementStarted,
        maintenanceEndDay,
    };
};
