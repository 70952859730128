import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { formatedDate } from 'utils/formatedDate';
import { TooltipCell } from '@northstar/plus';
import { casesDefaultFilterFn } from '../filterFn/casesDefaultFilterFn';

const CaseModifyDateColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('modifyDate', {
        header: 'Modified At',
        filterFn: casesDefaultFilterFn,
        cell: (cell) => (
            <TooltipCell
                text={formatedDate(cell.getValue() as string).toDate()}
            />
        ),
    });

export { CaseModifyDateColumn };
