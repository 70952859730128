const overrideTableStyles = {
    '& button[data-testid^="Filter-"]': {
        maxWidth: '300px',
        '&>:nth-of-type(2)': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        },
    },
    '& [data-testid="TableHeading"]': {
        display: 'flex',
        flexWrap: 'wrap',
        top: -1000,
    },
    '& [data-testid="TableHeader"]': {
        top: 0,
    },
    '& div[role=columnheader] > span': {
        textWrap: 'nowrap',
    },
    '& [data-testid="TableSearchBar"]': {
        flex: '0 0 100%',
    },
    '& [data-testid="TableFilterBar"]': {
        flex: '1 1 50%',
    },

    '& [data-testid="TableToolbar"]': {
        width: 'auto',
        flex: 'none',
        paddingRight: '6px',
    },
    '& span[aria-label="Settings"]': {
        order: 2,
    },
    height: 765,
    position: 'relative',
    overflow: 'auto',
};

export { overrideTableStyles };
