import { useCallback, useEffect, useState } from 'react';

type IThemeType = 'dark' | 'light';

const themesLiteral: {
    [key: string]: IThemeType;
} = {
    light: 'dark',
    dark: 'light',
};

export const useDarkMode = (): ['light' | 'dark', () => void] => {
    const [themeMode, setTheme] = useState<'light' | 'dark'>('light');

    const setMode = (mode: IThemeType): void => {
        try {
            window.localStorage.setItem('themeMode', mode);
        } catch (e) {
            /* do nothing */
        }
        setTheme(mode);
    };

    const themeToggler = useCallback(() => {
        setMode(themesLiteral[themeMode]);
    }, [themeMode]);

    useEffect(() => {
        try {
            const localTheme = window.localStorage.getItem('themeMode');

            if (
                localTheme &&
                (localTheme === 'light' || localTheme === 'dark')
            ) {
                setTheme(localTheme);
            } else {
                setMode('light');
            }
        } catch (e) {
            setMode('light');
        }
    }, []);

    return [themeMode, themeToggler];
};
