function formatBytes(
    bytes: number,
    decimals: number = 2
): {
    size: number;
    type: string;
    toString: () => string;
} {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const size = +bytes ? parseFloat((bytes / k ** i).toFixed(dm)) : 0;
    const type = size ? sizes[i] : 'Bytes';
    const text = `${size} ${type}`;

    return {
        size,
        type,
        toString() {
            return text;
        },
    };
}

export { formatBytes };
