import { useEffect, useState } from 'react';

const useScript = (
    url: string,
    libName: any = undefined,
    selector: keyof HTMLElementTagNameMap = 'body',
    async: boolean = true
) => {
    const [lib, setLib] = useState<any>();
    useEffect(() => {
        const element = document.querySelector(selector) as HTMLElement;
        const script: HTMLScriptElement = document.createElement('script');

        script.src = url;
        script.async = async;
        if (libName) {
            script.onload = () => setLib(window[libName]);
        }

        element.appendChild(script);

        return () => {
            element.removeChild(script);
        };
    }, [async, libName, selector, url]);
    return lib;
};

export default useScript;
