import { Box } from '@northstar/core';
import { useEffect, useRef, useState } from 'react';
import { Hits, SearchResultType } from 'types/searchResult';
import axios from 'utils/axios';
import { debounce } from 'utils/debounce';
import Typography from '@northstar/core/Typography';
import { CloseTwoTone } from '@northstar/icons';
import { Link } from 'components/Link';
import { currentExecutingRequests } from 'utils/axios/currentExecutingRequests';
import { LinearLoading } from './LinearLoading';

type Props = {
    caseTitle: string;
    productId: string | undefined;
};

const calculateWrapperMargin = (
    loading: boolean,
    articles: Hits[] | undefined
) => (loading || articles ? 2 : 0);

const KBArticlesSuggestion = ({ caseTitle, productId }: Props) => {
    const reqURL = '/search';
    const [articles, setArticles] = useState<Hits[] | undefined>();
    const [loading, setLoading] = useState(false);
    const currentRequest = currentExecutingRequests?.[reqURL];

    const getSearchResults = useRef(
        debounce(async (value: string, id: string) => {
            if (id && value && value.length >= 5) {
                try {
                    setLoading(true);
                    const { data: searchResult } =
                        await axios.post<SearchResultType>(reqURL, {
                            productId: id,
                            search: value,
                        });
                    const slicedHits = searchResult.hits.slice(0, 5);
                    setArticles(slicedHits);
                    setLoading(false);
                } catch (error) {
                    console.error('Failed to get search results', error);
                    setLoading(false);
                }
            } else {
                setArticles(undefined);
            }
        }, 2000)
    ).current;

    useEffect(() => {
        if (!productId) {
            setArticles(undefined);
        }
        getSearchResults(caseTitle, productId);
        if (currentRequest || (currentRequest && caseTitle.length < 5)) {
            currentRequest.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caseTitle, productId]);

    return (
        <Box
            bgcolor="background.default"
            position="relative"
            marginTop={calculateWrapperMargin(loading, articles)}
            id="kb-article-suggestions"
        >
            <LinearLoading loading={loading} />
            {articles && articles.length > 0 && (
                <Box
                    sx={{ padding: 3.5 }}
                    aria-label="Knowledge Base Article Suggestions"
                >
                    <Typography
                        variant="h4"
                        marginBottom={1}
                        sx={{ fontWeight: 500 }}
                    >
                        Suggested Knowledge Base Articles
                    </Typography>
                    <Typography component="p" variant="body1">
                        Based on the product and title of the request, here are
                        some articles that might be related
                    </Typography>
                    <CloseTwoTone
                        sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: 12,
                            right: 12,
                        }}
                        onClick={() => {
                            setArticles(undefined);
                            currentRequest && currentRequest.abort();
                        }}
                    />

                    {articles.map((item) => (
                        <Link
                            key={item.knowledgeArticleId}
                            variant="h6"
                            color="primary"
                            target="_blank"
                            sx={{ marginTop: 1, display: 'block' }}
                            to={`/${item.productSlug}/kb-articles/${item.urlKey}`}
                        >
                            {item.title}
                        </Link>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export { KBArticlesSuggestion };
