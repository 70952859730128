import { ProductDownloads } from 'types/products';
import AccordionDetails from '@northstar/core/AccordionDetails';
import SanitizeHTML from 'components/SanitizeHTML';
import { DownloadsList } from '../components/DownloadsList';
import { VersionType } from '../components/VersionType';

type Props = {
    versions: ProductDownloads[];
    productId: string;
};

const Latest = ({ versions, productId }: Props) => {
    return (
        <VersionType versions={versions}>
            {({ version, index }) => (
                <AccordionDetails sx={{ p: 0 }}>
                    <SanitizeHTML
                        aria-label={`Description Content ${index}`}
                        sx={{
                            px: 2,
                            pt: 1,
                            mb: 2,
                            '& a': {
                                color: 'primary.main',
                            },
                        }}
                        html={version?.description || ''}
                    />
                    <DownloadsList
                        downloadLinks={version.downloadLinks}
                        versionId={version.id || productId}
                        productId={version.productId}
                    />
                </AccordionDetails>
            )}
        </VersionType>
    );
};

export default Latest;
