import { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@northstar/core';
import { severityRequiresConfirmation } from 'pages/Support/utils/severityRequiresConfirmation';
import { Severity } from 'types/cases';
import { Button } from 'components/Button';
import { SeverityConfirmeContnet } from './SeverityConfirmeContnet';
import { SeverityOptions } from './SeverityOptions';

interface Props {
    open: boolean;
    onClose: () => void;
    onConfirm: (value: string) => void;
    options: Severity[];
    defaultValue?: string;
}

export const SeveritySelectDialog = ({
    open,
    onClose,
    onConfirm,
    options,
    defaultValue,
}: Props) => {
    const [confirm, setConfirm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [severityLevel, setSeverityLevel] = useState(defaultValue ?? 'LOW');

    const dialogCloseHandler = () => {
        setTimeout(() => setShowConfirmation(false), 300);
        setConfirm(false);
        if (defaultValue) {
            setSeverityLevel(defaultValue);
        }
        onClose();
        return true;
    };

    const confirmHandler = () => {
        if (severityLevel === defaultValue) {
            dialogCloseHandler();
            return;
        }

        const willConfirm = severityRequiresConfirmation(
            options,
            severityLevel
        );

        if (willConfirm && !confirm) {
            setShowConfirmation(true);
            return;
        }

        onConfirm(severityLevel);
        dialogCloseHandler();
    };

    useEffect(() => {
        if (defaultValue) {
            setSeverityLevel(defaultValue);
        }
    }, [defaultValue]);

    return (
        <Dialog
            open={open}
            onClose={() => dialogCloseHandler()}
            maxWidth={false}
        >
            {!showConfirmation && options && (
                <DialogContent sx={{ maxWidth: 960 }}>
                    <SeverityOptions
                        name="severity"
                        options={options}
                        value={severityLevel}
                        onChange={(e) => setSeverityLevel(e.target.value)}
                    />
                </DialogContent>
            )}
            {showConfirmation && (
                <DialogContent sx={{ maxWidth: 444 }}>
                    <SeverityConfirmeContnet
                        confirm
                        onConfirme={() => setConfirm((prev) => !prev)}
                    />
                </DialogContent>
            )}
            <DialogActions sx={{ padding: '0px 24px 24px 24px' }}>
                <Button onClick={() => dialogCloseHandler()}>CANCEL</Button>
                {options && (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={showConfirmation && !confirm}
                        onClick={() => confirmHandler()}
                    >
                        CONFIRM
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
