export const removeArrayDuplicateObjectsByKey = <T>(
    arr: T[],
    key: keyof T
): T[] => {
    if (!Array.isArray(arr)) {
        return [];
    }
    const uniqueKeys = new Set();
    return arr.reduce((uniqueArray, obj) => {
        if (!uniqueKeys.has(obj[key])) {
            uniqueKeys.add(obj[key]);
            uniqueArray.push(obj);
        }
        return uniqueArray;
    }, [] as T[]);
};
