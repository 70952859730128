import { ProductDownloads } from 'types/products';
import { objectOrderBy } from 'utils/objectOrderBy';

const sortDownloadLinks = (downloadList: ProductDownloads[]) => {
    downloadList.forEach((item) => {
        const sortedLinks = objectOrderBy(item.downloadLinks, 'number');
        Object.assign(item, { downloadLinks: sortedLinks });
    });
};

export { sortDownloadLinks };
