import { Link } from 'components/Link';
import { useAuth } from 'contexts/Auth';
import Box from '@northstar/core/Box';
import { Search as SearchIcon } from '@northstar/icons';

const HeaderNavLinks = ({ ...rest }) => {
    const { user } = useAuth();
    return (
        <Box
            sx={{
                'a:not(:last-child)': {
                    mr: 2,
                },
            }}
        >
            {user?.is_portal_user && (
                <>
                    <Link
                        underline="none"
                        sx={{ color: 'common.white' }}
                        {...rest}
                        to="/support/requests"
                    >
                        Manage Requests
                    </Link>
                    <Link
                        underline="none"
                        sx={{ color: 'common.white' }}
                        {...rest}
                        to="/support/requests/open"
                    >
                        Open Support Request
                    </Link>
                </>
            )}
            {!user && (
                <Link
                    underline="none"
                    sx={{ color: 'common.white' }}
                    {...rest}
                    to="/contact-us"
                >
                    Contact Us
                </Link>
            )}
            <Link
                underline="none"
                sx={{ color: 'common.white' }}
                to="/search"
                {...rest}
            >
                <Box
                    display="inline-flex"
                    component="span"
                    sx={{ verticalAlign: 'top' }}
                >
                    <SearchIcon sx={{ mr: 0.75 }} />
                    Search
                </Box>
            </Link>
        </Box>
    );
};

export default HeaderNavLinks;
