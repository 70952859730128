import Box, { BoxProps } from '@northstar/core/Box';
import sanitizeHtml from 'sanitize-html';
import { configSanitizeHTML } from './configSanitizeHTML';

interface IProps extends BoxProps {
    html: string;
}

const SanitizeHTML = ({ html, ...rest }: IProps) => {
    const clean = sanitizeHtml(html, configSanitizeHTML);
    return <Box {...rest} dangerouslySetInnerHTML={{ __html: clean }} />;
};

export default SanitizeHTML;
