const jobList: {
    [key: string]: number;
} = {
    Procurement: 717710017,
    'Systems Administration': 717710005,
    'Security Director': 717710014,
    'Security Consultant': 717710015,
    'Security Administration': 717710004,
    'Sales/Marketing': 717710009,
    'Programmer/Developer': 717710007,
    'Other IT Staff': 717710006,
    'Network Administration': 717710003,
    'IT Management': 717710001,
    Finance: 717710013,
    'Database Administration': 717710002,
    Consultant: 717710012,
    'C-Level': 717710000,
    'Business User': 717710010,
    Auditor: 717710008,
    Other: 717710011,
    'Accounts Payable': 717710016,
};

export { jobList };
