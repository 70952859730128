import { ObjectSchema, ValidationError } from 'libs/validation';
import { FieldValues, Resolver, ResolverResult } from 'react-hook-form';

const useYupValidationResolver =
    <T extends FieldValues>(schema: ObjectSchema<T>): Resolver<T> =>
    async (data: T): Promise<ResolverResult<T>> => {
        try {
            const values = await schema.validate(data, {
                abortEarly: false,
            });

            return {
                values,
                errors: {},
            };
        } catch (error) {
            const validationErrors = (error as ValidationError).inner.reduce(
                (allErrors, currentError) => ({
                    ...allErrors,
                    [currentError.path!]: {
                        type: currentError.type ?? 'validation',
                        message: currentError.message,
                    },
                }),
                {}
            );

            return {
                values: {},
                errors: validationErrors,
            };
        }
    };

export { useYupValidationResolver };
