const buttons = [
    'undo',
    'redo',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'align',
    'ul',
    'ol',
    'outdent',
    'indent',
    '|',
    'paragraph',
    'link',
];

export { buttons };
