import { useAuth } from 'contexts/Auth';

import { Link } from 'components/Link';

import Menu from '@northstar/core/Menu';
import MenuItem from '@northstar/core/MenuItem';
import { Product } from 'types/products';

type Props = {
    selectedProduct: Product | null;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
};

const ProductsOptionsMenu = ({
    selectedProduct,
    handleClose,
    anchorEl,
}: Props) => {
    const { getProductPermissions } = useAuth();

    const { canKBArticles, canForums, canManuals, canDownloads } =
        getProductPermissions(
            selectedProduct?.permissions,
            selectedProduct?.isSecured
        );

    return selectedProduct ? (
        <Menu
            anchorEl={anchorEl}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            onClose={handleClose}
            open
        >
            {canKBArticles && (
                <MenuItem
                    component={Link}
                    to={`/${selectedProduct?.slug}/kb-articles`}
                >
                    Knowledge Base
                </MenuItem>
            )}
            {canManuals && (
                <MenuItem
                    component={Link}
                    to={`/${selectedProduct?.slug}/documentation`}
                >
                    Documentation
                </MenuItem>
            )}
            {canForums && (
                <MenuItem
                    component={Link}
                    to={`/${selectedProduct?.slug}/forums`}
                >
                    Forums
                </MenuItem>
            )}
            {canDownloads && (
                <MenuItem
                    component={Link}
                    to={`/${selectedProduct?.slug}/downloads`}
                >
                    Downloads
                </MenuItem>
            )}
        </Menu>
    ) : null;
};

export { ProductsOptionsMenu };
