import Button from '@northstar/core/Button';
import Dialog from '@northstar/core/Dialog';
import DialogActions from '@northstar/core/DialogActions';
import DialogContent from '@northstar/core/DialogContent';
import DialogContentText from '@northstar/core/DialogContentText';
import DialogTitle from '@northstar/core/DialogTitle';
import { useApp } from 'contexts/App';
import { useAuth } from 'contexts/Auth';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import AuthService from 'services/auth/AuthService';
import { calculateSessionTimeouts } from './calculateSessionTimeouts';

const SnackbarAction = (
    snackbarId: string | number,
    removeSessionLogOut: () => void,
    closeSnackbar: (snackbarId: string | number) => void
) => {
    return (
        <>
            <Button
                size="small"
                variant="contained"
                onClick={() => {
                    AuthService.signIn();
                    removeSessionLogOut();
                    closeSnackbar(snackbarId);
                }}
            >
                Log In
            </Button>
            <Button
                size="small"
                color="inherit"
                sx={{ marginLeft: 1 }}
                onClick={() => {
                    removeSessionLogOut();
                    closeSnackbar(snackbarId);
                }}
            >
                Dismiss
            </Button>
        </>
    );
};

const AuthSessionDialog = () => {
    const { user, isLoading } = useAuth();

    const { addNotification, closeSnackbar } = useApp();

    const { DEFAULT_REMAINING_SEC, IDLE_TIMEOUT_MS, PROMPT_TIMEOUT_MS } =
        calculateSessionTimeouts();

    const [remaining, setRemaining] = useState<number>(DEFAULT_REMAINING_SEC);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const logOutByIdle = sessionStorage.getItem('logOutReason') === 'idle';

    const onIdle = () => {
        AuthService.signOut({
            reason: 'idle',
            redirectUri: window.location.href,
        });
    };

    const onActive = async () => {
        try {
            setOpenDialog(false);
            await AuthService.updateToken(-1);
        } catch (error) {
            console.error('Failed to refresh token:', error);
            if (error === 'Not authenticated') {
                AuthService.signOut({
                    reason: 'idle',
                    redirectUri: window.location.href,
                });
            }
        }
    };

    const onPrompt = () => {
        setOpenDialog(true);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    };

    const onAction = async () => {
        try {
            if (user && AuthService.isTokenExpired(30) && !openDialog) {
                if (AuthService.isRefreshTokenExpired()) {
                    AuthService.signOut({
                        reason: 'idle',
                        redirectUri: window.location.href,
                    });
                } else {
                    await AuthService.updateToken(-1);
                }
            }
        } catch (error) {
            console.error('Failed to refresh token:', error);
            if (error === 'Not authenticated') {
                AuthService.signOut({
                    reason: 'idle',
                    redirectUri: window.location.href,
                });
            }
        }
    };

    const { activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        onAction,
        timeout: IDLE_TIMEOUT_MS,
        promptBeforeIdle: PROMPT_TIMEOUT_MS,
        throttle: 1000,
        eventsThrottle: 1000,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200,
        disabled: !user,
    });

    const removeSessionLogOut = () => {
        sessionStorage.removeItem('logOutReason');
    };

    useEffect(() => {
        const interval =
            openDialog &&
            setInterval(() => {
                setRemaining((count) => (count > 0 ? count - 1 : count));
            }, 1000);

        return () => {
            interval && clearInterval(interval);
        };
    }, [openDialog]);

    useEffect(() => {
        if (!isLoading && !user && logOutByIdle) {
            addNotification(
                {
                    message: 'You have been logged out due to inactivity.',
                    status: 0,
                },
                {
                    autoHideDuration: null,
                    action: (snackbarId) =>
                        SnackbarAction(
                            snackbarId,
                            removeSessionLogOut,
                            closeSnackbar
                        ),
                }
            );
        }
    }, [addNotification, isLoading, logOutByIdle, user, closeSnackbar]);

    const handleStillHere = () => {
        activate();
        setRemaining(DEFAULT_REMAINING_SEC);
    };

    return (
        <Dialog onClose={handleStillHere} open={openDialog} maxWidth="md">
            <DialogTitle>Need more time?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your session is about to expire. You will be automatically
                    logged out in {remaining} second
                    {remaining > 1 && 's'}.
                </DialogContentText>
                <DialogActions sx={{ marginTop: 2 }}>
                    <Button onClick={() => AuthService.signOut()}>
                        Log Out
                    </Button>
                    <Button onClick={handleStillHere} variant="contained">
                        Stay Logged In
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default AuthSessionDialog;
