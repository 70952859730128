import { AnswerOption } from 'types/createCase';

const getIdToCompare = (
    value: AnswerOption,
    answerOptions: AnswerOption[] | undefined
) => {
    if (value.criteriaValue && answerOptions) {
        return (
            answerOptions.find(
                (item) =>
                    value.criteriaValue === item.value &&
                    value.questionId === item.questionId
            )?.id || value.id
        );
    }

    return value.id;
};

export { getIdToCompare };
