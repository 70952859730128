import { FormControlLabel, Radio, RadioGroup } from '@northstar/core';
import { Severity } from 'types/cases';
import { ChangeEvent, forwardRef, Ref } from 'react';
import { SeverityLabel } from './SeverityLabel';

interface Props {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: any;
    value?: any;
    options: Severity[];
    name?: string;
}

export const SeverityOptions = forwardRef(function MyInput(
    { onChange, defaultValue, options, name, value, ...fieldRest }: Props,
    ref: Ref<HTMLInputElement>
) {
    return (
        <RadioGroup
            ref={ref}
            defaultValue={defaultValue}
            value={value}
            name={name}
            onChange={onChange}
            {...fieldRest}
        >
            {options?.map((item: any) => (
                <FormControlLabel
                    key={item.level}
                    value={item.level}
                    control={<Radio sx={{ display: 'none' }} />}
                    sx={{
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: 2,
                        '& .Mui-checked + .MuiFormControlLabel-label > div': {
                            backgroundColor: 'divider',
                            transition: '0.3s ease',
                        },
                    }}
                    label={
                        <SeverityLabel
                            level={item.level}
                            subTitle={item.subTitle}
                            description={item.description}
                        />
                    }
                />
            ))}
        </RadioGroup>
    );
});
