import { useState, MouseEvent } from 'react';
import Divider from '@northstar/core/Divider';
import { Accordion, AccordionDetails } from '@northstar/core';
import { useAuth } from 'contexts/Auth';
import type { Product, UserProducts } from 'types/products';
import useSWR from 'swr';
import { useAccounts } from 'contexts/Accounts';
import { ProductsSection } from 'pages/UserAccount/Views/ProductsSection';
import { ProductsLoading } from './ProductsLoading';
import { ProductsHeader } from './ProductsHeader';
import { AccountTitle } from './AccountTitle';
import { ProductsOptionsMenu } from './ProductsOptionsMenu';

const MyProductsPage = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [expanded, setExpanded] = useState<boolean | number>(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(
        null
    );

    const { user } = useAuth();
    const {
        getAllAccountsIds,
        isSingleAccount,
        myProducts,
        selectedAccounts,
        handleUsersProducts,
        userAccountsDetails,
        relatedAccounts,
    } = useAccounts();

    const handleClick = (event: MouseEvent<HTMLElement>, product: Product) => {
        setAnchorEl(event.currentTarget);
        setSelectedProduct(product);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedProduct(null);
    };

    const handleExpandedState = (idx: number) => {
        setExpanded((prevExpanded) => (prevExpanded === idx ? false : idx));
    };

    const isLoadingUserProducts =
        user?.is_portal_user && !myProducts && relatedAccounts;

    useSWR<UserProducts>(
        isLoadingUserProducts && {
            url: '/my_products',
            args: { header: { portalAccountIds: getAllAccountsIds() } },
        },
        { onSuccess: handleUsersProducts }
    );

    const isEmptyAccountsOrItems =
        relatedAccounts &&
        (!selectedAccounts.length ||
            (myProducts && !Object.keys(myProducts).length));

    const getAccordionExpandedState = (idx: number) =>
        isSingleAccount ? true : expanded === idx;

    const handleAccordionOnChange = (idx: number) =>
        isSingleAccount ? undefined : handleExpandedState(idx);

    return (
        <>
            {userAccountsDetails.length > 0 && (
                <>
                    <ProductsHeader />
                    <Divider sx={{ marginY: 4 }} />
                    {isEmptyAccountsOrItems && (
                        <ProductsSection
                            products={[]}
                            handleClick={handleClick}
                        />
                    )}
                    {!isEmptyAccountsOrItems && (
                        <>
                            {userAccountsDetails.map(
                                ({ accountId, accountName }, index) => {
                                    if (!accountName || !accountId) return null;

                                    const currentProductList =
                                        myProducts?.[accountId];

                                    return currentProductList ? (
                                        <Accordion
                                            key={accountId}
                                            variant="outlined"
                                            expanded={getAccordionExpandedState(
                                                index
                                            )}
                                            onChange={() =>
                                                handleAccordionOnChange(index)
                                            }
                                        >
                                            <AccountTitle
                                                isSingleAccount={
                                                    isSingleAccount
                                                }
                                                accountName={accountName}
                                            />
                                            <AccordionDetails>
                                                <ProductsSection
                                                    products={
                                                        currentProductList
                                                    }
                                                    handleClick={handleClick}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : (
                                        <ProductsLoading key={accountId} />
                                    );
                                }
                            )}
                        </>
                    )}
                </>
            )}
            <ProductsOptionsMenu
                handleClose={handleClose}
                anchorEl={anchorEl}
                selectedProduct={selectedProduct}
            />
        </>
    );
};

export default MyProductsPage;
