import { GlobalStyles } from '@northstar/core';

const TableGlobalStyles = () => (
    <GlobalStyles
        styles={({ palette }) => ({
            'ul#OrganizationSelect, ul#product, ul#caseStatus': {
                maxHeight: 350,
            },
            'svg[data-testid="CheckBoxIcon"]': {
                fill: palette.primary.main,
            },
        })}
    />
);

export { TableGlobalStyles };
