import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { casesDefaultFilterFn } from '../filterFn/casesDefaultFilterFn';

const RequestNumberColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('caseNumber', {
        header: 'Request Number',
        filterFn: casesDefaultFilterFn,
    });

export { RequestNumberColumn };
