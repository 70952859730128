export const FALLBACK_BLOCKEDLIST =
    'ade;adp;app;asa;ashx;asmx;asp;bas;bat;cdx;cer;class;cmd;com;config;cpl;crt;csh;dll;exe;fxp;hlp;hta;htr;htw;ida;idc;idq;inf;ins;isp;its;js;jse;ksh;lnk;mad;maf;mag;mam;maq;mar;mas;mat;mau;mav;maw;mda;mdb;mde;mdt;mdw;mdz;msc;msh;msh1;msh1xml;msh2;msh2xml;mshxml;msi;msp;mst;ops;pcd;pif;prf;prg;printer;pst;reg;rem;scf;scr;sct;shb;shs;shtm;shtml;soap;stm;tmp;url;vb;vbe;vbs;vsmacros;vss;vst;vsw;ws;wsc;wsf;wsh;svg';

export const MAX_FILES_LENGTH = 10;
export const MAX_FILE_SIZE = 96468992;
export const MAX_FILES_TOTAL = 524288000;
export const MAX_FILE_NAME = 255;

export const videoFormats = ['mp4', 'webm', 'ogg', 'ogv'];
export const imageFormats = ['png', 'jpg', 'jpeg', 'webp', 'gif'];
export const documentFormats = ['pdf'];

export const MESSAGE_ZIPPING_FILES =
    'File Size Limit: The maximum size limit per file is 92MB.  Total file size, up to 10 files, cannot exceed 500MB.  We highly recommend that you consider zipping large files for an efficient upload process or contact your Support team member for an alternative link.';

export const MAX_FILES_MSG = {
    message: `You can attach up to ${MAX_FILES_LENGTH} files.`,
    type: 'MAX_FILES',
};

export const DUPLICATE_FILES_MSG = {
    message: 'Duplicate file could not be attached to the request.',
    type: 'DUPLIACTE_FILES',
};
