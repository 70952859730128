import { TableState } from '@tanstack/table-core';
import { TABLE_ROWS_PER_PAGE } from 'constants/tableConfig';

const tableDefaultState: TableState = {
    columnSizing: {},
    columnSizingInfo: {
        startOffset: null,
        startSize: null,
        deltaOffset: null,
        deltaPercentage: null,
        isResizingColumn: false,
        columnSizingStart: [],
    },
    rowSelection: {},
    expanded: {},
    grouping: [],
    sorting: [],
    columnFilters: [],
    globalFilter: '',
    columnPinning: {
        left: [],
        right: [],
    },
    rowPinning: {
        top: [],
        bottom: [],
    },
    columnOrder: [],
    columnVisibility: {},
    pagination: {
        pageSize: TABLE_ROWS_PER_PAGE[0],
        pageIndex: 0,
    },
};

export { tableDefaultState };
