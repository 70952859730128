import { StringSchema, string } from 'libs/validation';
import { DynamicFormField } from 'types/formFields';

interface Validation {
    [key: string]: StringSchema;
}

const createValidationRules = (fields: DynamicFormField[]) => {
    const validation = {} as Validation;

    fields.forEach((item) => {
        let validationSchema = string().trim();

        if (item.validationRules) {
            if (item.validationRules.limit > 1) {
                validationSchema = validationSchema.max(
                    item.validationRules.limit
                );
            }

            if (item.validationRules.isRequired) {
                validationSchema = validationSchema.required();
            }
        }

        validation[item.props.name] = validationSchema;
    });

    return validation;
};

export { createValidationRules };
