import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Box from '@northstar/core/Box';
import { Button } from 'components/Button';
import TextField from '@northstar/core/TextField';
import InputAdornment from '@northstar/core/InputAdornment';
import Grid from '@northstar/core/Grid';
import { Search } from '@northstar/icons';
import { Brand, Product } from 'types/products';
import useSWR from 'swr';
import { Autocomplete } from '@northstar/core';
import { getProductsFromBrands } from 'utils/getProductsFromBrands';
import axios from 'utils/axios';
import { useEffectOnce, usePrevious } from 'hooks';

import { AxiosError } from 'axios';
import { currentExecutingRequests } from 'utils/axios/currentExecutingRequests';
import { objectOrderBy } from 'utils/objectOrderBy';
import { SearchResultType } from 'types/searchResult';
import { clearAutoCompleteValue } from 'utils/clearAutoCompleteValue';

interface Props {
    handleSearchResult: (data: SearchResultType) => void;
    searchedData: SearchResultType;
    setLoading: (value: boolean) => void;
    isLoading: boolean;
    nextLnk: string | null;
    handleMoreResult: (
        data: SearchResultType | null,
        hasError?: AxiosError
    ) => void;
}

const SearchFields = ({
    handleSearchResult,
    nextLnk,
    handleMoreResult,
    setLoading,
    isLoading,
    searchedData,
}: Props) => {
    const reqUrl = '/search';

    const { search: locationSearch } = useLocation();
    const refProduct = useRef<HTMLElement>(null);
    const { data: productBrands, error: brandError } =
        useSWR<Brand[]>('/productbrands');
    const [brandData, setBrandData] = useState<Brand[]>([]);
    const [productData, setProductData] = useState<Product[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const qSearch = searchParams.get('q') || '';
    const qBrand = searchParams.get('brand') || '';
    const qProduct = searchParams.get('product') || '';
    const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(
        null
    );

    const locationSearchPrev = usePrevious(locationSearch);

    const shouldSearch =
        (locationSearch &&
            locationSearchPrev &&
            locationSearch !== locationSearchPrev) ||
        (locationSearchPrev === '' && locationSearch);

    const brandAll = {
        id: 'all',
        name: 'All Brands',
    } as Brand;

    const productAll = {
        id: 'all',
        name: 'All Products',
    } as Product;

    const handleBrandChange = (value: Brand | null) => {
        setSelectedBrand(value);
        if (refProduct && refProduct.current) {
            setSearchParams(searchParams);

            clearAutoCompleteValue(refProduct.current);
        }
        if (value && value.name !== 'All Brands') {
            setProductData(objectOrderBy(value.productList, 'name'));
        } else {
            setProductData([
                productAll,
                ...getProductsFromBrands(productBrands),
            ]);
        }
    };

    const handleProductChange = (value: Product | null) => {
        if (!selectedBrand) {
            setSelectedBrand(brandAll);
        }
        setSelectedProduct(value);
    };

    const getSearchData = async (
        term: string,
        brandId?: string,
        productId?: string,
        nextLinkValue?: string | null
    ) => {
        return axios.post(
            reqUrl,
            {
                search: term,
                productBrandId: brandId === 'all' ? null : brandId || null,
                productId: productId === 'all' ? null : productId || null,
            },
            {
                params: {
                    nextLink: nextLinkValue,
                },
            }
        );
    };

    const setSearchResult = (data: SearchResultType | null) => {
        handleSearchResult(data || { hits: [], nextLink: null });
    };

    const getSearchResult = async () => {
        setLoading(true);

        try {
            const { data } = await getSearchData(qSearch, qBrand, qProduct);

            setSearchResult(data);
            setLoading(false);
        } catch (error: any) {
            if (error.code === 'ERR_CANCELED' && window.location.search) {
                setLoading(true);
            } else {
                setSearchResult(null);
                setLoading(false);
            }
        }
    };

    const getMoreResult = () => {
        const result = getSearchData(qSearch, qBrand, qProduct, nextLnk);
        result
            .then(({ data }) => handleMoreResult(data))
            .catch((error) => handleMoreResult(null, error));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const searchValue = e.target.elements.search.value.trim() || '';
        const brandValue = selectedBrand?.id || '';
        const productValue = selectedProduct?.id || '';

        if (!searchValue) {
            setSearchResult(null);
        }

        if (brandValue) {
            searchParams.set('brand', brandValue);
        } else {
            searchParams.delete('brand');
        }

        if (productValue) {
            searchParams.set('product', productValue);
        } else {
            searchParams.delete('product');
        }

        searchParams.set('q', searchValue);
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (!productBrands) {
            return;
        }

        const allBrands = [brandAll, ...productBrands];
        const allProducts = [
            productAll,
            ...getProductsFromBrands(productBrands),
        ];

        setBrandData(allBrands);
        setProductData(allProducts);

        if (!qBrand) {
            return;
        }

        const defaultBrand = allBrands.find((item) => item.id === qBrand);
        if (!defaultBrand) {
            return;
        }

        if (defaultBrand.id === brandAll.id) {
            defaultBrand.productList = allProducts;
        }

        setSelectedBrand(defaultBrand);

        if (defaultBrand.id !== brandAll.id) {
            setProductData(objectOrderBy(defaultBrand.productList, 'name'));
        } else {
            setProductData(allProducts);
        }

        if (qProduct) {
            const defaultProduct = defaultBrand.productList.find(
                (item) => item.id === qProduct
            );
            setSelectedProduct(defaultProduct || null);
        }
        if (brandError) {
            setSearchResult(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productBrands, brandError]);

    useEffectOnce(() => {
        if (qSearch && searchedData.hits.length === 0) {
            getSearchResult();
        }
    });

    useEffect(() => {
        if (nextLnk) {
            getMoreResult();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextLnk]);

    useEffect(() => {
        if (qSearch && shouldSearch) {
            getSearchResult();
        } else if (locationSearchPrev && !locationSearch) {
            setSearchResult(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSearchResult, setSearchResult, shouldSearch]);

    useEffect(() => {
        if (locationSearchPrev && locationSearch === '') {
            currentExecutingRequests[reqUrl]?.abort();
        }
    }, [locationSearch, locationSearchPrev]);

    useEffect(
        () => () => {
            currentExecutingRequests?.[reqUrl]?.abort();
        },
        []
    );

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                <TextField
                    sx={{
                        mr: { xs: 0, md: 2 },
                        mb: { xs: 2, md: 0 },
                    }}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    fullWidth
                    InputProps={{
                        sx: {
                            height: 54,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search
                                    sx={{
                                        color: 'marketing.fortaForest',
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    defaultValue={qSearch}
                    key={qSearch}
                    name="search"
                    aria-label="search"
                />
                <Button
                    sx={{
                        height: 54,
                        whiteSpace: 'nowrap',
                        minWidth: 105,
                    }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    Search
                </Button>
            </Box>
            <Box marginTop={3} marginBottom={6}>
                <Grid spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            disablePortal
                            options={brandData}
                            loading={!brandData}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(
                                option: Brand,
                                value: Brand
                            ) => option.id === value.id}
                            renderOption={(props, option) => {
                                if (option.name === null) {
                                    return null;
                                }
                                return (
                                    <li {...props} key={props.id}>
                                        {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    name="brand"
                                    placeholder="Brand"
                                    {...params}
                                />
                            )}
                            value={selectedBrand}
                            onChange={(_, value) => handleBrandChange(value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            disablePortal
                            ref={refProduct}
                            options={productData}
                            loading={!productData}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(
                                option: Product,
                                value: Product
                            ) => option.id === value.id}
                            renderOption={(props, option) => {
                                if (option.name === null) {
                                    return null;
                                }
                                return (
                                    <li {...props} key={props.id}>
                                        {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Product"
                                    name="product"
                                    {...params}
                                />
                            )}
                            value={selectedProduct}
                            onChange={(_, value) => handleProductChange(value)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default SearchFields;
