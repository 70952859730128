import Box from '@northstar/core/Box';
import Grid from '@northstar/core/Grid';
import Avatar from '@northstar/core/Avatar';
import Typography from '@northstar/core/Typography';
import Paper from '@northstar/core/Paper';
import Button from '@northstar/core/Button';
import { Link } from 'components/Link';
import { useParams } from 'react-router-dom';
import { Permissions } from 'types/products';
import { Container } from 'components/Container';

import {
    BookTwoTone as BookTwoToneIcon,
    SchoolTwoTone as SchoolTwoToneIcon,
    ForumTwoTone as ForumTwoToneIcon,
    GetAppTwoTone as GetAppTwoToneIcon,
    ArrowRightAlt as ArrowRightAltIcon,
} from '@northstar/icons';
import { useAuth } from 'contexts/Auth';

const mock = {
    knowledgeBase: {
        title: 'Knowledge Base',
        subtitle:
            'Check out our common troubleshooting articles, videos, and how-to guides to make using our software as easy as possible.',
        url: '#',
    },
    forums: {
        title: 'Forums',
        subtitle:
            'Connect with your peers, ask questions, and share pointers in our product discussion forums.',
        url: '#',
    },
    documentation: {
        title: 'Documentation',
        subtitle:
            'Find your Fortra product manuals and documentation for quick and easy reference.',
        url: '#',
    },
    downloads: {
        title: 'Downloads',
        subtitle: "Find your organization's purchased product downloads.",
    },
};

type Props = {
    productPermissions: Permissions | undefined;
    isSecured: boolean;
};

const ProductInfo = ({ productPermissions, isSecured }: Props) => {
    const { getProductPermissions } = useAuth();
    const { productSlug } = useParams();

    const { canKBArticles, canForums, canManuals, canDownloads } =
        getProductPermissions(productPermissions, isSecured);

    return (
        <Container sx={{ paddingTop: { xs: 2, sm: 4 } }}>
            <Grid container spacing={4}>
                {canKBArticles && (
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={0}
                            variant="outlined"
                            sx={{ p: 2, display: 'flex' }}
                        >
                            <Box
                                component={Avatar}
                                marginBottom={1}
                                width={60}
                                height={60}
                                sx={{
                                    bgcolor: 'scale.yellow.100',
                                    color: 'scale.yellow.900',
                                }}
                            >
                                <BookTwoToneIcon sx={{ fontSize: 28 }} />
                            </Box>
                            <Box sx={{ ml: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    {mock.knowledgeBase.title}
                                </Typography>
                                <Typography color="textSecondary">
                                    {mock.knowledgeBase.subtitle}
                                </Typography>

                                <Button
                                    to={`/${productSlug}/kb-articles`}
                                    component={Link}
                                    sx={{ mt: 2, ml: -1 }}
                                    endIcon={<ArrowRightAltIcon />}
                                >
                                    View {mock.knowledgeBase.title}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                )}
                {canManuals && (
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={0}
                            variant="outlined"
                            sx={{ p: 2, display: 'flex' }}
                        >
                            <Box
                                component={Avatar}
                                marginBottom={1}
                                width={60}
                                height={60}
                                sx={{
                                    bgcolor: 'scale.seagreen.100',
                                    color: 'scale.seagreen.900',
                                }}
                            >
                                <SchoolTwoToneIcon sx={{ fontSize: 28 }} />
                            </Box>
                            <Box sx={{ ml: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    {mock.documentation.title}
                                </Typography>
                                <Typography color="textSecondary">
                                    {mock.documentation.subtitle}
                                </Typography>

                                <Button
                                    to={`/${productSlug}/documentation`}
                                    component={Link}
                                    sx={{ mt: 2, ml: -1 }}
                                    endIcon={<ArrowRightAltIcon />}
                                >
                                    View {mock.documentation.title}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                )}
                {canForums && (
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={0}
                            variant="outlined"
                            sx={{ p: 2, display: 'flex' }}
                        >
                            <Box
                                component={Avatar}
                                marginBottom={1}
                                width={60}
                                height={60}
                                sx={{
                                    bgcolor: 'scale.red.100',
                                    color: 'scale.red.900',
                                }}
                            >
                                <ForumTwoToneIcon sx={{ fontSize: 28 }} />
                            </Box>
                            <Box sx={{ ml: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    {mock.forums.title}
                                </Typography>
                                <Typography color="textSecondary">
                                    {mock.forums.subtitle}
                                </Typography>

                                <Button
                                    to={mock.knowledgeBase.url}
                                    component={Link}
                                    sx={{ mt: 2, ml: -1 }}
                                    endIcon={<ArrowRightAltIcon />}
                                >
                                    View {mock.forums.title}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                )}
                {canDownloads && (
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={0}
                            variant="outlined"
                            sx={{ p: 2, display: 'flex' }}
                        >
                            <Box
                                component={Avatar}
                                marginBottom={1}
                                width={60}
                                height={60}
                                sx={{
                                    bgcolor: 'scale.blue.100',
                                    color: 'scale.blue.900',
                                }}
                            >
                                <GetAppTwoToneIcon sx={{ fontSize: 28 }} />
                            </Box>
                            <Box sx={{ ml: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    {mock.downloads.title}
                                </Typography>
                                <Typography color="textSecondary">
                                    {mock.downloads.subtitle}
                                </Typography>
                                <Button
                                    to={`/${productSlug}/downloads`}
                                    component={Link}
                                    sx={{ mt: 2, ml: -1 }}
                                    endIcon={<ArrowRightAltIcon />}
                                >
                                    View {mock.downloads.title}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default ProductInfo;
