import { editorConfig } from './editorConfig';

const removeDeniedTags = (html: string) => {
    const tags = Object.keys(editorConfig.cleanHTML.denyTags).join('|');
    const regex = new RegExp(`</?(${tags})[^>]*>`, 'gi');

    return html.replace(regex, '');
};

export { removeDeniedTags };
