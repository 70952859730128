import { ProductDownloads } from 'types/products';

const sortByVersionNumber = (data: ProductDownloads[]) => {
    if (data.length <= 1) {
        return data;
    }

    data.sort((a, b) => {
        if (!a.version && !b.version) return 0;
        if (!a.version) return 1;
        if (!b.version) return -1;

        const numA = a.version.replace(/[^0-9.]/g, '').split('.');
        const numB = b.version.replace(/[^0-9.]/g, '').split('.');

        for (let i = 0; i < numA.length; i += 1) {
            if (!numA[i]) return 1;
            if (!numB[i]) return -1;

            const aPart =
                numA[i][0] === '0' && numA.length > 1
                    ? +`${+numA[i].substring(1, 1)}.${numA[i].substring(1)}`
                    : +numA[i];

            const bPart =
                numB[i][0] === '0' && numB.length > 1
                    ? +`${+numB[i].substring(1, 1)}.${numB[i].substring(1)}`
                    : +numB[i];

            if (aPart < bPart) return 1;
            if (aPart > bPart) return -1;
        }

        return 0;
    });

    return data;
};

export { sortByVersionNumber };
