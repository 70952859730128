import { Skeleton } from '@northstar/core';

const FormFieldLoading = () => (
    <>
        <Skeleton
            animation="wave"
            aria-label="skeleton"
            width="150px"
            height="20px"
        />
        <Skeleton
            animation="wave"
            aria-label="skeleton"
            width="100%"
            height="86px"
        />
    </>
);

export { FormFieldLoading };
