import { Breadcrumbs, Typography, Box } from '@northstar/core';
import { Link } from 'components/Link';
import Container from '@northstar/core/Container';

interface BreadcrumbProps {
    data: BreadcrumbItem[];
    mode?: 'dark' | 'light';
    breadcrumbFullWidth?: boolean;
}

export type BreadcrumbItem = {
    href?: string;
    title: string;
    isActive: boolean;
};

const Breadcrumb = ({
    data,
    mode = 'light',
    breadcrumbFullWidth = false,
}: BreadcrumbProps) => {
    const size = breadcrumbFullWidth ? 'xl' : undefined;
    const color =
        mode === 'dark'
            ? {
                  bg: '#145351',
                  borderColor: '1px solid rgba(255, 255, 255, 0.08)',
                  text: {
                      active: 'common.white',
                      main: 'rgba(255, 255, 255, 0.87)',
                  },
              }
            : {
                  bg: 'alternate.dark',
                  borderColor: '1px solid rgba(0, 0, 0, 0.08)',
                  text: {
                      active: 'textPrimary',
                      main: 'inherit',
                  },
              };

    return (
        <Box
            bgcolor={color.bg}
            sx={{
                borderBottom: color.borderColor,
            }}
            aria-label="breadcrumb-box"
            paddingY={2}
        >
            <Container maxWidth={size}>
                <Breadcrumbs aria-label="breadcrumb" color={color.text.main}>
                    {!data && <Typography> </Typography>}
                    {data.map((item: BreadcrumbItem) => (
                        <span key={item.href || `#${item.title}`}>
                            {item.isActive ? (
                                <Typography
                                    sx={{ color: color.text.active }}
                                    role="paragraph"
                                >
                                    {item.title}
                                </Typography>
                            ) : (
                                <Link
                                    color="inherit"
                                    to={item.href || '#'}
                                    sx={{
                                        '&:hover': {
                                            textDecoration: 'none',
                                        },
                                    }}
                                >
                                    {item.title}
                                </Link>
                            )}
                        </span>
                    ))}
                </Breadcrumbs>
            </Container>
        </Box>
    );
};

export default Breadcrumb;
