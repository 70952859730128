import Typography from '@northstar/core/Typography';
import { Link } from 'components/Link';

const ProductsHeader = () => (
    <>
        <Typography variant="h6" gutterBottom fontWeight={500}>
            My products
        </Typography>
        <Typography variant="body2" color="textSecondary">
            Listed are the products associated with your account. If this list
            is inaccurate or incomplete, please{' '}
            <Link color="primary" to="/website-access-request" underline="none">
                request product access
            </Link>{' '}
            to resolve this issue.
        </Typography>
    </>
);

export { ProductsHeader };
