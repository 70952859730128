import type { Brand, Product } from 'types/products';
import { objectOrderBy } from 'utils/objectOrderBy';

const getProductsFromBrands = (brands: Brand[] | undefined) => {
    if (!brands) {
        return [];
    }

    const list: Product[] = [];

    brands.forEach((brand) => {
        brand.productList.forEach((product) => {
            list.push(product);
        });
    });

    return objectOrderBy(list, 'name');
};

export { getProductsFromBrands };
