import Box from '@northstar/core/Box';
import Button from '@northstar/core/Button';
import Paper from '@northstar/core/Paper';
import Typography from '@northstar/core/Typography';
import { useDropzone } from 'react-dropzone';
import Alert from '@northstar/core/Alert';
import { useEffect, useState } from 'react';
import {
    DUPLICATE_FILES_MSG,
    MAX_FILES_LENGTH,
    MAX_FILES_MSG,
} from 'constants/file';
import DropzoneAcceptedFiles from './DropzoneAcceptedFiles';
import { findDuplicateFileNames } from './utils/findDuplicateFileNames';

interface Props {
    label?: string;
    onDrop(...args: any): any;
    showDropArea?: boolean;
    dropArea?: 'self' | 'parrent';
    info?: string;
    disabled?: boolean;
    errors?: string;
    onRemove: (file: File) => void;
    files: File[];
    retryUpload?: (file: File) => void;
    hasProgressBar?: boolean;
}

type WarrningMessgaeType = { message: string; type: string };

const Dropzone = ({
    label = 'Browse Files',
    onDrop,
    showDropArea = false,
    dropArea = 'parrent',
    info,
    disabled,
    errors,
    onRemove,
    files,
    retryUpload,
    hasProgressBar = true,
}: Props) => {
    const disableUploaad = disabled || files.length >= 10;

    const [warnings, setWarning] = useState<WarrningMessgaeType[]>([]);

    const handleDropFile = (acceptedFiles: File[]) => {
        setWarning([]);
        const maxFiles = files.length + acceptedFiles.length > MAX_FILES_LENGTH;
        if (findDuplicateFileNames(acceptedFiles, files)) {
            setWarning((prev) => [...prev, DUPLICATE_FILES_MSG]);
        }
        if (maxFiles) {
            setWarning((prev) => [...prev, MAX_FILES_MSG]);
        }
        onDrop(acceptedFiles);
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop: handleDropFile,
        noClick: true,
        disabled: disableUploaad,
    });

    const dropAreaColor = disableUploaad ? 'text.disabled' : 'text.secondary';
    const dropzontStyle = showDropArea
        ? {
              display: 'flex',
              flexDirection: 'column',
          }
        : {};

    const dropzonContainertStyle = showDropArea
        ? {
              border: '2px dashed',
              borderColor: 'action.disabled',
              borderRadius: 1,
              padding: 4,
              textAlign: 'center',
              width: 1,
          }
        : {};

    const dropZoneLableStyle = showDropArea
        ? {
              marginBottom: 1,
              order: -2,
              color: dropAreaColor,
          }
        : {
              marginLeft: 1,
              color: dropAreaColor,
              display: {
                  xs: 'none',
                  sm: 'inline-block',
              },
          };

    useEffect(() => {
        if (!files.length) {
            setWarning([]);
        }
    }, [files.length]);

    return (
        <>
            <Box
                {...getRootProps()}
                sx={{
                    position: dropArea === 'self' ? 'relative' : 'static',
                    ...dropzonContainertStyle,
                }}
            >
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...dropzontStyle,
                    }}
                >
                    {isDragActive && (
                        <Paper
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                zIndex: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: 0.7,
                            }}
                        >
                            <Typography variant="h4">
                                Drop files to upload
                            </Typography>
                        </Paper>
                    )}
                    <input aria-label={label} {...getInputProps()} />
                    <Button
                        component="span"
                        variant="outlined"
                        color="primary"
                        onClick={open}
                        disabled={disableUploaad}
                    >
                        {label}
                    </Button>

                    <Typography
                        variant={showDropArea ? 'body1' : 'caption'}
                        sx={dropZoneLableStyle}
                    >
                        {!showDropArea && 'or '}Drag and Drop files here
                    </Typography>
                    {showDropArea && (
                        <Typography
                            variant={showDropArea ? 'body1' : 'caption'}
                            sx={{
                                marginBottom: 1.375,
                                order: -1,
                                color: dropAreaColor,
                            }}
                        >
                            Or
                        </Typography>
                    )}
                </Box>
                {info && (
                    <Typography
                        component="p"
                        variant="caption"
                        marginTop={1}
                        sx={{ color: dropAreaColor }}
                    >
                        {info}
                    </Typography>
                )}
            </Box>
            <Box sx={{ width: '100%' }}>
                {errors && (
                    <Alert
                        aria-label="errorAlert"
                        severity="error"
                        sx={{ mt: 2 }}
                    >
                        {errors}
                    </Alert>
                )}

                {!!warnings.length && (
                    <Alert
                        aria-label="warningAlert"
                        severity="warning"
                        onClose={() => setWarning([])}
                        sx={{ mt: 2 }}
                    >
                        {warnings.map((warning) => `${warning.message} `)}
                    </Alert>
                )}
            </Box>

            <DropzoneAcceptedFiles
                files={files}
                onRemove={onRemove}
                hasProgressBar={hasProgressBar}
                disabled={disabled}
                retryUpload={retryUpload}
            />
        </>
    );
};

export default Dropzone;
