interface Props {
    src: string;
    alt?: string;
    fallbackSrc?: string;
    style?: object;
    width?: string | number;
    height?: string | number;
    onError?: () => void;
    [key: string]: any;
}
const Image = ({ src, alt, fallbackSrc, ...rest }: Props) => {
    return (
        <img
            src={src}
            alt={alt}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                if (fallbackSrc) {
                    e.currentTarget.src = fallbackSrc;
                    e.currentTarget.className = 'error';
                }
            }}
            {...rest}
        />
    );
};

export default Image;
