import { Box, Button, Typography, Container } from '@northstar/core';
import { useTheme } from '@northstar/core/styles';
import { Image } from 'components/Image';
import { Link } from 'components/Link';
import SanitizeHTML from 'components/SanitizeHTML';
import { pageErrorData } from 'constants/pageErrorData';

interface Props {
    status: number | string;
}

const PageError = ({ status }: Props) => {
    const theme = useTheme();

    let error: {
        status: number;
        message: string;
        description: string;
        image: string;
    } = pageErrorData[404];

    if (Number(status) >= 500) {
        error = { ...pageErrorData[500] };
    } else if (Number(status) in pageErrorData) {
        error = { ...pageErrorData[Number(status)] };
    }

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
            }}
        >
            <Container>
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    position="relative"
                >
                    <Box
                        width={1}
                        order={{ xs: 2, md: 1 }}
                        display="flex"
                        alignItems="center"
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        paddingY={4}
                    >
                        <Box>
                            <Typography
                                variant="h1"
                                component="h1"
                                sx={{
                                    fontWeight: 200,
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                                marginBottom={2}
                            >
                                {error.status}
                            </Typography>
                            <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                    fontWeight: 200,
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                                marginBottom={3}
                            >
                                {error.message}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                color="textSecondary"
                                aria-label="Error Description"
                                marginBottom={4}
                                sx={{ textAlign: { xs: 'center', md: 'left' } }}
                            >
                                <SanitizeHTML html={error.description} />
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent={{
                                    xs: 'center',
                                    md: 'flex-start',
                                }}
                            >
                                <Button
                                    marginTop={4}
                                    component={Link}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    to="/"
                                >
                                    Back home
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: { xs: '0 0 100%', md: '0 0 50%' },
                            position: 'relative',
                            maxWidth: { xs: '100%', md: '50%' },
                            order: { xs: 1, md: 2 },
                            minHeight: {
                                xs: 'auto',
                                md: '100vh',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: 1, md: '50vw' },
                                height: '100%',
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                }}
                            >
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        left: '0%',
                                        width: 1,
                                        height: 1,
                                        position: {
                                            xs: 'relative',
                                            md: 'absolute',
                                        },
                                        clipPath: {
                                            xs: 'none',
                                            md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                                        },
                                        shapeOutside: {
                                            xs: 'none',
                                            md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                                        },
                                    }}
                                >
                                    <Box
                                        height={{ xs: 300, md: 1 }}
                                        maxHeight={{ xs: 300, md: 1 }}
                                        width={1}
                                        maxWidth={1}
                                        sx={{
                                            effect: 'blur',
                                            position: 'relative',
                                            filter:
                                                theme.palette.mode === 'dark'
                                                    ? 'brightness(0.7)'
                                                    : 'none',
                                        }}
                                    >
                                        <Image
                                            src={error.image}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                            alt={String(status) || ''}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default PageError;
