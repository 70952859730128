import Select, { SelectChangeEvent } from '@northstar/core/Select';
import { useAccounts } from 'contexts/Accounts';
import { dropDownMenuProps } from 'constants/dropDownMenuProps';

import { useEffect, useState } from 'react';
import { FormControl, InputLabel } from '@northstar/core';
import ListItemText from '@northstar/core/ListItemText';
import MenuItem from '@northstar/core/MenuItem';
import Checkbox from '@northstar/core/Checkbox';
import { SelectedAccounts } from './SelectedAccounts';

interface Props {
    handleSelectionChange?: () => void;
}

const AccountsSelection = ({ handleSelectionChange }: Props) => {
    const {
        userAccountsDetails,
        selectedAccounts,
        setSelectedAccounts,
        isSingleAccount,
    } = useAccounts();

    const [prevSelectedAccounts, setPrevSelectedAccounts] = useState<
        string[] | undefined
    >();

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

    const shouldShowTooltip =
        selectedAccounts.length !== userAccountsDetails?.length &&
        tooltipIsOpen;

    const handleChange = (
        event: SelectChangeEvent<typeof selectedAccounts>
    ) => {
        const {
            target: { value },
        } = event;

        if (value.length) {
            const stateData =
                typeof value === 'string' ? value.split(',') : value;

            setSelectedAccounts((prev) => {
                setPrevSelectedAccounts(prev);
                return stateData;
            });

            sessionStorage.setItem('selectedAccounts', stateData.toString());
        }
    };

    const handleClose = () => {
        setPrevSelectedAccounts(selectedAccounts);

        if (prevSelectedAccounts?.length !== selectedAccounts.length) {
            handleSelectionChange?.();
        }
    };

    useEffect(() => {
        if (selectedAccounts.length && !prevSelectedAccounts) {
            setPrevSelectedAccounts(selectedAccounts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccounts]);

    if (isSingleAccount) return null;

    return (
        <FormControl
            sx={{
                my: 1,
                mx: [0, 2],
                width: ['100%', '300px'],
            }}
            size="small"
        >
            <InputLabel id="organization-filter">Organization</InputLabel>
            <Select
                fullWidth
                multiple
                MenuProps={dropDownMenuProps}
                labelId="organization-filter-select"
                id="organization-filter-select"
                label="Organization"
                aria-label="Organization Select"
                onMouseEnter={() => setTooltipIsOpen(true)}
                onMouseLeave={() => setTooltipIsOpen(false)}
                onClick={() => setTooltipIsOpen(false)}
                value={selectedAccounts}
                onChange={handleChange}
                onClose={handleClose}
                renderValue={() => (
                    <SelectedAccounts shouldShowTooltip={shouldShowTooltip} />
                )}
            >
                {userAccountsDetails.map((item) => (
                    <MenuItem value={item.accountId} key={item.accountId}>
                        <Checkbox
                            color="primary"
                            checked={
                                selectedAccounts.indexOf(item.accountId) > -1
                            }
                        />
                        <ListItemText
                            primary={item.accountName}
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                            }}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export { AccountsSelection };
