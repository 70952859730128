import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { multiSelectFilterFn } from '../filterFn/multiSelectFilterFn';

const CaseStatusColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('caseStatus', {
        header: 'Status',
        filterFn: multiSelectFilterFn,
        enableColumnFilter: false,
        enableHiding: false,
    });

export { CaseStatusColumn };
