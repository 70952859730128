import Typography from '@northstar/core/Typography';
import { forwardRef, PropsWithChildren } from 'react';

export const InputTitle = forwardRef((props: PropsWithChildren, ref: any) => (
    <Typography
        fontWeight={500}
        marginBottom={2}
        ref={ref}
        sx={{ width: 'fit-content' }}
        variant="body2"
        {...props}
    >
        {props.children}
    </Typography>
));
