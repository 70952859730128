import { Case } from 'types/cases';

const defaultValues: Case = {
    account: '',
    attachments: [],
    caseDescription: '',
    caseTitle: '',
    draftCaseId: '',
    language: '',
    operatingSystem: '',
    product: '',
    productId: '',
    productVersion: '',
    selectedAccount: '',
    severity: 'Normal',
    category: 'Support Request',
};

export { defaultValues };
