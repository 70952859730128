/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';
import Grid from '@northstar/core/Grid';
import TextField from '@northstar/core/TextField';
import FormControl from '@northstar/core/FormControl';
import InputLabel from '@northstar/core/InputLabel';
import Select, { SelectChangeEvent } from '@northstar/core/Select';
import MenuItem from '@northstar/core/MenuItem';
import axios from 'utils/axios';
import { useApp } from 'contexts/App';
import { Button } from 'components/Button';
import { object, string } from 'libs/validation';
import { AlertFormSubmited } from 'components/Alert';

type IContactFormFieldsValues = {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    infoType: string;
};

const validationSchema = object({
    firstName: string().trim().min(2).max(50).required(),
    lastName: string().trim().min(2).max(50).required(),
    email: string().trim().email().required(),
    message: string().trim().required(),
});

const ContactUsForm = () => {
    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
    const { addNotification } = useApp();

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        infoType: '',
    };

    const onSubmit = async (
        values: IContactFormFieldsValues,
        actions: FormikHelpers<IContactFormFieldsValues>
    ) => {
        actions.setSubmitting(true);
        try {
            await axios.post('/hs_contact_details', values);
            setFormSubmitSuccess(true);
        } catch (e: any) {
            addNotification({
                message: e.message,
                status: e.response.status,
            });
            actions.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const handleInfoTypeChange = (event: SelectChangeEvent) => {
        formik.setFieldValue('infoType', event.target.value);
    };

    return (
        <Box
            flex={{ xs: '1 1 100%', md: '1 1 70%' }}
            maxWidth={{ xs: '100%' }}
            sx={{ pt: 4, pb: 4 }}
            bgcolor="alternate.dark"
        >
            <Box maxWidth={600} margin="0 auto">
                {formSubmitSuccess ? (
                    <AlertFormSubmited
                        title="Thanks!"
                        description="Your request has been received and a team member will
                get in touch with you soon!"
                    />
                ) : (
                    <>
                        <Box marginBottom={4}>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                Contact us
                            </Typography>
                            <Typography color="textSecondary" align="center">
                                Our team is ready to answer your questions -
                                we’d love to hear from you!
                            </Typography>
                        </Box>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            sx={{ height: 54 }}
                                            label="First name"
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            name="firstName"
                                            fullWidth
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.firstName &&
                                                Boolean(formik.errors.firstName)
                                            }
                                            helperText={
                                                formik.touched.firstName &&
                                                formik.errors.firstName
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            sx={{ height: 54 }}
                                            label="Last name"
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            name="lastName"
                                            fullWidth
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.lastName &&
                                                Boolean(formik.errors.lastName)
                                            }
                                            helperText={
                                                formik.touched.lastName &&
                                                formik.errors.lastName
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{ height: 54 }}
                                            label="Email"
                                            type="email"
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            name="email"
                                            fullWidth
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.email &&
                                                Boolean(formik.errors.email)
                                            }
                                            helperText={
                                                formik.touched.email &&
                                                formik.errors.email
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="infoType-request-label">
                                                I am looking for...
                                            </InputLabel>
                                            <Select
                                                labelId="infoType-request-label"
                                                id="infoType-request"
                                                value={formik.values.infoType}
                                                label="I am looking for..."
                                                fullWidth
                                                onChange={handleInfoTypeChange}
                                            >
                                                <MenuItem value="support">
                                                    Support information
                                                </MenuItem>
                                                <MenuItem value="product">
                                                    Product information
                                                </MenuItem>
                                                <MenuItem value="general">
                                                    General information
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Message"
                                            multiline
                                            rows={6}
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            name="message"
                                            fullWidth
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.message &&
                                                Boolean(formik.errors.message)
                                            }
                                            helperText={
                                                formik.touched.message &&
                                                formik.errors.message
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        justifyContent="center"
                                    >
                                        <Button
                                            variant="contained"
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justifyContent="center"
                                        xs={12}
                                    >
                                        <Typography color="textSecondary">
                                            We'll get back to you in 1-2
                                            business days.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export { ContactUsForm };
