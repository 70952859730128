import { Grid } from '@northstar/core';

import useSWR from 'swr';

import { Os } from 'types/createCase';

import { FormField } from 'types/formFields';
import { useFormContext } from 'react-hook-form';
import { Suspense } from 'react';
import { FormFieldLoading } from 'components/FormFieldLoading';
import { LazyFormAutocomplete, LazyFormInput } from 'components/FormFields';

type Props = {
    showConditionalFields: boolean;
    handleDraftChange: (data: any) => void;
};

const ConditionalFormFields = ({
    showConditionalFields,
    handleDraftChange,
}: Props) => {
    const { data: os } = useSWR<Os[]>('/operating_systems');

    const { getValues: getFormValues } = useFormContext();

    const formValues = getFormValues();

    if (!showConditionalFields) return null;

    const conditionalFormFields: FormField<Os>[] = [
        {
            elementType: 'input',
            props: {
                inputLabel: 'Product Version',
                name: 'productVersion',
                placeholder: 'Enter version number *',
            },
        },
        {
            elementType: 'dropDown',
            props: {
                inputLabel: 'Operating System',
                name: 'operatingSystem',
                labelKey: 'label',
                valueKey: 'value',
                options: os,
                placeholder: 'Select Operating System *',
                fieldValue: os?.find(
                    (v) => v.value === formValues.operatingSystem
                ),
            },
        },
    ];

    const handleOnChange = (value: { name: string; data: Os | null }) => {
        const { name, data } = value;

        handleDraftChange({
            name,
            value: data?.value,
        });
    };

    const renderField = (field: FormField<Os>) => {
        const { elementType, props } = field;

        switch (elementType) {
            case 'input':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormInput
                            handleOnChange={handleDraftChange}
                            {...props}
                        />
                    </Suspense>
                );
            case 'dropDown':
                return (
                    <Suspense fallback={<FormFieldLoading />}>
                        <LazyFormAutocomplete
                            handleOnChange={handleOnChange}
                            {...props}
                        />
                    </Suspense>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {conditionalFormFields.map((item) => (
                <Grid key={item.props.name} item xs={12}>
                    {renderField(item)}
                </Grid>
            ))}
        </>
    );
};

export { ConditionalFormFields };
