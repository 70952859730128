export const removeQueryParameters = (
    url: string,
    paramsToRemove: string[]
): string => {
    const urlObject = new URL(url);

    try {
        paramsToRemove.forEach((param) => {
            urlObject.searchParams.delete(param);
        });

        return encodeURI(decodeURI(urlObject.toString()));
    } catch {
        return `${urlObject.origin}${urlObject.pathname}${urlObject.search}`;
    }
};
