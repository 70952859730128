import { functionalUpdate, OnChangeFn, TableState } from '@tanstack/table-core';
import { useCallback, useEffect, useRef, useState } from 'react';
import AuthService from 'services/auth/AuthService';
import { debounce } from 'utils/debounce';
import { parseJsonString } from 'utils/parseJsonString';

export const casesTableStorageKey = 'casesTableState';

const getInitialState = (defaultState: TableState, key: string) => {
    const localState = parseJsonString(localStorage.getItem(key) || '');

    const sessionState = parseJsonString(sessionStorage.getItem(key) || '');

    return {
        ...defaultState,
        ...(localState || {}),
        ...(sessionState || {}),
    };
};

const usePersistentTableState = (defaultState: TableState) => {
    const { getPortalAccountId } = AuthService;

    const storageKey = getPortalAccountId() || casesTableStorageKey;

    const [tableState, setTableState] = useState<TableState>(() =>
        getInitialState(defaultState, storageKey)
    );

    const debouncedSave = useRef(
        debounce((newState: TableState) => {
            const {
                columnFilters,
                columnOrder,
                columnSizing,
                columnVisibility,
                globalFilter,
                pagination,
                sorting,
            } = newState;

            const newColumnFilters = columnFilters.filter(
                (item) => item.id !== 'ownerInfo'
            );

            const sessionItems = {
                globalFilter,
                columnFilters: newColumnFilters,
            };

            const localItems = {
                columnOrder,
                columnSizing,
                columnVisibility,
                pagination,
                sorting,
            };

            localStorage.setItem(storageKey, JSON.stringify(localItems));
            sessionStorage.setItem(storageKey, JSON.stringify(sessionItems));
        }, 800)
    );

    useEffect(() => {
        debouncedSave
            .current(tableState)
            .catch((error) =>
                console.error('Failed to save table state:', error)
            );
    }, [tableState, storageKey]);

    const onStateChange: OnChangeFn<TableState> = useCallback(
        (updater) =>
            setTableState((old: TableState) => functionalUpdate(updater, old)),
        []
    );

    return { tableState, onStateChange, setTableState };
};

export { usePersistentTableState };
