import { ProductDownloads } from 'types/products';
import AccordionDetails from '@northstar/core/AccordionDetails';
import Typography from '@northstar/core/Typography';
import Box from '@northstar/core/Box';
import Divider from '@northstar/core/Divider';

import SanitizeHTML from 'components/SanitizeHTML';
import { DownloadsList } from '../components/DownloadsList';
import { VersionType } from '../components/VersionType';

type Props = {
    versions: ProductDownloads[];
    productId: string;
    productName: string;
    handleChangeTab: (value: number) => void;
};

const Previous = ({
    versions,
    productName,
    handleChangeTab,
    productId,
}: Props) => {
    return (
        <VersionType versions={versions}>
            {({ version, index }) => (
                <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ px: 2 }}>
                        <Divider sx={{ mt: -1, mb: 3 }} />
                        <Typography
                            sx={{
                                pt: 1,
                                mb: 2,
                            }}
                        >
                            To download files for the latest version of{' '}
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 500,
                                }}
                            >
                                {productName}
                            </Typography>
                            ,{' '}
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}
                                aria-label="Latest Version Number"
                                onClick={() => handleChangeTab(0)}
                            >
                                see here
                            </Typography>
                            . For more information on this version, see below:
                        </Typography>
                        <SanitizeHTML
                            aria-label={`Description Content ${index}`}
                            sx={{
                                pt: 1,
                                mb: 2,
                                '& a': {
                                    color: 'primary.main',
                                },
                            }}
                            html={version?.description || ''}
                        />
                    </Box>
                    <DownloadsList
                        downloadLinks={version.downloadLinks}
                        versionId={version.id || productId}
                        productId={version.productId}
                    />
                </AccordionDetails>
            )}
        </VersionType>
    );
};

export default Previous;
