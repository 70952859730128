import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { SelectColumnFilter } from '@northstar/plus';
import { multiSelectFilterFn } from '../filterFn/multiSelectFilterFn';

const CaseSeverityColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('severity', {
        header: 'Severity',
        enableHiding: true,
        filterFn: multiSelectFilterFn,
        meta: {
            filterFlags: {
                alwaysShow: false,
                filterRender: SelectColumnFilter,
                multiple: true,
                options: ['Low', 'Normal', 'High', 'Critical'],
            },
        },
    });

export { CaseSeverityColumn };
