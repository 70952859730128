export function debounce(fn: Function, ms = 500) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return <T>(...args: any): Promise<T> => {
        return new Promise((resolve, reject) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                try {
                    const output = fn(...args);
                    resolve(output);
                } catch (err) {
                    reject(err);
                }
            }, ms);
        });
    };
}
