import { ColumnHelper } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { casesDefaultFilterFn } from '../filterFn/casesDefaultFilterFn';

const CaseAssigneeColumn = (columnHelper: ColumnHelper<CaseData>) =>
    columnHelper.accessor('ownerInfo.owner', {
        header: 'Fortra Assignee',
        id: 'fortraAssignee',
        filterFn: casesDefaultFilterFn,
    });

export { CaseAssigneeColumn };
