import Box from '@northstar/core/Box';
import IconButton from '@northstar/core/IconButton';
import Container from '@northstar/core/Container';
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@northstar/icons';

import { NavAccount } from './components';
import Logo from '../Logo';
import { HeaderNavLinks } from './components/NavLinks';

interface IProps {
    onSidebarOpen: () => void;
    expanded: boolean;
}
const Topbar = ({ onSidebarOpen, expanded = false }: IProps) => {
    return (
        <Container
            maxWidth="xl"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Logo />
            <Box
                sx={{ display: { xs: 'none', md: 'flex' } }}
                alignItems="center"
            >
                <HeaderNavLinks />
                <NavAccount marginLeft={2} />
            </Box>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => onSidebarOpen()}
                sx={{
                    ml: -2.25,
                    mr: [0, 1],
                    display: { xs: 'inline-flex', md: 'none' },
                }}
            >
                {!expanded ? <MenuIcon /> : <MenuOpenIcon />}
            </IconButton>
        </Container>
    );
};

export default Topbar;
