const openFileInANewTab = async (file: string, name: string) => {
    try {
        const base64Response = await fetch(file);
        const blob = await base64Response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    } catch (e) {
        throw new Error(`Failed to view ${name}`);
    }
};

export { openFileInANewTab };
