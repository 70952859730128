import Box from '@northstar/core/Box';
import { ContactFooter } from 'components/CallToAction/ContactFooter';
import { Container } from 'components/Container';
import Divider from '@northstar/core/Divider';
import Typography from '@northstar/core/Typography';
import { useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Main } from 'components/layouts';
import { useTheme } from '@northstar/core/styles';
import useScript from 'utils/useScript';
import LoadingBox from 'components/LoadingBox';
import ThumbRating, { UserFeedback } from 'components/ThumbRating';
import axios from 'utils/axios';
import AccessRestricted from 'components/DataState/AccessRestricted';
import SanitizeHTML from 'components/SanitizeHTML';
import { useApp } from 'contexts/App';

const determineKbApiUrl = (
    productSlug: string | undefined,
    articleId: string | undefined,
    articleSlug: string | undefined
) => {
    if (!productSlug && articleSlug) return `/kbarticles/slug/${articleSlug}`;
    if (!productSlug && !articleSlug && articleId)
        return `/kbarticles/${articleId}`;

    return `/kbarticles/${articleSlug}?productSlug=${productSlug}`;
};

const redirectIfNecessary = (
    productSlug: string | undefined,
    article: any,
    hash: string
) => {
    if (!productSlug && article) {
        const url = `/${article.productSlug}/kb-articles/${article.urlKey}${hash}`;

        if (window.history.pushState) {
            window.history.pushState(null, '', url);
        } else {
            window.history.replaceState(null, '', url);
        }
    }
};

const scrollToHash = (article: any, hash: string) => {
    if (article && hash) {
        setTimeout(() => {
            const elementTop =
                document.querySelector(hash)?.getBoundingClientRect().top || 0;
            if (elementTop) {
                window.scroll({
                    top: elementTop + 100,
                    behavior: 'smooth',
                });
            }
        }, 500);
    }
};

const ProductArticle = () => {
    const { appConfig } = useApp();

    useScript('//fast.wistia.com/assets/external/E-v1.js');
    const theme = useTheme();
    const { productSlug, articleId, articleSlug } = useParams();
    const { hash } = useLocation();

    const kbApiUrl = determineKbApiUrl(productSlug, articleId, articleSlug);

    const { data: article, error } = useSWR(kbApiUrl);

    redirectIfNecessary(productSlug, article, hash);

    scrollToHash(article, hash);

    const breadcrumbs = [
        {
            href: '/',
            title: 'Home',
            isActive: false,
        },
        ...(article
            ? [
                  {
                      href: `/${article?.productSlug}`,
                      title: article?.productName,
                      isActive: false,
                  },
                  {
                      href: `/${article?.productSlug}/kb-articles`,
                      title: 'Knowledge Base',
                      isActive: false,
                  },
                  {
                      title: article.title,
                      isActive: true,
                  },
              ]
            : []),
    ];

    let errorStatus;

    if (error?.response?.status !== 403) {
        errorStatus = error?.response?.status;
    }

    if (productSlug === 'null' || article?.productSlug === null) {
        errorStatus = 404;
    }
    const handleRating = (rating: UserFeedback) => {
        return axios.post(`/kbarticles/${article.knowledgeArticleId}/rating`, {
            rating,
        });
    };

    return (
        <Main
            breadcrumbs={breadcrumbs}
            errorStatus={errorStatus}
            id="kb-article"
        >
            {error?.response?.status === 403 && <AccessRestricted />}

            {article && (
                <Container id="printarea">
                    <Box>
                        <Typography variant="h3" component="h1" gutterBottom>
                            {article.title}
                        </Typography>
                        <Box display="flex">
                            <Typography
                                sx={{ pr: 1 }}
                                variant="body2"
                                color="textSecondary"
                                aria-label="Product Name"
                            >
                                {article.productName}
                            </Typography>
                        </Box>
                        <Divider sx={{ marginY: 4 }} />
                    </Box>
                    <SanitizeHTML
                        aria-label="Article Content"
                        sx={{
                            mb: 2,
                            overflow: 'auto',
                            '& p, & span, & ul, & ol, & dl, & dt, & dd, & li, & em, & div:not(.wistia_embed *)':
                                {
                                    fontSize: 'initial  !important',
                                    fontFamily: 'unset !important',
                                    backgroundColor: 'initial !important',
                                    color: 'unset !important',
                                },
                            '& a': {
                                color: `${theme.palette.primary.main} !important`,
                            },
                            '& table th': {
                                background: `${theme.palette.primary.main} !important`,
                                color: `${theme.palette.primary.contrastText} !important`,
                                padding: 0.625,
                            },
                            '& table td': {
                                padding: 0.625,
                            },
                        }}
                        html={article.content}
                    />
                    {!appConfig?.IS_READ_ONLY_MAINTENANCE && (
                        <ThumbRating
                            userFeedback={article.rating.userFeedback}
                            likes={article.rating.positiveFeedbacks || 0}
                            dislikes={article.rating.negativeFeedbacks || 0}
                            onRate={(e) => handleRating(e)}
                        />
                    )}
                </Container>
            )}
            {!error && !article && <LoadingBox minHeight={400} />}
            <ContactFooter />
        </Main>
    );
};

export default ProductArticle;
