import { Brand } from 'types/products';

const isShownOnPortalFilter = (data: Brand[]) =>
    data?.length > 0
        ? data.map((brand) => ({
              ...brand,
              productList: brand.productList.filter(
                  (product) => product.isShowOnPortal === true
              ),
          }))
        : [];

export { isShownOnPortalFilter };
