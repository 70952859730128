export const countriesList: {
    [key: string]: number;
} = {
    'United States': 717710185,
    'United Kingdom': 717710184,
    Afghanistan: 717710083,
    Akrotiri: 717710231,
    Albania: 717710000,
    Algeria: 717710001,
    'American Samoa': 717710002,
    Andorra: 717710003,
    Angola: 717710004,
    Anguilla: 717710211,
    'Antigua and Barbuda': 717710224,
    Argentina: 717710005,
    Armenia: 717710006,
    Aruba: 717710232,
    Australia: 717710007,
    Austria: 717710008,
    Azerbaijan: 717710009,
    Bahamas: 717710010,
    Bahrain: 717710011,
    Bangladesh: 717710012,
    Barbados: 717710210,
    Belarus: 717710013,
    Belgium: 717710014,
    Belize: 717710221,
    Benin: 717710015,
    Bermuda: 717710235,
    Bhutan: 717710016,
    Bolivia: 717710220,
    'Bosnia and Herzegovina': 717710017,
    Botswana: 717710018,
    'Bouvet Island': 717710019,
    Brazil: 717710020,
    'British Indian Ocean Territory': 717710021,
    'Brunei Darussalam': 717710022,
    Bulgaria: 717710023,
    'Burkina Faso': 717710024,
    Burundi: 717710025,
    Cambodia: 717710026,
    Cameroon: 717710027,
    Canada: 717710028,
    'Cape Verde': 717710029,
    'Cayman Islands': 717710209,
    'Central African Republic': 717710030,
    Chad: 717710031,
    Chile: 717710032,
    China: 717710033,
    'Christmas Island': 717710034,
    'Cocos (Keeling) Islands': 717710035,
    Colombia: 717710036,
    Comoros: 717710037,
    Congo: 717710038,
    'Congo, Democratic Republic': 717710039,
    'Cook Islands': 717710040,
    'Costa Rica': 717710041,
    "Cote d'Ivoire": 717710042,
    Croatia: 717710043,
    Cuba: 717710219,
    Curaçao: 717710238,
    Cyprus: 717710044,
    'Czech Republic': 717710045,
    Dehkelia: 717710239,
    Denmark: 717710046,
    Djibouti: 717710047,
    Dominica: 717710218,
    'Dominican Republic': 717710048,
    Ecuador: 717710049,
    Egypt: 717710050,
    'El Salvador': 717710212,
    'Equatorial Guinea': 717710051,
    Eritrea: 717710052,
    Estonia: 717710053,
    Ethiopia: 717710054,
    'Falkland Islands': 717710240,
    'Faroe Islands': 717710055,
    'Fiji Islands': 717710056,
    Finland: 717710057,
    France: 717710058,
    'French Polynesia': 717710059,
    Gabon: 717710060,
    Gambia: 717710061,
    Georgia: 717710062,
    Germany: 717710063,
    Ghana: 717710064,
    Gibraltar: 717710065,
    Greece: 717710066,
    Greenland: 717710067,
    Grenada: 717710217,
    Guadeloupe: 717710242,
    Guam: 717710068,
    Guatemala: 717710069,
    Guernsey: 717710243,
    Guinea: 717710070,
    'Guinea-Bissau': 717710071,
    Guyana: 717710216,
    Haiti: 717710215,
    Honduras: 717710073,
    'Hong Kong': 717710207,
    Hungary: 717710074,
    Iceland: 717710075,
    India: 717710076,
    Indonesia: 717710077,
    Iran: 717710078,
    Iraq: 717710079,
    Ireland: 717710080,
    Israel: 717710081,
    Italy: 717710082,
    Jamaica: 717710084,
    Japan: 717710086,
    Jersey: 717710248,
    Jordan: 717710087,
    Kazakhstan: 717710088,
    Kenya: 717710089,
    Kiribati: 717710090,
    Kosovo: 717710228,
    Kuwait: 717710092,
    Kyrgyzstan: 717710093,
    Laos: 717710229,
    Latvia: 717710095,
    Lebanon: 717710096,
    Lesotho: 717710097,
    Liberia: 717710098,
    Libya: 717710099,
    Liechtenstein: 717710100,
    Lithuania: 717710101,
    Luxembourg: 717710102,
    Macau: 717710198,
    Macedonia: 717710103,
    Madagascar: 717710104,
    Malawi: 717710105,
    Malaysia: 717710106,
    Maldives: 717710107,
    Mali: 717710108,
    Malta: 717710109,
    'Marshall Islands': 717710202,
    Martinique: 717710249,
    Mauritania: 717710110,
    Mauritius: 717710111,
    Mayotte: 717710112,
    Mexico: 717710113,
    Micronesia: 717710114,
    Moldova: 717710115,
    Monaco: 717710116,
    Mongolia: 717710117,
    Montenegro: 717710118,
    Montserrat: 717710233,
    Morocco: 717710119,
    Mozambique: 717710120,
    Myanmar: 717710121,
    Namibia: 717710122,
    Nauru: 717710123,
    Nepal: 717710124,
    Netherlands: 717710125,
    'New Caledonia': 717710126,
    'New Zealand': 717710127,
    Nicaragua: 717710128,
    Niger: 717710129,
    Nigeria: 717710130,
    'Norfolk Island': 717710132,
    'North Korea': 717710200,
    'Northern Mariana Island': 717710133,
    Norway: 717710134,
    Oman: 717710135,
    Pakistan: 717710137,
    Palau: 717710138,
    Palestine: 717710139,
    Panama: 717710140,
    'Papua New Guinea': 717710141,
    Paraguay: 717710142,
    Peru: 717710143,
    Philippines: 717710144,
    Poland: 717710145,
    Portugal: 717710146,
    'Puerto Rico': 717710085,
    Qatar: 717710147,
    Reunion: 717710148,
    Romania: 717710149,
    'Russian Federation': 717710150,
    Rwanda: 717710151,
    'Saint Helena': 717710236,
    'Saint Kitts and Nevis': 717710214,
    'Saint Lucia': 717710208,
    'Saint Martin': 717710237,
    'Saint Pierre and Miquelon': 717710244,
    'Saint Vincent and the Grenadines': 717710206,
    Samoa: 717710152,
    'San Marino': 717710153,
    'Sao Tome and Principe': 717710154,
    'Saudi Arabia': 717710155,
    Senegal: 717710156,
    Serbia: 717710157,
    Seychelles: 717710158,
    'Sierra Leone': 717710159,
    Singapore: 717710160,
    'Sint Maarten': 717710241,
    Slovakia: 717710161,
    Slovenia: 717710162,
    'Solomon Islands': 717710163,
    Somalia: 717710164,
    'South Africa': 717710165,
    'South Korea': 717710199,
    'South Sudan': 717710205,
    Spain: 717710166,
    'Sri Lanka': 717710167,
    Sudan: 717710204,
    Suriname: 717710213,
    Svalbard: 717710168,
    Swaziland: 717710169,
    Sweden: 717710170,
    Switzerland: 717710171,
    Syria: 717710091,
    Taiwan: 717710201,
    Tajikistan: 717710172,
    Tanzania: 717710173,
    Thailand: 717710174,
    'Timor-Leste': 717710230,
    Togo: 717710175,
    Tokelau: 717710203,
    Tonga: 717710176,
    'Trinidad and Tobago': 717710222,
    Tunisia: 717710177,
    Turkey: 717710178,
    Turkmenistan: 717710179,
    'Turks and Caicos Islands': 717710245,
    Tuvalu: 717710180,
    Uganda: 717710181,
    Ukraine: 717710182,
    'United Arab Emirates': 717710183,
    Uruguay: 717710186,
    Uzbekistan: 717710187,
    Vanuatu: 717710188,
    Vatican: 717710189,
    Venezuela: 717710190,
    Vietnam: 717710191,
    'Virgin Islands, British': 717710072,
    'Virgin Islands, U.S.': 717710223,
    'Wallis & Futuna Islands': 717710192,
    'Western Sahara': 717710193,
    Yemen: 717710194,
    Zambia: 717710195,
    Zimbabwe: 717710196,
    Other: 717710094,
    'East Timor': 717710197,
    'Pacific Islands Trust Tr': 717710136,
};
