import { Box, Button, Grid, Typography } from '@northstar/core';
import { Link } from 'components/Link';
import Container from '@northstar/core/Container';

const Main = () => {
    return (
        <Container maxWidth="xl" sx={{ paddingY: { xs: 2, sm: 4, md: 6 } }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h3" component="h1" gutterBottom>
                    Manage Support Requests
                </Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Box marginBottom={3}>
                        <Typography sx={{ mb: 1 }} component="p">
                            Before opening a support request, please search our
                            existing support content in the&nbsp;
                            <Link to="/search">Search</Link>
                        </Typography>
                        <Typography component="p">
                            If you can&apos;t find the answers you&apos;re
                            looking for in our Search, submit an official
                            request and our friendly support staff would be
                            happy to assist you.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            width: 'fit-content',
                            mt: 0.5,
                            mb: 1,
                            mx: 'auto',
                        }}
                    >
                        <Button
                            sx={{ width: '100%' }}
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/support/requests/open"
                        >
                            Open Support request
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Main;
