import Typography from '@northstar/core/Typography';
import { withAuth } from 'components/Auth';
import Form from 'pages/Support/components/Create/Form';
import { Main } from 'components/layouts';
import { useAuth } from 'contexts/Auth';
import Box from '@northstar/core/Box';
import Divider from '@northstar/core/Divider';
import { Link } from 'components/Link';
import { PageHeader, PageBody } from './components/PageContent';

const breadcrumbs = [
    {
        href: '/',
        title: 'Home',
        isActive: false,
    },
    {
        href: '/support/requests',
        title: 'Manage Requests',
        isActive: false,
    },
    {
        title: 'Open Support Request',
        isActive: true,
        href: '#',
    },
];

const CreateCases = () => {
    const { user } = useAuth();
    const errorStatus = !user?.is_portal_user ? 403 : undefined;

    return (
        <Main
            breadcrumbs={breadcrumbs}
            breadcrumbsMode="dark"
            errorStatus={errorStatus}
        >
            <PageHeader maxWidth="md">
                <Typography
                    variant="h3"
                    component="h1"
                    sx={{ color: 'common.white' }}
                >
                    Open Support Request
                </Typography>
            </PageHeader>
            <PageBody maxWidth="md">
                <Typography
                    variant="body1"
                    gutterBottom
                    aria-label="createSupportText"
                >
                    Having trouble? Search our existing support content in the{' '}
                    <Link to="/search">Search</Link> or open a support request
                    online and our talented support staff will get back to you
                    shortly.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Support Requests and emails are only monitored during
                    business hours. If you need immediate assistance, please
                    refer to your brand&apos;s contact details{' '}
                    <Link color="primary" to="/contact-us">
                        here
                    </Link>
                    .
                </Typography>
                <Box paddingY={4}>
                    <Divider />
                </Box>
                <Form />
            </PageBody>
        </Main>
    );
};

export default withAuth(CreateCases);
