import { QuestionCriteria } from 'types/createCase';

const getRelevantCriteria = (
    idToCompare: string,
    questionCriteria: QuestionCriteria[] | undefined
) =>
    (questionCriteria || []).filter(
        (qCItem) => qCItem.parentQuestionOptionAnswerValue === idToCompare
    );

export { getRelevantCriteria };
