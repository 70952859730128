import { Outlet, useLocation } from 'react-router-dom';
import { InfoBanner } from 'components/Banner';
import Box from '@northstar/core/Box';
import { AppProvider, useApp } from 'contexts/App';
import { AuthProvider } from 'contexts/Auth';
import { SnackbarProvider } from 'notistack';
import { useDarkMode } from 'hooks/useDarkMode';
import { ThemeProvider } from '@northstar/core/styles';
import { getTheme } from 'utils/getTheme';
import { CssBaseline } from '@northstar/core';
import { SWRConfig } from 'swr';
import axios from 'utils/axios';
import { AccountsProvider } from 'contexts/Accounts';
import { primaryRequest } from 'constants/primaryRequest';
import { MaintenancePage } from 'pages';
import { PropsWithChildren } from 'react';
import AuthSessionDialog from './Main/components/AuthSessionDialog';
import { Footer, Header } from './Main/components';

interface FetcherParams {
    url: string;
    args?: {
        header?: Record<string, any>;
    };
}

export const fetcher = async (params: string | FetcherParams) => {
    if (!primaryRequest.isCompleted) await primaryRequest.waitToComplete;

    let URL = '';
    let headerValue: Record<string, any> = {};

    if (typeof params === 'object') {
        const { url: paramsURL, args } = params;
        URL = paramsURL;
        headerValue = args?.header || {};
    } else {
        URL = params;
    }

    return axios(URL, {
        headers: headerValue,
    })
        .then((res) => res.data)
        .catch((e: any) => {
            throw e;
        });
};

const AppLayout = ({ children }: PropsWithChildren) => {
    return (
        <>
            <Header bgcolor="marketing.fortaForest" />
            <InfoBanner />
            <Box
                display="flex"
                flexDirection="column"
                component="main"
                minHeight={['calc(100% - 269px)', 'calc(100% - 213px)']}
                position="relative"
                zIndex={0}
                id="main"
            >
                {children}
            </Box>
            <Footer />
            <AuthSessionDialog />
        </>
    );
};

const RenderOutlet = () => {
    const { appConfig } = useApp();
    const location = useLocation();
    const BLOCKED_PAGES = [
        '/account',
        '/support/requests/open',
        '/website-access-request',
    ];

    if (appConfig?.IS_FULL_MAINTENANCE) {
        return (
            <MaintenancePage
                isFullMaintenance
                endDate={appConfig.maintenanceEndDay}
            />
        );
    }

    if (
        appConfig?.IS_READ_ONLY_MAINTENANCE &&
        BLOCKED_PAGES.includes(location.pathname)
    ) {
        return (
            <AppLayout>
                <MaintenancePage endDate={appConfig?.maintenanceEndDay} />
            </AppLayout>
        );
    }
    return (
        <AppLayout>
            <Outlet />
        </AppLayout>
    );
};

const RootLayout = () => {
    const [themeMode, themeToggler] = useDarkMode();
    const swrConfig = {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    };

    return (
        <SWRConfig value={{ fetcher, ...swrConfig }}>
            <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
                <CssBaseline />
                <SnackbarProvider maxSnack={4} preventDuplicate>
                    <AppProvider>
                        <AuthProvider>
                            <AccountsProvider>
                                <RenderOutlet />
                            </AccountsProvider>
                        </AuthProvider>
                    </AppProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </SWRConfig>
    );
};

export default RootLayout;
