import { Table } from '@tanstack/table-core';
import { CaseData } from 'types/cases';
import { downloadCSV } from 'utils/downloadCSV';
import { formatedDate } from 'utils/formatedDate';

const exportCasesToCSV = (
    tableInstance: Table<CaseData>,
    appliedFilterNames: string
) => {
    const filteredRows = tableInstance
        .getSortedRowModel()
        .rows.filter((row) => row.original.caseStatus !== 'Draft');

    if (filteredRows.length === 0)
        return "Sorry, there's nothing to export. Please adjust the filters and try again.";

    const rowObjects = filteredRows.map((row) => {
        const rowObject = {} as any;
        tableInstance.getVisibleFlatColumns().forEach((column: any) => {
            const { id, columnDef } = column;
            const { header = '' } = columnDef;
            const isDateCel = id === 'createDate' || id === 'modifyDate';
            const cellValue = isDateCel
                ? formatedDate(row.getValue(id)).toDate()
                : row.getValue(id);
            rowObject[header] = cellValue;
        });

        return rowObject;
    });

    const headerKeys = Object.keys(rowObjects[0]);

    return downloadCSV({
        jsonData: rowObjects,
        headers: headerKeys,
        fileName: `${new Date().toLocaleDateString()}_fortra_support_requests.csv`,
        additionalLine: appliedFilterNames,
    });
};

export { exportCasesToCSV };
