import { Grid } from '@northstar/core';
import { Suspense, useEffect, useRef, useState } from 'react';
import { ProductPermission } from 'types/permissions';
import { objectOrderBy } from 'utils/objectOrderBy';
import { AnswerOption, LookupResponse } from 'types/createCase';
import { useFormContext } from 'react-hook-form';

import { useServiceRequest } from 'hooks/useServiceRequest';
import { useAccounts } from 'contexts/Accounts';
import { usePrevious } from 'hooks';
import { DynamicFormField } from 'types/formFields';
import { DynamicFormFields } from 'pages/Support/components/Create/DynamicFormFields';
import { LazyFormAutocomplete } from 'components/FormFields';
import { FormFieldLoading } from 'components/FormFieldLoading';
import { getProductMatchCriteria } from '../DynamicFormFields/getProductMatchCriteria';

import { createFieldState } from '../DynamicFormFields/createFieldState';
import { createValidationRules } from '../DynamicFormFields/createValidationRules';
import { conditionalValidationFields } from '../validationSchema';
import { getChainedFields } from '../DynamicFormFields/getChainedFields';

type Props = {
    handleDraftChange: (data: any) => void;
    initialProductId: string | undefined;
    lookUpOptions: LookupResponse[];
    setLookUpOptions: (options: LookupResponse[]) => void;
    updateFormValidation: (removedFields: any, validation: any) => void;
    showConditionalFields: boolean;
    setShowConditionalFields: (value: boolean) => void;
    defaultSeverityLevel: string;
};

const ProductSelection = ({
    handleDraftChange,
    initialProductId,
    lookUpOptions,
    setLookUpOptions,
    updateFormValidation,
    setShowConditionalFields,
    defaultSeverityLevel,
}: Props) => {
    const {
        getValues: getFormValues,
        setValue: setFieldValue,
        unregister,
        watch,
    } = useFormContext();

    const formValues = getFormValues();

    const accountChangeWatcher = watch('selectedAccount');

    const productIdWatcher = watch('productId');

    const { permissionsProducts } = useAccounts();

    const {
        questionCriteria,
        questionDefinitions,
        answerOptions,
        isServiceRequestDataLoaded,
    } = useServiceRequest();

    const [dynamicFormFields, setDynamicFormFields] = useState<
        DynamicFormField[]
    >([]);

    const productRef = useRef<HTMLElement>(null);
    const productInitialized = useRef<boolean>(false);

    const [productBrandId, setProductBrandId] = useState('');

    const prevProductId = usePrevious(productIdWatcher);

    const currentProductList = permissionsProducts[accountChangeWatcher];

    const selectedUserProducts = currentProductList
        ? currentProductList.filter((item) => item.isShowOnPortal)
        : undefined;

    const cleanDynamicFieldsStates = () => {
        const fieldNames = [] as string[];

        setProductBrandId('');

        if (dynamicFormFields.length === 0) return;

        dynamicFormFields.forEach((item) => {
            fieldNames.push(item.props.name);
            unregister(item.props.name);
        });

        setDynamicFormFields([]);
        setShowConditionalFields(true);
        updateFormValidation(fieldNames, conditionalValidationFields);
    };

    const processProductValue = async (value: ProductPermission | null) => {
        if (questionCriteria && questionDefinitions && answerOptions && value) {
            const criteria = getProductMatchCriteria(value, questionCriteria);

            if (!criteria) {
                cleanDynamicFieldsStates();
                return;
            }

            const definition = questionDefinitions.find(
                (qDItem) => qDItem.id === criteria.questionId
            );

            if (!definition) {
                cleanDynamicFieldsStates();
                return;
            }

            const newFormFields = [] as DynamicFormField[];
            const fieldState = createFieldState(criteria, definition);

            if (fieldState.elementType === 'dropDown') {
                const fieldOptions = answerOptions.filter(
                    (aOItem) => aOItem.questionId === definition.id
                );

                fieldState.props.options = objectOrderBy(fieldOptions, 'name');
            }

            newFormFields.push(fieldState);

            if (formValues[criteria.questionId]) {
                const optItem = {
                    id: formValues[criteria.questionId],
                    questionId: criteria.questionId,
                } as AnswerOption;

                const chainedFormItems = getChainedFields({
                    startOption: optItem,
                    dynamicFormFields,
                    formValues,
                });

                newFormFields.push(...chainedFormItems);
            } else {
                setFieldValue(
                    fieldState.props.name,
                    definition.defaultValue || ''
                );

                const newValidation = createValidationRules([fieldState]);

                updateFormValidation(
                    ['productVersion', 'operatingSystem'],
                    newValidation
                );
            }

            const orderedByKey = objectOrderBy(newFormFields, 'displayOrder');

            setDynamicFormFields(orderedByKey);
            setShowConditionalFields(false);
            setProductBrandId(value.brandId || '');
        }
    };

    const handleProductChange = ({
        data,
    }: {
        name: string;
        data: ProductPermission | null;
    }) => {
        processProductValue(data);

        handleDraftChange({
            name: 'productId',
            value: data,
        });
    };

    useEffect(() => {
        cleanDynamicFieldsStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountChangeWatcher]);

    useEffect(() => {
        if (prevProductId && productIdWatcher === '') {
            cleanDynamicFieldsStates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevProductId, productIdWatcher]);

    useEffect(() => {
        const shouldInitProduct =
            initialProductId &&
            selectedUserProducts &&
            isServiceRequestDataLoaded &&
            !productInitialized.current;

        if (shouldInitProduct) {
            const foundedProduct = selectedUserProducts.find(
                (item) => item.softwareProductId === initialProductId
            );

            if (foundedProduct) {
                productInitialized.current = true;
                processProductValue(foundedProduct);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialProductId, selectedUserProducts, isServiceRequestDataLoaded]);

    return (
        <>
            <Grid item xs={12}>
                <Suspense fallback={<FormFieldLoading />}>
                    <LazyFormAutocomplete
                        handleOnChange={handleProductChange}
                        fieldRef={productRef}
                        inputLabel="Product"
                        labelKey="productName"
                        name="productId"
                        options={selectedUserProducts}
                        placeholder="Select Product *"
                        valueKey="softwareProductId"
                    />
                </Suspense>
            </Grid>
            <DynamicFormFields
                dynamicFormFields={dynamicFormFields}
                productBrandId={productBrandId}
                lookUpOptions={lookUpOptions}
                setDynamicFormFields={setDynamicFormFields}
                setLookUpOptions={setLookUpOptions}
                setShowConditionalFields={setShowConditionalFields}
                updateFormValidation={updateFormValidation}
                defaultSeverityLevel={defaultSeverityLevel}
            />
        </>
    );
};

export { ProductSelection };
