import Box from '@northstar/core/Box';
import Typography from '@northstar/core/Typography';

const SearchHeader = () => {
    return (
        <Box sx={{ mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Search Fortra Support
            </Typography>
            <Typography variant="h5" color="primary">
                What can we help you with?
            </Typography>
        </Box>
    );
};

export default SearchHeader;
