import LoadingBox from 'components/LoadingBox';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import JoditEditor, { IJoditEditorProps, Jodit } from 'jodit-react';
import { FormHelperText, useTheme } from '@northstar/core';
import { isHtmlEmpty } from './isHtmlEmpty';
import { handleBeforePastInsert } from './handleBeforePastInsert';
import { createAttributes } from './createAttributes';
import { editorConfig } from './editorConfig';
import { InjectGlobalStyles } from './InjectGlobalStyles';

type Props = {
    fieldError?: string;
    onBlur?: (html: string) => void;
    onChange: (html: string) => void;
    placeholder: string;
    initialValue: string;
    resetContent: boolean;
    readonly: boolean;
};

const HTMLEditor = ({
    fieldError,
    initialValue,
    onChange,
    placeholder,
    resetContent,
    readonly,
}: Props) => {
    const { palette } = useTheme();

    const [content, setContent] = useState(initialValue || '');

    const prevValue = useRef<string>(initialValue);
    const editorInstance = useRef<Jodit | null>(null);

    const config: IJoditEditorProps['config'] = useMemo(
        () => ({
            ...editorConfig,
            placeholder,
            readonly,
            createAttributes: createAttributes(palette.divider),
            events: {
                beforePasteInsert: handleBeforePastInsert,
                afterInit: (instance: Jodit) => {
                    editorInstance.current = instance;
                },
            },
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [placeholder, palette, readonly]
    );

    const handleValueChange = (newInputValue: string) => {
        const prev = prevValue.current;
        const isEmptyHtmlContent = isHtmlEmpty(newInputValue);

        if (!newInputValue || isEmptyHtmlContent) {
            if (prev) {
                onChange('');
                prevValue.current = '';
            }

            return;
        }

        if (newInputValue !== prev) {
            prevValue.current = newInputValue;
            onChange(newInputValue);
        }
    };

    useEffect(() => {
        if (resetContent) {
            setContent('');

            prevValue.current = '';

            if (editorInstance.current) {
                editorInstance.current.setEditorValue('');
            }
        }
    }, [resetContent]);

    return (
        <Suspense fallback={<LoadingBox />}>
            <InjectGlobalStyles isError={!!fieldError} />
            <JoditEditor
                value={content}
                config={config}
                onChange={handleValueChange}
            />
            {fieldError && (
                <FormHelperText
                    error
                    sx={{
                        marginTop: '3px',
                        marginLeft: '14px',
                    }}
                >
                    {fieldError}
                </FormHelperText>
            )}
        </Suspense>
    );
};

export { HTMLEditor };
