import { MAX_FILES_TOTAL, MAX_FILE_SIZE } from 'constants/file';

export const canUploadFiles = (
    uploadedFiles: File[],
    acceptedFiles: File[]
) => {
    let attachmentsTotal = 0;
    let isExceedTotal = false;

    if (acceptedFiles.length === 1 && acceptedFiles[0].size > MAX_FILE_SIZE) {
        isExceedTotal = true;
    } else {
        isExceedTotal = [
            ...uploadedFiles,
            ...acceptedFiles.filter((file: any) => file.uploaded !== 'retry'),
        ].some((value) => {
            attachmentsTotal += value.size;
            if (attachmentsTotal > MAX_FILES_TOTAL) return true;
            return false;
        });
    }

    if (isExceedTotal) {
        acceptedFiles.forEach((value) => {
            Object.assign(value, {
                status: 'error',
                uploaded: 'failed',
            });
        });
        return false;
    }

    return true;
};
