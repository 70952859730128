import NorthLink from '@northstar/core/Link';
import Typography from '@northstar/core/Typography';
import Box from '@northstar/core/Box';
import Container from '@northstar/core/Container';
import { Link } from 'components/Link';
import AppLogo from 'components/Icons/AppLogo';

const Footer = () => {
    const colorLink = 'marketing.skyBlue';
    const colorText = 'common.white';
    const colorBg = 'marketing.duskForest';
    return (
        <Box
            sx={{ backgroundColor: colorBg }}
            component="footer"
            paddingY={4}
            aria-label="Footer"
        >
            <Container maxWidth="xl">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                >
                    <Link to="/" marginBottom={2}>
                        <AppLogo titleAccess="Fortra" />
                    </Link>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        marginBottom={2}
                    >
                        <Link
                            to="/"
                            underline="none"
                            variant="body2"
                            sx={{
                                color: colorLink,
                            }}
                            marginRight={2}
                        >
                            Home
                        </Link>
                        <NorthLink
                            href="https://fortra.com/privacy-policy"
                            underline="none"
                            variant="body2"
                            target="_blank"
                            marginRight={2}
                            sx={{
                                color: colorLink,
                            }}
                        >
                            Privacy Policy
                        </NorthLink>
                        <NorthLink
                            href="https://www.fortra.com/cookie-policy"
                            underline="none"
                            variant="body2"
                            target="_blank"
                            marginRight={2}
                            sx={{
                                color: colorLink,
                            }}
                        >
                            Cookie Policy
                        </NorthLink>
                        <Link
                            to="/contact-us"
                            underline="none"
                            variant="body2"
                            sx={{
                                color: colorLink,
                            }}
                            marginRight={2}
                        >
                            Contact Us
                        </Link>
                        <NorthLink
                            href="https://www.fortra.com/"
                            underline="none"
                            variant="body2"
                            target="_blank"
                            sx={{
                                color: colorLink,
                            }}
                        >
                            Fortra.com
                        </NorthLink>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        align="center"
                        variant="body2"
                        fontWeight={500}
                        sx={{
                            color: colorText,
                        }}
                        gutterBottom
                    >
                        Copyright © Fortra, LLC and its group of companies.
                    </Typography>
                    <Typography
                        align="center"
                        variant="caption"
                        component="p"
                        sx={{
                            color: colorText,
                        }}
                    >
                        Fortra™, the Fortra™ logos, and other identified marks
                        are proprietary trademarks of Fortra, LLC.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
