import Box from '@northstar/core/Box';
import Container, { ContainerProps } from '@northstar/core/Container';

interface Props extends ContainerProps {}

const PageHeader = ({ maxWidth, children }: Props) => {
    return (
        <Box
            bgcolor="marketing.duskForest"
            height={176}
            paddingTop={7}
            paddingBottom={7}
            display="flex"
            alignItems="flex-end"
        >
            <Container maxWidth={maxWidth}>{children}</Container>
        </Box>
    );
};

export default PageHeader;
