import DownloadIcon from '@mui/icons-material/Download';
import { Action } from '@northstar/plus';
import { CaseData } from 'types/cases';
import { SettingsBackupRestore } from '@northstar/icons';
import { Box, IconButton } from '@northstar/core';

type Props = {
    processCSVDownload: () => void;
    resetTable: () => void;
    isLoading: boolean;
};

const getToolbarActions = ({
    processCSVDownload,
    resetTable,
    isLoading,
}: Props): readonly Action<CaseData>[] => {
    const iconsProps = {
        component: Box,
        sx: {
            padding: 0,
            fill: ({ palette }: any) => palette.grey[600],
        },
        disabled: isLoading,
        disableFocusRipple: true,
    };

    const actions: Action<CaseData>[] = [
        {
            label: 'Reset',
            onClick: isLoading ? () => null : resetTable,
            icon: (
                <IconButton {...iconsProps}>
                    <SettingsBackupRestore />
                </IconButton>
            ),
            type: 'icon',
        },
        {
            label: 'Export',
            onClick: isLoading ? () => null : processCSVDownload,
            icon: (
                <IconButton {...iconsProps}>
                    <DownloadIcon />
                </IconButton>
            ),
            type: 'icon',
        },
    ];

    return actions;
};

export { getToolbarActions };
