import Box, { BoxProps } from '@northstar/core/Box';
import { PropsWithChildren } from 'react';

interface ITabPanelProps extends BoxProps {
    value: number;
    index: number;
    dir: string;
}

const TabPanel = ({
    children,
    value,
    index,
    sx,
    ...other
}: PropsWithChildren<ITabPanelProps>) => (
    <Box
        sx={{ position: 'relative', p: 2, ...sx }}
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >
        {value === index && children}
    </Box>
);

export { TabPanel };
