import { ButtonProps } from '@northstar/core/types/Button';
import { Delete as DeleteIcon } from '@northstar/icons';
import { Button } from 'components/Button';

interface IProps extends ButtonProps {
    hasDraft?: boolean;
}

export const DraftDeleteButton = ({ hasDraft, onClick, ...rest }: IProps) => {
    return hasDraft ? (
        <Button
            startIcon={<DeleteIcon />}
            sx={{ ml: 'auto' }}
            color="inherit"
            type="reset"
            onClick={onClick}
            {...rest}
        >
            Discard
        </Button>
    ) : null;
};
