import { formatedDate } from 'utils/formatedDate';

const casesDefaultFilterFn = (
    row: any,
    columnId: string,
    filterValue: string[]
) => {
    const value = row.getValue(columnId) || '';
    const isDateCel = columnId === 'createDate' || columnId === 'modifyDate';

    const newValue = isDateCel ? formatedDate(value).toDate() : value;

    return String(newValue)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase());
};

export { casesDefaultFilterFn };
