import Avatar from '@northstar/core/Avatar';
import Box from '@northstar/core/Box';
import Accordion from '@northstar/core/Accordion';
import AccordionSummary from '@northstar/core/AccordionSummary';
import AccordionDetails from '@northstar/core/AccordionDetails';
import ListItem from '@northstar/core/ListItem';
import ListItemAvatar from '@northstar/core/ListItemAvatar';
import ListItemText from '@northstar/core/ListItemText';
import { ExpandMore as ExpandMoreIcon } from '@northstar/icons';
import GuestNav from 'components/layouts/Main/components/Topbar/components/NavAccount/GuestNav';
import UserAccountNav from 'components/layouts/Main/components/Topbar/components/NavAccount/UserAccountNav';
import { useAuth } from 'contexts/Auth';
import { getAvatarName } from 'utils/getAvatarName';

function NavAccount() {
    const { user } = useAuth();
    const avatarName = user && getAvatarName(user.name);

    return user ? (
        <Accordion
            disableGutters
            elevation={0}
            sx={{ backgroundColor: 'transparent' }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    padding: 0,
                    '& > .MuiAccordionSummary-content': {
                        mt: 0,
                    },
                }}
            >
                <Box
                    component={ListItem}
                    width="auto"
                    padding={0}
                    sx={{
                        maxWidth: '208px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <Box
                        component={ListItemAvatar}
                        minWidth="auto !important"
                        marginRight={1}
                    >
                        <Avatar
                            alt={user?.name}
                            sx={{
                                width: 36,
                                height: 36,
                                bgcolor: 'primary.main',
                            }}
                        >
                            {avatarName}
                        </Avatar>
                    </Box>
                    <ListItemText
                        primary={user ? user.name : 'My account'}
                        secondary={user?.preferred_username}
                        sx={{
                            '& *': {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            },
                        }}
                    />
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
                <UserAccountNav />
            </AccordionDetails>
        </Accordion>
    ) : (
        <GuestNav fullWidth />
    );
}

export default NavAccount;
