import { findDefaultSeverity } from 'pages/Support/utils/findDefaultSeverity';
import { Case, Languages, Severity } from 'types/cases';

type Props = {
    draftCase: any;
    selectedAccounts: string[];
    languages: Languages[] | undefined;
    severityLevels: Severity[] | undefined;
};

const defaultCaseValues = ({
    draftCase,
    selectedAccounts,
    languages,
    severityLevels,
}: Props) => {
    const defaultSeverity = findDefaultSeverity(severityLevels || []);
    const formUpdatedValues: Case = {
        caseTitle: draftCase?.caseTitle,
        selectedAccount: draftCase?.ownerInfo?.accountId || selectedAccounts[0],
        caseDescription: draftCase?.caseDescription,
        product: draftCase?.product,
        productId: draftCase?.productId,
        productVersion: draftCase?.productVersion,
        operatingSystem: draftCase?.operatingSystem,
        language: languages ? draftCase?.language || languages?.[0].value : '',
        attachments: draftCase?.attachments || [],
        account: draftCase?.account,
        draftCaseId: draftCase?.id,
        severity: draftCase?.severity ?? defaultSeverity?.level ?? 'Normal',
        category: 'Support Request',
    };

    return formUpdatedValues;
};

export { defaultCaseValues };
