import { ProductDownloads } from 'types/products';
import { useState } from 'react';
import Accordion from '@northstar/core/Accordion';
import { DownloadSummary } from '../DownloadSummary';

type Children = {
    version: ProductDownloads;
    index: number;
};

type Props = {
    versions: ProductDownloads[];
    children: (data: Children) => React.JSX.Element;
};

const VersionType = ({ versions, children }: Props) => {
    const [expanded, setExpanded] = useState<boolean | number>(false);

    const handleExpandedState = (idx: number) => {
        setExpanded(expanded === idx ? false : idx);
    };

    return (
        <>
            {versions.map((version, index) =>
                version.downloadLinks.length === 0 ? null : (
                    <Accordion
                        key={version.id}
                        variant="outlined"
                        expanded={expanded === index}
                        onChange={() => handleExpandedState(index)}
                        aria-label={`Accordion Content ${index}`}
                    >
                        <DownloadSummary
                            name={version.name}
                            version={version.version}
                            releaseDate={version.releaseDate}
                        />
                        {children({ version, index })}
                    </Accordion>
                )
            )}
        </>
    );
};

export { VersionType };
