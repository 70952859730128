const parseJsonString = <T>(data: string): T => {
    try {
        const json = JSON.parse(data);
        if (typeof json !== 'object' && !Object.keys(json).length) {
            throw new Error();
        }
        return json;
    } catch {
        return null as T;
    }
};

export { parseJsonString };
