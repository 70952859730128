import FormControl from '@northstar/core/FormControl';
import InputLabel from '@northstar/core/InputLabel';
import Select, { SelectChangeEvent } from '@northstar/core/Select';

import MenuItem from '@northstar/core/MenuItem';
import { jobList } from 'constants/jobList';
import { dropDownMenuProps } from 'constants/dropDownMenuProps';
import FormHelperText from '@northstar/core/FormHelperText';

type IJobTitlesDropDown = {
    onChange: (value: string, name: string) => void;
    selectedValue?: string;
    error?: boolean;
    helperText?: string | boolean;
};

const JobTitlesDropDown = ({
    onChange,
    selectedValue,
    error,
    helperText,
}: IJobTitlesDropDown) => {
    return (
        <FormControl fullWidth error={error}>
            <InputLabel>Job Title</InputLabel>
            <Select
                value={selectedValue}
                displayEmpty
                fullWidth
                onChange={(e: SelectChangeEvent<string>) =>
                    onChange(e.target.value, 'jobTitle')
                }
                MenuProps={dropDownMenuProps}
                labelId="jobTitle"
                id="jobTitle"
                label="Job Title"
            >
                {Object.keys(jobList).map((item) => (
                    <MenuItem value={jobList[item]} key={jobList[item]}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export { JobTitlesDropDown };
