import Box, { BoxProps } from '@northstar/core/Box';
import CircularProgress from '@northstar/core/CircularProgress';

const LoadingBox = (props: BoxProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...props.sx,
        }}
        {...props}
    >
        <CircularProgress />
    </Box>
);

export default LoadingBox;
