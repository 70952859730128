import { useBlocker as useRouterBlocker } from 'react-router-dom';

export const useBlocker = (
    isUnsafeClose: boolean | string | undefined,
    cb?: () => void
) => {
    useRouterBlocker(() => {
        if (isUnsafeClose) {
            // eslint-disable-next-line no-alert
            const promptConfirm = window.confirm(
                'Are you sure you want to leave this page? Changes you made may not be saved.'
            );
            if (promptConfirm && cb) {
                cb();
            }
            return !promptConfirm;
        }
        return false;
    });
};
