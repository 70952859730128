import { useState } from 'react';
import { useTheme } from '@northstar/core/styles';
import Tabs from '@northstar/core/Tabs';
import Tab from '@northstar/core/Tab';
import Divider from '@northstar/core/Divider';
import Typography from '@northstar/core/Typography';
import Box from '@northstar/core/Box';
import { Container } from 'components/Container';
import { TabPanel } from 'components/TabPanel';
import useSWR from 'swr';
import type { Brand, UserProducts } from 'types/products';
import { useAuth } from 'contexts/Auth';
import { AccountsSelection } from 'components/AccountsSelection';
import { useAccounts } from 'contexts/Accounts';
import { mergeMyProducts } from 'contexts/Accounts/mergeMyProducts';
import { ProductsList } from './productsList';

const HSProducts = () => {
    const {
        getAllAccountsIds,
        handleUsersProducts,
        myProducts,
        selectedAccounts,
        relatedAccounts,
    } = useAccounts();

    const { user } = useAuth();

    const [value, setValue] = useState(() => (user ? 0 : 1));
    const theme = useTheme();

    const { data: productBrands } = useSWR<Brand[]>('/productbrands');

    const handleChange = (_event: any, newValue: number) => {
        setValue(newValue);
    };

    const mergedProducts = mergeMyProducts(myProducts, selectedAccounts);

    useSWR<UserProducts>(
        user?.is_portal_user &&
            !myProducts &&
            relatedAccounts && {
                url: '/my_products',
                args: {
                    header: {
                        portalAccountIds: getAllAccountsIds(),
                    },
                },
            },
        { onSuccess: handleUsersProducts }
    );

    return (
        <Container>
            <Box>
                <Typography variant="h2" gutterBottom>
                    Explore by product
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Find documentation, knowledge base articles and downloads
                </Typography>
            </Box>
            <Box>
                {user && (
                    <>
                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            sx={{
                                position: 'relative',
                                marginTop: 3,
                            }}
                            onChange={handleChange}
                            aria-label="tabs example"
                        >
                            <Tab label="My Products" />
                            <Tab label="All Products" />
                        </Tabs>

                        <Divider
                            sx={{
                                position: 'relative',
                            }}
                        />
                    </>
                )}

                <TabPanel
                    sx={{ paddingX: 0 }}
                    value={value}
                    index={0}
                    dir={theme.direction}
                >
                    <AccountsSelection />
                    <ProductsList data={mergedProducts} />
                </TabPanel>
                <TabPanel
                    sx={{ paddingX: 0 }}
                    value={value}
                    index={1}
                    dir={theme.direction}
                >
                    <ProductsList data={productBrands} />
                </TabPanel>
            </Box>
        </Container>
    );
};

export { HSProducts };
