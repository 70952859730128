import { LightTheme } from '@northstar/core';
import { createMuiTheme, responsiveFontSizes } from '@northstar/core/styles';
import { lightThemeColors, darkThemeColors } from 'constants/colors';

declare module '@mui/material/styles' {
    interface Theme {
        themeToggler: () => void;
    }
    interface ThemeOptions {
        themeToggler?: () => void;
    }
}

const getTheme = (mode: 'light' | 'dark', themeToggler: () => void) =>
    responsiveFontSizes(
        createMuiTheme({
            palette: mode === 'light' ? lightThemeColors : darkThemeColors,
            typography: LightTheme.typography,
            zIndex: {
                appBar: 1200,
                drawer: 1300,
            },
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                },
            },
            themeToggler,
        })
    );

export { getTheme };
