import { UserAccountsProducts } from 'types/accounts';
import { Brand } from 'types/products';
import { objectOrderBy } from 'utils/objectOrderBy';
import { removeArrayDuplicateObjectsByKey } from 'utils/removeArrayDuplicateObjectsByKey';

const mergeMyProducts = (
    accountsProducts: UserAccountsProducts | undefined,
    selectedAccounts: string[] = []
) => {
    if (!accountsProducts) return undefined;

    const dataKeys = Object.keys(accountsProducts);

    const groupedProducts: { [key: string]: Brand } = {};

    const result = dataKeys.reduce((currentData, currentKey) => {
        const newData = {
            ...currentData,
        };

        if (selectedAccounts.includes(currentKey)) {
            accountsProducts[currentKey].forEach((product) => {
                const existingGroup = newData[product.id];

                if (existingGroup) {
                    const { productList } = existingGroup;

                    productList.push(...product.productList);

                    existingGroup.productList =
                        removeArrayDuplicateObjectsByKey(productList, 'id');
                } else {
                    newData[product.id] = {
                        ...product,
                        productList: [...product.productList],
                    };
                }
            });
        }

        return newData;
    }, groupedProducts);

    const values = Object.values(result);

    return objectOrderBy(values, 'name');
};
export { mergeMyProducts };
