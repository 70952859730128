import NorhButton, { ButtonProps } from '@northstar/core/Button';
import CircularProgress from '@northstar/core/CircularProgress';

interface IProps extends ButtonProps {
    isLoading?: boolean;
}

const Button = ({ isLoading, children, href, component, ...rest }: IProps) => (
    <NorhButton href={href} component={component} {...rest}>
        {children}
        {isLoading && (
            <CircularProgress
                size={24}
                sx={{
                    color: 'scale.seagreen.500',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        )}
    </NorhButton>
);

export { Button };
