import { ProductDownloads } from 'types/products';
import { useState } from 'react';
import { TabPanel } from 'components/TabPanel';
import { useTheme } from '@northstar/core/styles';
import { Container } from 'components/Container';

import Tab from '@northstar/core/Tab';
import Tabs from '@northstar/core/Tabs';
import Previous from './Previous';
import Latest from './Latest';

type Props = {
    latests: ProductDownloads[];
    previous: ProductDownloads[];
    productId: string;
    productName: string;
};

const Downloads = ({ latests, previous, productName, productId }: Props) => {
    const [value, setValue] = useState(0);
    const theme = useTheme();

    const handleChangeTab = (newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container paddingTop="0 !important">
            <Tabs
                sx={{ borderBottom: 1, borderColor: 'divider' }}
                value={value}
                onChange={(_, val) => handleChangeTab(val)}
                aria-label="basic tabs example"
            >
                <Tab label="Latest Version" />
                <Tab
                    disabled={previous?.length === 0}
                    label="Previous Versions"
                />
            </Tabs>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <Latest versions={latests} productId={productId} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <Previous
                    productId={productId}
                    versions={previous}
                    productName={productName}
                    handleChangeTab={handleChangeTab}
                />
            </TabPanel>
        </Container>
    );
};

export default Downloads;
