import axios from 'utils/axios';

type Props = {
    productURL: string;
    productId: string;
    productName: string;
    versionId: string;
};

const downloadProduct = async ({
    productURL,
    productId,
    productName,
    versionId,
}: Props) => {
    try {
        const { data } = await axios(
            `/product_downloads/${productId}/presignedUrl/${versionId}?downloadUrl=${productURL}`
        );

        const downloadLink = document.createElement('a');

        downloadLink.href = data;
        downloadLink.target = '_blank';
        downloadLink.download = productName;
        downloadLink.click();
        downloadLink.remove();
    } catch (e) {
        throw new Error(`Failed to download ${productName}`);
    }
};

export { downloadProduct };
